// Global variables
var i18n;
// var wsCache;
var _select_all = false;
var _emailchain_id_for_notification = '';
var _message_sent = false;
var _loaded_notifications = false;
var _loaded_news = false;
var _version_change = false;
var _is_in_left_slider = false;
var _modal_id = 'myModal';
var _loaded_todo = false;
var socket;

function _contentMenuCallback(itemKey, opt, e) {

	var _query = '?v=1';

	var _action = itemKey;
	var _id = opt.$trigger.attr("id");
	var _status = opt.$trigger.attr("data-status");
	var _mid = opt.$trigger.attr("data-mailboxid");
	var _from = opt.$trigger.attr("data-from") || null;
	var _url_obj = opt.$trigger.attr("data-url") || null;

	// Define the from
	if (_from) {
		_query += '&from=' + _from;
	}
	// If drafts its a bit different
	if (_from === 'drafts') {
		_query += '&drafts=true';
	}

	// If URL exists
	var _url = _url_obj ? _url_obj : '/mailbox/' + _mid + '/' + _status + '/' + _id + _query;

	switch (_action) {
		case 'open':
			document.location.href = _url;
			break;
		case 'target':
			window.open(_url, '_blank');
			break;
		case 'modal':
			var _modal = 'globalConversationModal';
			$('#' + _modal).modal('show');
			loadConversationIntoModal(_id, _modal);
			break;
		case 'reply':
			window.open(_url + '&action=reply', '_blank');
			break;
		case 'note':
			window.open(_url + '&action=note', '_blank');
			break;
		case 'forward':
			document.location.href = '/message/' + _mid + '/new/' + _id;
			break;
		case 'gomb':
			document.location.href = '/mailbox/' + _mid + '/inbox';
			break;
		case 'answered':
			setReadUnread(true, _isSearch(), false, _getGlobalHiddenIsMixed(), _id);
			break;
		case 'unanswered':
			setReadUnread(false, _isSearch(), false, _getGlobalHiddenIsMixed(), _id);
			break;
	}

	return false;

}

function checkSelectMessages() {
	// console.log("checkSelectMessages");
	// Always hide the select all div
	$('#show_select_all').removeClass('show').addClass('hidden');

	// Only show if is_filter is false
	var _is_filter = _isCompanyUserFilter();

	// Check if this is from elasticsearch
	// var _elasticsearch = $('#is_elasticsearch').val() === 'true' ? true : false;

	var $checkbox = $('#messagelist #table-rows').find(':checkbox');
	$checkbox.prop('checked', !$checkbox[0].checked);
	// See how many email have been checked
	var _checked = $('#messagelist #table-rows input[type=checkbox]:checked').length;

	// Get how many are in this status
	// var _total = $('#total_count').val();
	// _total = parseInt(_total);
	// The redefined search on the left only calls partially the view so we need to get the total found count here
	// if ($('#partial_view').val() === 'true') {
	// 	_total = $('#partial_view_total').val();
	// 	_total = parseInt(_total);
	// }
	// And check if we have to hide buttons
	showMessageListButtons();

	if (_is_filter) {
		$('#show_select_all_text_filter').removeClass('hidden').addClass('show');
		$('#show_select_all_text_filter .select_all_text').removeClass('hidden');
		$('#show_select_all_text_filter #show_select_all_link').removeClass('hidden');
		$('#show_select_all_text_filter #show_select_all_text_success').addClass('hidden');
	}

	// Show select all message (not less than 50)
	// if (_checked && _checked <= _total) {
	if (_checked) {
		if (!_is_filter) {
			// Show div with select all
			$('#show_select_all').removeClass('hidden').addClass('show');
			$('#show_select_all_text').removeClass('hidden').addClass('show');
			$('#show_select_all_checked').html(' ' + _checked + ' ');
			// $('#show_select_all_total').html(' ' + _total + ' ');
			// If checked is less than total we can hide the link to select all
			if (_checked < 25) {
				$('#show_select_all_link').addClass('hidden');
			}
			// if (_checked === 50) {
			// 	$('#show_select_all_link').addClass('hidden');
			// }
		}
	}
}

function checkSelectCompanyUsers() {

	var $checkbox = $('#company_user_list #table-rows').find(':checkbox');
	$checkbox.prop('checked', !$checkbox[0].checked);
	// See how many email have been checked
	var _checked = $('#company_user_list #table-rows input[type=checkbox]:checked').length;

	// Get how many are in this status
	var _total = $('#total_count').val();
	_total = parseInt(_total);

	// And check if we have to hide buttons
	showCompanyUsersButtons();
}


function redirectPendingSelection(id, text, mailbox_id) {
	var _pn = getGlobalPluginName();
	var _pid = getGlobalPluginId();
	var _cookie_append = _pid ? _pid : mailbox_id;
	//- Value is -
	if (id === '-') return false;
	//- Value is 0 reset cookie
	if (id === '0') {
		Cookies.remove('email_list_pending_' + _cookie_append);
	} else {
		Cookies.set('email_list_pending_' + _cookie_append, _globalCookieOptions(14, {
			"assignee": id,
			"user_name": text
		}) );
	}
	//- Load page again
	document.location.href = _pn ? pluginGoToURL(_pn, _pid, 'pending') : '/mailbox/' + mailbox_id + '/pending';
}

function redirectMineSelection(id, text, mailbox_id) {
	//- Value is -
	if (id === '-') return false;
	//- Value is 0 reset cookie
	if (id === '0') {
		Cookies.remove('email_list_mine');
	} else {
		Cookies.set('email_list_mine', _globalCookieOptions(14, {
			"assignee": id,
			"user_name": text
		}) );
	}
	var _pn = getGlobalPluginName();
	var _pid = getGlobalPluginId();
	//- Load page again
	document.location.href = _pn ? pluginGoToURL(_pn, _pid, 'mine') : '/mailbox/' + mailbox_id + '/mine';
}

// Remove Company User
function removeCompanyUser() {
	// Grab the id
	var company_user_id = getModalId();
	// Call route to remove record on success close the window and refresh list
	$.ajax({
		type: "POST",
		url: '/settings/removecompanyuser',
		data: {
			'id': company_user_id
		},
		success: function(data) {
			// since the modal is called from an include let each page handle the result independently
			try {
				// exists in settings_company_edit && settings_company_users
				if (typeof afterRemoveCompanyUser !== 'undefined') {
					afterRemoveCompanyUser(data);
				}
			} catch (e) {
				/* no need to do anything */
				// console.log(e);
			}
		}
	});
}

// Remove Workflow
function removeWorkflow() {
	// Grab the id
	var workflow_id = getModalId();
	var mailbox_id = _getMailboxId();
	// Call route to remove record on success close the window and refresh list
	$.ajax({
		type: "POST",
		url: '/mailbox/settings/workflows_remove',
		data: {
			'id': workflow_id
		},
		success: function(data) {
			try {
				location.href = '/mailbox/settings/workflows/' + mailbox_id;
			} catch (e) {
				/* no need to do anything */
				// console.log(e);
			}
		}
	});
}

// Remove message in thread
// function removeMessageInThread() {
// 	// Grab the id
// 	var _id = getModalId();
// 	var _ecid = $('#emailchain_id').val();
// 	// Call route to remove record on success close the window and refresh list
// 	$.ajax({
// 		type: "GET",
// 		url: '/message/remove_one/' + _ecid + '/' + _id,
// 		statusCode: {
// 			500: function() {
// 				notyWrapper({
// 					text: i18n.__('remove_this_message_error'),
// 					type: 'error',
// 					timeout: false
// 				});
// 			},
// 			200: function() {
// 				// Hide modal
// 				$('#removeMessageInThread').modal('hide');
// 				// Noty
// 				notyWrapper({
// 					text: i18n.__('remove_this_message_success'),
// 					type: 'success'
// 				});
// 				// Remove div
// 				$('#timeline_message_' + _id).remove();
// 			}
// 		}
// 	});
// }





// Update Note
// function updateNote() {
// 	var _pn = getGlobalPluginName();
// 	var _pid = getGlobalPluginId();
// 	var _request_info = getGlobalRequestInfo();
// 	$('#note_request_info').val(JSON.stringify(_request_info));
// 	$('#note_pn').val(_pn);
// 	$('#note_pid').val(_pid);
// 	$('#form_editnote').submit();
// }

// Show messagelist buttons on select
function showCompanyUsersButtons() {

	// Hide top nav
	$('#customers_top_nav').addClass('hidden');

	// Always hide the select all div
	$('#show_select_all').removeClass('show').addClass('hidden');
	$('#show_select_all_text_success').removeClass('show').addClass('hidden');
	// Filter
	$('#show_select_all_text_filter').removeClass('show').addClass('hidden');
	$('#show_select_all_text_filter .select_all_text').removeClass('hidden');
	$('#show_select_all_text_filter #show_select_all_link').removeClass('hidden');
	$('#show_select_all_text_filter #show_select_all_text_success').addClass('hidden');

	_select_all = false;
	$('#is_filter_all_selected').val(false);

	// Get how many are in this status
	var _total = $('#total_count_users').val();
	_total = parseInt(_total);

	// How many checkboxes are selected
	var howmany = $('#company_user_list #table-rows input[type=checkbox]:checked').length;

	$('th input[type=checkbox]').prop('checked', !!howmany);

	// Only show if is_filter is false
	var _is_filter = _isCompanyUserFilter();

	// If filter
	if ( _is_filter ) {
		$('#show_select_all_text_filter').removeClass('hidden').addClass('show');
		$('#show_select_all').addClass('show').removeClass('hidden');
		$('#show_select_all_text').removeClass('show').addClass('hidden');
	}

	// If 0 then hide else show
	if (!howmany) {
		// Show top nav
		$('#customers_top_nav').removeClass('hidden');
		$('#show_select_all').removeClass('show').addClass('hidden');
	}
	else {

		if (howmany <= _total) {
			if ( !_is_filter ) {
				// Show div with select all
				$('#show_select_all').removeClass('hidden').addClass('show');
				$('#show_select_all_text').removeClass('hidden').addClass('show');
				$('#show_select_all_checked').html(' ' + howmany + ' ');
				$('#show_select_all_total').html(' ' + _total + ' ');
			}
		}
		// If checked is less than total we can hide the link to select all
		if (howmany < _total) {
			if ( !_is_filter ) $('#show_select_all_link').removeClass('hidden');
		}
		if (howmany === _total) {
			$('th input[type=checkbox]').prop('checked', true);
			$('#show_select_all_link').addClass('hidden');
		}

	}

}

// Select all if filter
function selectAllCompanyUsersFilter() {
	// Get how many are in this status
	var _total = $('#total_count_users').val();
	_total = parseInt(_total);
	// Hide
	$('#show_select_all_text_filter .select_all_text').addClass('hidden');
	$('#show_select_all_text_filter #show_select_all_link').addClass('hidden');
	$('#show_select_all_text_filter #show_select_all_text_success').removeClass('hidden');
	$('#show_select_all_text_filter #show_select_all_text_success_total').html(_total);
	// Set all checkboxes in the page to checked
	$('#company_user_list #table-rows input[type=checkbox]').prop('checked', true);
	// Set all checkbox
	$('th input[type=checkbox]').prop('checked', true);
	// Set all field
	$('#is_filter_all_selected').val(true);
	// Hide top nav
	$('#customers_top_nav').addClass('hidden');
	// Show actions
	$('#show_select_all').removeClass('hidden').addClass('show');
	$('#show_select_all_text').addClass('hidden').removeClass('show');
}

// If user selects all messages in status
function selectAllCompanyUsers() {
	// Get how many are in this status
	var _total = $('#total_count_users').val();
	_total = parseInt(_total);
	// Hide show stuff
	$('#show_select_all_text').removeClass('show').addClass('hidden');
	$('#show_select_all_text_success').removeClass('hidden').addClass('show');
	$('#show_select_all_text_success_total').html(_total + ' ');
	// Set global var
	_select_all = true;
	// Set all checkboxes in the page to checked
	$('#company_user_list #table-rows input[type=checkbox]').prop('checked', true);
	// Set all checkbox
	$('th input[type=checkbox]').prop('checked', true);
}

// Show messagelist buttons on select
function showMessageListButtons() {
	// console.log("showMessageListButtons");
	// Always hide the select all div
	$('#show_select_all').removeClass('show').addClass('hidden');
	$('#show_select_all_text_success').removeClass('show').addClass('hidden');

	_select_all = false;
	$('#is_filter_all_selected').val(false);

	// Get how many are in this status
	// var _total = $('#total_count').val();
	// _total = parseInt(_total);

	// The redefined search on the left only calls partially the view so we need to get the total found count here
	// if ($('#partial_view').val() === 'true') {
	// 	_total = $('#partial_view_total').val();
	// 	_total = parseInt(_total);
	// }

	// How many checkboxes are selected
	var howmany = $('#messagelist #table-rows input[type=checkbox]:checked').length;

	// Check if this is from elasticsearch
	// var _elasticsearch = $('#is_elasticsearch').val() === 'true' ? true : false;

	$('th input[type=checkbox]').prop('checked', !!howmany);

	// If 0 then hide else show
	if (!howmany) {
		$('#messagelistonselect').addClass('hidden');
		$('#folders_navigation_trash_div').removeClass('hidden');
		$('#folders_navigation_search_div').removeClass('hidden');
	}
	else {
		$('#messagelistonselect').removeClass('hidden');
		$('#folders_navigation_trash_div').addClass('hidden');
		$('#folders_navigation_search_div').addClass('hidden');
		// if (howmany <= 50) {
			// Show div with select all
			$('#show_select_all').removeClass('hidden').addClass('show');
			$('#show_select_all_text').removeClass('hidden').addClass('show');
			$('#show_select_all_link').removeClass('hidden');
			$('#show_select_all_checked').html(' ' + howmany + ' ');
			// $('#show_select_all_total').html(' ' + _total + ' ');
		// }
		// If checked is less than total we can hide the link to select all
		// if (howmany < 25) {
		// 	$('#show_select_all_link').addClass('hidden');
		// }
		// if (howmany === 50) {
		// 	$('th input[type=checkbox]').prop('checked', true);
		// 	$('#show_select_all_link').addClass('hidden');
		// }
		// // If elasticsearch we also hide for now
		// if (_elasticsearch) {
		// 	$('#show_select_all_link').addClass('hidden');
		// }
	}
}

// Return selected messages in list
function getSelectedMessages(messageid) {
	// Returned string for all
	var _all_string = 'all';
	// Check for search
	var _is_search = _isSearch();
	// If search
	if (_is_search === 'true') {
		_all_string = 'all_search';
	}
	// if 'all' is passed
	if (_select_all) return _all_string;
	// Set array
	var selected = [];
	// If messageid is 0 (from list)
	if (messageid === '' || messageid === '0' || typeof messageid === 'undefined' || !messageid) {
		$('#messagelist #table-rows input[type=checkbox]:checked').each(function() {
			selected.push($(this).val());
		});
	} else {
		// Set detail id
		selected.push(messageid);
	}
	// Return
	return selected;
}

// If user selects all messages in status
// function selectAllInStatus() {
// 	// Get how many are in this status
// 	// var _total = $('#total_count').val();
// 	// _total = parseInt(_total);
// 	// The redefined search on the left only calls partially the view so we need to get the total found count here
// 	// if ($('#partial_view').val() === 'true') {
// 	// 	_total = $('#partial_view_total').val();
// 	// 	_total = parseInt(_total);
// 	// }
// 	// Hide show stuff
// 	$('#show_select_all_text').removeClass('show').addClass('hidden');
// 	$('#show_select_all_text_success').removeClass('hidden').addClass('show');
// 	// $('#show_select_all_text_success_total').html(_total + ' ');
// 	// Set global var
// 	_select_all = true;
// 	// Set all checkboxes in the page to checked
// 	$('#messagelist #table-rows input[type=checkbox]').prop('checked', true);
// 	// Set all checkbox
// 	$('th input[type=checkbox]').prop('checked', true);
// 	// Change wording of button
// 	// $('#messagelistselect_selected').removeClass('show').addClass('hide');
// 	// $('#messagelistselect_deselected').removeClass('hide').addClass('show');
// }

function handlePostSearchAction(div_id) {
	// uncheck the selected messages
	$('#messagelist #table-rows input[type=checkbox]:checked').prop('checked', false);
	// hide the top section
	$('#messagelistonselect').addClass('hidden');
	// Set all to false
	_select_all = false;
	$('#is_filter_all_selected').val(false);
	// show the div og the given name
	var div_selector = '#' + div_id;
	$(div_selector).removeClass('hidden');
	$(div_selector).fadeTo("fast", 100).animate({
		opacity: 1.0
	}, 1000).fadeTo("slow", 0, function() {
		$(div_selector).slideUp(50);
	});
}

// Set status delayed
function setStatusDelayed(status, is_search, is_detail, is_mixed) {
	waitForValue('#global_selection_div', function() {
		// Value set. Set status now
		setStatus(status, is_search, is_detail, is_mixed);
	});
}

// Set status from messagelist
function setStatus(status, is_search, is_detail, is_mixed, move_to_folder) {

	// Remove animation
	// _removeLoadingAnimationGlobal();

	var _log_set_status = false;

	var _count = 0;

	// is_Mixed means the records can be from different mailboxes
	is_mixed = is_mixed || is_mixed === 'true' ? true : false;

	move_to_folder = move_to_folder || move_to_folder === 'true' ? true : false;

	// If values are not defined
	is_search = _isSearch();
	is_detail = _isDetail();

	// If is_mixed
	if (is_mixed) {
		// Get what is selected
		var post_data = getMixedSelectedConversations();
		post_data.status = status;
		post_data.mtf = move_to_folder;
		post_data.is_search = is_search;
		_count = post_data.count;
	} else {
		// get modalid (If message is being trashed from detail only!!!)
		var messageid = getModalId();
		// Post data
		var post_data = getBulkActionPostDataArray();
		post_data.push({
			'name': 'status',
			'value': status
		});
		post_data.push({
			'name': 'mtf',
			'value': move_to_folder
		});
		post_data.push({
			'name': 'is_search',
			'value': is_search
		});
		// Mailbox ID
		var _mailbox_id = _getMailboxId();
	}


	// Label ID
	var _label_id = _getLabelId();
	// Var
	var _foldertext = '';
	var _text = '';
	var _one = false;
	var _has_more = $('#has_more').val() === 'true' ? true : false;

	_log_set_status && console.log('SetStatus(), post_data :', post_data);

	// Get translation
	var _status_trans = 'folder_' + status;
	var _status_for_trans = i18n.__(_status_trans);
	// _status = i18n.__(_status_trans);
	// _status = _status.split(' ')[0];


	// Show noty that this is now working in the background
	// if ( !is_detail && ( _count > 25 || _select_all ) ) {
	if (!is_detail) {

		// Check checked messages
		var _checked = is_mixed ? post_data.selected : getSelectedMessages();

		// If nothing is selected just stop
		if ( typeof _checked !== 'string' && !_checked.length ) return false;

		// if status changed to trash then it was done with a modal so close it
		if (status === 'trash') {
			 if (_checked.length > 15 || _select_all) {
				// Show noty
				_text = i18n.__('messages_moved_to_trash_many') + '<br><br>' + i18n.__('process_background_list');
				notyWrapper({
					text: _text,
					type: 'success'
				});
			}
		}
		else if (!is_detail) {
			// Toggle dropdown
			$('#msg_button_status').dropdown('toggle');

			//Show noty
			var _noty = _isDashboard() ? false : true;

			var selected = [];
			$('#messagelist #table-rows input[type=checkbox]:checked').each(function() {
				selected.push($(this).val());
			});
			$.each(selected, function(i, v) {
				var _lf = $('#chk_' + v).data('labelfolder');
				if (_lf) _noty = false;
			});
			// Only if _noty is true
			if (_noty && (_checked.length > 25 || _select_all) ) {
				var _text = move_to_folder ? i18n.__('conversations_moved_to_%s', _status_for_trans) : i18n.__('status_changed_to_many_%s', _status_for_trans);
				_text = _text + '<br><br>' + i18n.__('process_background_list')
				notyWrapper({
					text: _text,
					type: 'success'
				});
			}
		}

		// If mixed hide mixed buttons
		if (is_mixed) {
			_hideMixedButtons(_getGlobalHiddenType());
		}

		// Hide modal
		$('#trashSelectedMail').modal('hide');

	}

	// Now add to db
	$.ajax({
		'type': "POST",
		'url': '/message/setstatus',
		'data': post_data,
		'statusCode': {
			200: function(data) {
				// console.log('data', data);
				// console.log('_label_id', _label_id);
				// console.log('is_detail', is_detail);
				// console.log('is_search', is_search);
				// return false;
				if (is_search) {
					// Set all to false
					_select_all = false;
					// uncheck the selected messages
					$('#messagelist #table-rows input[type=checkbox]:checked').prop('checked', false);
					// hide the top section
					$('#messagelistonselect').addClass('hidden');
					// return false;
				}
				// For detail
				if (is_detail) {
					// set statuses in dropdown
					$('.dropdown_status').removeClass('hidden');
					$('#dropdown_status_' + status).addClass('hidden');
					$('.labeldropdown_mtf_status').removeClass('hidden');
					$('#labeldropdown_mtf_status_' + status).addClass('hidden');
					// Toggle dropdown
					if (status !== 'trash') {
						$('#msg_button_status').dropdown('hide');
						$('#labeldropdown_mtf').dropdown('hide');
					}
					var _text = move_to_folder ? i18n.__('conversation_moved_to_%s', _status_for_trans) : i18n.__('status_changed_to_%s', _status_for_trans);
					notyWrapper({
						text: _text,
						type: 'success'
					});
					// Located in div
					if (!data.label_folder_status_change) $('.message_detail_label_folder').addClass('hidden');
					// Set status
					$('#message_detail_status_text').html(status.toUpperCase()).addClass('highlight-background');
					// After 5s remove class again
					setTimeout(function() {
						$('#message_detail_status_text').removeClass('highlight-background');
					}, 5000);
					// Change URL
					setURLBar('/mailbox/' + _getMailboxId() + '/' + status.toLowerCase() + '/' + $('#emailchain_id').val() )
				}

				// Action Navigation
				var _kind = status === 'trash' ? 'trash' : 'status';
				// _nextActionNavigation(is_detail, _kind);

				// Check if there are any emails left. Else redirect
				if (_has_more && !is_detail && !is_search) {
					setTimeout(function() {
						Waypoint.refreshAll();
					}, 800);
					// document.location.href = data.url;
				}
				else {
					redirectEmailListIfEmpty(is_detail, is_search, is_mixed);
				}

				// Deselect all messages
				if (!is_detail && !is_mixed) {
					_uncheckAllMessages();
				}

				// Refresh Waypoints
				// Waypoint.refreshAll();

			}
		}
	});
}

// Subfunction for noty on set status
function _sub_setstatus_noty(status, one, text) {
	// Var
	var _foldertext = '';
	var _status = '';
	text = text ? text : '';
	// Set text
	_status = i18n.__('folder_' + status);
	_foldertext = one ? i18n.__('conversation_moved_to_%s', _status) : i18n.__('conversations_moved_to_%s', _status);
	_foldertext = text ? _foldertext + '<br><br>' + text : _foldertext;
	// Set Notification
	// var _text = one ? i18n.__('status_changed_to_%s', _status) : i18n.__('status_changed_to_many_%s', _status);

	notyWrapper({
		text: _foldertext,
		type: 'success'
	});
}

// Set Mark/unread
function setReadUnread(mark, is_search, is_detail, is_mixed, message_id) {

	var _text = '';
	var _count = 0;

	// is_Mixed means the records can be from different mailboxes
	is_mixed = is_mixed || is_mixed === 'true' ? true : false;

	var post_data = null

	var _count = null;

	// If is_mixed
	if (is_mixed) {
		// Get what is selected
		post_data = getMixedSelectedConversations();
		_count = post_data.count;

		post_data.mark_read = mark;

	} else {
		post_data = getBulkActionPostDataArray(message_id);
		_count = post_data.find(function(p) {
			return p.name === 'count'
		}).value;

		post_data.push({
			'name': 'mark_read',
			'value': mark
		});
	}

	// Show noty that this is now working in the background
	if (!is_detail && (_count > 25 || _select_all)) {
		notyWrapper({
			text: i18n.__('process_background_notification') + '<br><br>' + i18n.__('process_background_list'),
			type: 'success'
		});
	}

	// Toggle dropdown
	$('#msg_button_status').dropdown('toggle');

	// uncheck the selected messages
	// setTimeout(function() {
	// 	_uncheckAllMessages();
	// }, 500);

	// Now add to db
	$.ajax({
		'type': "POST",
		'url': '/message/setreadunread',
		'data': post_data,
		success: function(data) {
			// For search
			// if (is_search) {
			// 	// Set all to false
			// 	// _select_all = false;
			// 	// uncheck the selected messages
			// 	// $('#messagelist #table-rows input[type=checkbox]:checked').prop('checked', false);
			// 	// hide the top section
			// 	// $('#messagelistonselect').addClass('hidden');
			// 	// Show noty
			// 	_text = mark ? i18n.__('messages_marked_answered') : i18n.__('messages_marked_unanswered');
			// 	notyWrapper({
			// 		text: _text,
			// 		type: 'success'
			// 	});
			// }
			// For detail
			if (is_detail) {
				// Set hidden field
				$('#mark_read').val(mark);
				// Show noty
				_text = mark ? i18n.__('messages_marked_answered_one') : i18n.__('messages_marked_unanswered_one');
				notyWrapper({
					text: _text,
					type: 'success'
				});
				// return false;
			}

			// $.each(data.ids, function(i, v) {
				// Remove
				// if (mark) {
				// 	$('#' + v).removeClass('unread');
					// $('#' + v + ' td').removeClass('unread').addClass('read-email-list');
					// $('#' + v + '_subject').removeClass('unread');
				// } else {
				// 	$('#' + v).addClass('unread');
					// $('#' + v + ' td').addClass('unread');
					// $('#' + v + '_subject').addClass('unread');
				// }
				// Set notifications
				// markNotificationForEmailChainRead(v, true);
			// });


			// var _mailbox_id = _getMailboxId();

			return false;
		}
	});
}

// Move selected to trash
// function trashSelectedMailsForever(empty_trash) {
// 	// Param
// 	var messages = 0;
// 	// Get selected ones (not needed if we empty the trash)
// 	if (!empty_trash) {
// 		messages = getSelectedMessages();
// 	}
// 	// Remove modal
// 	$('#trashSelectedMailForeverAll').modal('hide');
// 	$('#trashSelectedMailForever').modal('hide');
// 	// Noty user for background option
// 	notyWrapper({
// 		text: i18n.__("empty_trash_notification"),
// 		type: 'success'
// 	});
// 	// Uncheck mail checkbox (this is a lame way to first check all and then uncheck all)
// 	$('#messagelistselect').trigger('click');
// 	// $('#messagelistselect').trigger('click');

// 	var _pn = getGlobalPluginName();
// 	var _pid = getGlobalPluginId();

// 	// Now add to db
// 	$.ajax({
// 		'type': "POST",
// 		'url': '/message/delete_forever',
// 		'data': {
// 			messageid: messages,
// 			emptytrash: empty_trash
// 		},
// 		success: function(data) {
// 			// Remove messages
// 			if (!empty_trash) {
// 				$.each(messages, function(i, v) {
// 					$('#' + v).remove();
// 					$('#collision_tr_' + v).remove();
// 				});
// 				// Check there are any more messages. If not redirect
// 				if ( !$("#table-rows tr").length ) {
// 					_globalRemoveListDivsAndShowEmptyStatus(true);
// 					// location.href = _pn ? pluginGoToURL(_pn, _pid, data.mailboxStatus) : '/mailbox/' + data.mailboxid + '/' + data.mailboxstatus;
// 				}
// 			}
// 			else {
// 				_globalRemoveListDivsAndShowEmptyStatus(true);
// 				// redirect
// 				// location.href = _pn ? pluginGoToURL(_pn, _pid, data.mailboxStatus) : '/mailbox/' + data.mailboxid + '/' + data.mailboxstatus;
// 			}
// 		}
// 	});
// }



function getBulkActionPostDataArray(message_id) {

	// Define
	message_id = message_id ? message_id : null;

	// only used in listing
	var mailboxid = $('#mailbox_id').val() || $('#mailboxid').val() || 'is_from_search';
	var listing_label_id = _getLabelId();

	// TO DO : this has not been implemented (currently value comes from session)
	var listing_status = $('#listing_status').val() || $('#message_status').val();
	// if we're working with search serialize the select data, or else use the mailboxid and listing status
	var post_data = _isSearch() ? $('#form_search_refine').serializeArray() : [{
		'mailboxid': mailboxid
	}];

	var _message_id = message_id ? message_id : _isDetail() ? $('#message_id').val() : null;

	var _selected = getSelectedMessages(_message_id);

	// messageid represents the selected fields
	post_data.push({
		'name': 'messageid',
		'value': _selected
	});
	post_data.push({
		'name': 'count',
		'value': _selected.length
	});
	post_data.push({
		'name': 'request_info',
		'value': getGlobalRequestInfo()
	});
	if (listing_label_id) {
		post_data.push({
			'name': 'listing_label_id',
			'value': listing_label_id
		});
	}
	if (listing_status) {
		post_data.push({
			'name': 'listing_status',
			'value': listing_status
		});
	}

	// if there's a filter present send that data as well
	var is_filtered = $('#is_filtered').val() == 'true';
	if (is_filtered) {
		post_data.push({
			'name': 'filter_param',
			'value': $('#filter_param').val()
		});
		post_data.push({
			'name': 'is_filtered_view',
			'value': true
		});
	}

	return post_data;
}

// Show next Message
function showNextMessage(back, previousstatus) {
	// Params
	var mailbox_id = _getMailboxId();
	var status = previousstatus || $('#message_status').val();
	var label_id = $('#label_id').val();
	var emailchain_id = $('#emailchain_id').val();
	var sort_direction = +(Cookies.get('mailbox_listing_sort_direction') || -1);

	var is_filtered_view = $('#is_filtered_view').val() === 'true';
	var filter_param = $('#filter_param').val();

	var _pn = getGlobalPluginName();
	var _pid = getGlobalPluginId();
	var _request_info = getGlobalRequestInfo();

	var data = {
		'mailbox_id': mailbox_id,
		'emailchain_id': emailchain_id,
		'status': status,
		'reverse': !!back,
		'label_id': label_id,
		'is_filtered_view': is_filtered_view,
		'filter_param': filter_param,
		'pn': _pn,
		'pid': _pid,
		'request_info': _request_info,
	};

	$.ajax({
		'type': "POST",
		'url': '/message/show_next',
		'data': data,
		'statusCode': {
			200: function(data) {
				//- Append
				var _append = '?';
				//- we have, data, assemble the url for the next message in the list
				var url = '/mailbox/' + data.mailbox_id + '/' + data.status + '/' + data._id;
				//- if we have filter params add that to the url
				if (is_filtered_view) {
					url += '/filter/' + filter_param;
				}
				//- if we're viewing a label then tack on the query string to the url
				if (data.label_id) {
					_append += 'l=' + data.label_id;
				}
				//- Plugin
				if (_pn) {
					var _prefix = _append === '?' ? '' : '&';
					_append += _prefix + 'pn=' + _pn + '&pid=' + _pid;
				}
				//- Final URL
				url += _append;
				//- Redirect
				location.href = url;
			},
			201: function(data) {
				// Depending on plugin
				var _url_label = _pn ? pluginGoToURL(_pn, _pid, data.status, data.label_id) : '/label/' + data.mailbox_id + '/' + data.label_id;
				var _url_mailbox = _pn ? pluginGoToURL(_pn, _pid, data.status) : '/mailbox/' + data.mailbox_id + '/' + data.status;
				//- no data found, send the user back to the best location based on the data we have
				if (data.label_id) {
					location.href = _url_label;
				} else if (data.mailbox_id && data.status) {
					location.href = _url_mailbox;
				} else {
					location.href = '/main';
				}
			}
		}
	});
}

// Show CC/BCC div
function showCcBcc(global) {
	var _append_global = global ? 'g' : '';
	$('#linkShowCcBcc' + _append_global).addClass('hidden');
	$('#ccbcchidden' + _append_global).removeClass('hidden');
}





// Check for the same email address in the mailbox
function sameEmailCheck(id) {
	// Get email value
	var email = $('#' + id).val();
	var mb = _getMailboxId();
	// Fire of request
	setTimeout(function() {
		$.ajax({
			type: "POST",
			url: '/mailbox/mailboxemailcheck',
			data: {
				'email': email,
				'current_mailbox_id' : mb
			},
			statusCode: {
				200: function(data) {
					$('#emailcheck').addClass('hidden');
				},
				201: function(data) {
					$('#emailcheck').removeClass('hidden');
					$('#emailcheck').html('Email address already exists! You can still save, but it is propably a good idea to change it.');
				}
			}
		});
	}, 1000);
}


// Collision UI
// function collisionDecoration(action, emailchain_id, acting_user) {
// 	// console.log('action', action);
// 	// console.log('emailchain_id', emailchain_id);
// 	// console.log('acting_user', acting_user);

// 	// Remove any existing collision classes
// 	$('.collision_reply_list').addClass('hidden');
// 	// $('.collision_view_list').addClass('hidden');

// 	var _text = '';
// 	var _content_detail = '';

// 	// View
// 	if (action === 'view') {
// 		_text = acting_user.full_name + ' ' + i18n.__("status_collision_view");
// 		_content_detail = _content_1 + _text + _content_2;
// 		// List
// 		$('#collision_tr_' + emailchain_id).removeClass('hidden collision_reply_list');
// 		$('#collision_td_' + emailchain_id).html(_content_detail);
// 		// Detail
// 		$('#collision_status_message_container_' + emailchain_id).removeClass('hidden collision_status_message_reply');
// 		$('#collision_status_message_' + emailchain_id).html(_content_detail);
// 	}
// 	// Reply
// 	if (action === 'reply') {
// 		_text = acting_user.full_name + ' ' + i18n.__("status_collision_reply");
// 		_content_detail = _content_1 + _text + _content_2;
// 		$('#collision_tr_' + emailchain_id).removeClass('hidden').addClass('collision_reply_list');
// 		$('#collision_td_' + emailchain_id).html(_content_detail);
// 		// Detail
// 		$('#collision_status_message_container_' + emailchain_id).removeClass('hidden').addClass('collision_status_message_reply');
// 		$('#collision_status_message_' + emailchain_id).html(_content_detail);
// 	}
// 	// When done (replied)
// }

// On search page show next records
// function nextRecords() {
// 	// Show Spinner
// 	$('#search_show_next_link_div').removeClass('hidden');
// 	if ($('#search_show_next_link_link').hasClass('form-submitted')) {
// 		return false;
// 	}
// 	$('#search_show_next_link_link').addClass('form-submitted');
// 	// Get values
// 	var _found = $('#search_found').val();
// 	var _page = $('#search_page').val();
// 	var _num_per_page = $('#search_num_per_page').val();
// 	var _query = $('#search_query').val();
// 	var _last_record_date = $('#search_last_record_date').val();
// 	var _total = $('#search_total').val();
// 	// console.log('found', _found);
// 	// console.log('total', _total);
// 	// console.log('page', _page);
// 	// console.log('query', _query);
// 	// console.log('last_record_date', _last_record_date);
// 	// console.log('num_per_page', _num_per_page);
// 	$.ajax({
// 		type: 'POST',
// 		url: '/search/search_next_results',
// 		data: {
// 			found: _found,
// 			page: _page,
// 			num_per_page: _num_per_page,
// 			query: _query,
// 			last_record_date: _last_record_date
// 		},
// 		statusCode: {
// 			// Error
// 			500: function(data) {
// 				alert('There was an error loading more results. Please report this. Thank you!');
// 			},
// 			// Domain does NOT exists
// 			200: function(data) {

// 				var emailchains = data.emails;

// 				// Var
// 				var _html;

// 				emailchains.forEach(function(emailchain) {

// 					// console.log('EC', emailchain)

// 					// Var
// 					var _from = '';
// 					var _labels = '';

// 					// Email to var
// 					var _email = emailchain.emails[0];
// 					var _count = emailchain.emails.length > 1 ? emailchain.emails.length : '';

// 					// Set it all together
// 					var _url = '/mailbox/' + emailchain.mailbox_id._id + '/' + emailchain.status + '/' + emailchain._id;
// 					var _attachment = emailchain.has_attachments ? '<span class="fal fa-paperclip fa-1-4"></span>' : '';
// 					var _date = getTimeForRefresh(emailchain.last_communication_date);
// 					var _assignee = emailchain.assignee ? emailchain.assignee.first_name && emailchain.assignee.last_name ? emailchain.assignee.first_name + ' ' + emailchain.assignee.last_name : '' : '';

// 					//- If the last in the message array is from the customer we mark it unread
// 					var _unread = !emailchain.mark_read ? 'unread' : '';
// 					//- If the last message in array is from an internal user we italic it
// 					var _internalonly = (_email && _email.from && _email.from.user_id && _email.from.user_id !== '') ? 'internalonly' : '';
// 					// var internalunread = (! _email.mark_read && _email && _email.from && _email.from.user_id && _email.from.user_id !== '') ? 'unread' : '';

// 					// var unread_bold = _unread === '' ? 'color:#808080' : '';
// 					// var unread_bold_internal = internalunread !== '' ? 'color:#808080' : '';

// 					//- Set SLA background
// 					var _sla_bg = emailchain.is_sla_active ? 'bg-warning' : '';
// 					_sla_bg = emailchain.is_sla_overdue ? 'sla-background-overdue' : _sla_bg;

// 					// From
// 					if (emailchain.communication_with[0]) {
// 						_from = emailchain.communication_with[0].first_name && emailchain.communication_with[0].last_name ? emailchain.communication_with[0].first_name + ' ' + emailchain.communication_with[0].last_name : emailchain.communication_with[0].email;
// 					} else {
// 						_from = _email && _email.from.user_id && _email.from.user_id.first_name && _email.from.user_id.last_name ? _email.from.user_id.first_name + ' ' + _email.from.user_id.last_name : _email && _email.from.user_id && _email.from.user_id.email ? _email.from.user_id.email : 'Helpmonks User';
// 					}

// 					// Mailbox label
// 					var _border_color = emailchain.mailbox_id.color && emailchain.mailbox_id.color.normal ? emailchain.mailbox_id.color.normal : 'grey';
// 					var _border = 'border-left: 7px solid ' + _border_color;
// 					var _mailbox_div = '<div class="clearfix paddingtop8"></div><div class="well well-sm well-dashboard-mixed" style="' + _border + '" data-url="/mailbox/' + emailchain.mailbox_id._id + '/inbox" id="mailbox_div_url_' + emailchain._id + '"><a href="/mailbox/' + emailchain.mailbox_id._id + '/inbox" target="_blank" class="clickurl" id="mailbox_div_link_' + emailchain._id + '"><span class="font-size-11 font-normal" id="mailbox_div_name_' + emailchain._id + '">' + emailchain.mailbox_id.display_name + '</span></a></div>'

// 					// Add from and mailbox together
// 					_from += _mailbox_div;

// 					// Add labels
// 					emailchain.labels.forEach(function(label) {
// 						_labels += '<div id="' + emailchain._id + '-' + label._id + '" class="pull-left badge badge-secondary" style="background-color:' + label.color + '"><a href="/label/' + emailchain.mailbox_id._id + '/' + label._id + '">' + label.name + '</a><a href="#" onclick="removeLabelFromMessage(\'' + emailchain._id + '\',\'' + label._id + '\',\'' + label.name + '\');return false;" style="padding-left:5px;">x</a></div><div class="pull-left" id="' + emailchain._id + '-' + label._id + '-pad"><span class="paddingright5"></span></div>';
// 					});

// 					_html += '<tr class="message-list ' + _sla_bg + '" id="' + emailchain._id + '"><td><label class="hm-checkbox"><input type="checkbox" value="' + emailchain._id + '" onclick="handleMessageSelect();" class="chkmedium" id="chk_' + emailchain._id + '"><span id="span_' + emailchain._id + '" class="hm-checkbox-style"></span></label></td><td class="' + _unread + ' ' + _internalonly + '"><a href="' + _url + '" style="' + _unread + '">' + _from + '</a></td><td class="td-truncate"><a href="' + _url + '"><span class="character-overflow email_header ' + _unread + ' ' + _internalonly + '"><span class="character-overflow email_header ' + _unread + '">' + _email.subject + '</span><span class="body-preview"> - ' + _email.excerpt + ' ...</span></a><div id="messagelabels_' + emailchain._id + '" class="messagelabels">' + _labels + '</div></td><td><a href="' + _url + '"><span class="badge">' + _count + '</span></a></td><td class="d-none d-md-table-cell"><a href="' + _url + '">' + _attachment + '</a></td><td class="d-none d-md-table-cell ' + _unread + ' ' + _internalonly + '"><a href="' + _url + '" style="' + _unread + '">' + _assignee + '</a></td><td class="nowrap d-none d-md-table-cell ' + _unread + ' ' + _internalonly + '"><a href="' + _url + '" style="' + _unread + '">' + _date + '</a></td><td class="d-none d-md-table-cell ' + _unread + ' ' + _internalonly + '"><a href="' + _url + '" class="capitalize-first-letter" style="' + _unread + '">' + emailchain.status + '</a></td></tr>';

// 				});

// 				// Append to list
// 				$('#messagelist #table-rows').append(_html);

// 				// Update hidden fields
// 				$('#search_found').val(data.found);
// 				$('#search_page').val(data.page);
// 				$('#search_num_per_page').val(data.num_per_page);
// 				$('#search_query').val(data.query);
// 				$('#search_last_record_date').val(data.last_record_date);

// 				// Decide to hide more results link
// 				var _total_new = parseInt(_total);
// 				var _found_new = parseInt(data.found);
// 				if (_found_new >= _total_new) {
// 					$('#search_show_next_link').addClass('hidden');
// 				}

// 				$('#search_show_next_link_div').addClass('hidden');
// 				$('#search_show_next_link_link').removeClass('form-submitted');

// 			}
// 		}
// 	});
// }

// Return selected messages in list
function getSelectedMessagesWithType(type) {
	// Set array
	var selected = [];
	// If messageid is 0 (from list)
	$('#messagelist_' + type + ' #table-rows input[type=checkbox]:checked').each(function() {
		selected.push($(this).val());
	});
	// Return
	return selected;
}

// Calling this to post to search page
function doSearchPost(search_term) {
	// Insert search term to input field
	$('#form_search #search_text').val(search_term);
	// Make sure to open in a new window
	$('#form_search').attr('target', '_blank');
	// Submit
	$('#form_search').submit();
	// Make sure the spinning icon is reset
	$('#navbar_search_icon_button').prop('disabled', false);
	// $('#navbar_search_icon').addClass('fa-search').removeClass('fal fa-sync-alt fa-spin fa-1x');
	// Reset target
	$('#form_search').removeAttr('target');
	// Remove value
	$('#form_search #search_text').val('');
}

//- Load stats
function loadUserDetailStats(div) {
	//- Check if new
	var _is_new = $('#is_new').val();
	//- if true abort
	if (_is_new === 'true') return false;

	//- Load stats

	//- Get userid
	var _id = $('#user_id').val();
	//- Load stats
	$.ajax({
		type: "GET",
		url: '/settings/user/stats/' + _id,
		statusCode: {
			200: function(data) {
				$('#' + div + '_' + _id).html(data);
			},
			500: function(data) {
				var _text = '<span>An error occured loading this users status. Please be so kind and report it. Thank you.</span>'
				$('#' + div + '_' + _id).html(_text);
			}
		}
	});
}

// -----------------------------------------------
// ------------------------------------- Show type of messages for detail view
// -----------------------------------------------

function showMessageTypeinDetailView(type) {
	// Remove animation
	// _removeLoadingAnimationGlobal();
	// Var type class
	var _class = '.' + type;
	// Split
	var _type_split = type.split('_');
	// Check on class
	var _hidden_or_show = $(_class).hasClass('show') ? 'show' : 'hidden';
	// Do the CSS stuff
	if (_hidden_or_show === "show") {
		$(_class).removeClass('show').addClass('hidden');
		$('#message_detail_toggle_' + type + ' i').addClass('helpmonks-text-tomato');
	} else {
		$(_class).removeClass('hidden').addClass('show');
		$('#message_detail_toggle_' + type + ' i').removeClass('helpmonks-text-tomato');
	}

	// BUTTON: Switch tooltip and button class
	var _trans = i18n.__(_hidden_or_show + '_' + _type_split[1]);
	// Set title
	$('#message_detail_toggle_' + type).attr({
		'title': _trans,
		'data-original-title': _trans
	});

	// Save state into cookie
	Cookies.set(type, _hidden_or_show === 'show' ? 'hidden' : 'show', _globalCookieOptions(365));
}

// -----------------------------------------------
// ------------------------------------- Get custom code
// -----------------------------------------------

function getCustomCode() {

	//- Do not show on profile pages
	if (window.location.href.includes('/profile/')) return;
	//- Do not show on search results page
	if (window.location.href.includes('/message/search')) return;
	//- Do not show on login page
	if (window.location.href.includes('login')) return;
	if (window.location.href.includes('reset')) return;
	if (window.location.href.includes('forgot')) return;
	if (window.location.href.includes('account')) return;
	if (window.location.href.includes('register')) return;
	if (window.location.href.includes('signup')) return;
	if (window.location.href.includes('admin')) return;

	jQuery.cachedScript = function( url, options ) {
		// Allow user to set any option except for dataType, cache, and url
		options = $.extend( options || {}, {
			dataType: "script",
			cache: true,
			url: url
		});
		// Use $.ajax() since it is more flexible than $.getScript
		// Return the jqXHR object so we can chain callbacks
		return jQuery.ajax( options );
	};

	// Usage
	$.cachedScript('/load_custom_code');

}




var _goto_interval;
var _goto_term;
var _term;
var _submit = false;
var _typing_timer;
var _done_typing_interval = 1000;
var _done_typing = false;
$(function () {

	// For clicking the back button if a modal
	// $(window).on('popstate', function (e) {
	// 	var state = e.originalEvent.state;
	// 	if (!state) return;
	// 	$('#goToModal').modal('hide');
	// });

	// Grab location
	$(document).on('show.bs.modal', '#goToModal', function (e) {
		setInitialUrl({ get: true, app: 'search' });
		sessionStorage.setItem('initial_title', document.title);
	});
	//- Check for any recent visited pages
	$(document).on('shown.bs.modal', '#goToModal', function (e) {
		//- Make sure stuff is set
		$('#goToModal #go_to_termm').removeClass('no-text').attr('placeholder', i18n.__('search') + '...');
		$('#goToModal #goto-append').addClass('hidden').removeClass('dot-wrapper').html('');
		$('#goToModal .goto-spinner').addClass('hidden');
		$('#goToModal #go_to_termm').focus();
	})
	//- Event on close
	$(document).on('hide.bs.modal', '#goToModal', function (e) {
		// setInitialUrl({ set: true, app: 'search' });
		$('#goToModal #go_to_termm').val('');
		$('#goToModal #goto-append').addClass('hidden').html('');
		$('#goToModal .goto-spinner').addClass('hidden');
		_goto_term = null;
		_submit = false;
	});
	// $(document).on('hidden.bs.modal', '#goToModal', function (e) {
	// 	setInitialUrl({ set: true, app: 'search' });
	// });
	//- Catching any up or down on keyup
	$(document).on('keyup', '#goToModal #go_to_termm', function (e) {
		//- If advanced search is open we stop here
		if ($('.mail_advanced_search_div').is(':visible')) {
			return;
		}
		//- Code
		var _key = e.key;
		//- Check key
		switch (_key) {
			case "Shift":
			case "Meta":
			case "Control":
			case "Alt":
			case "Enter":
			case "Esc":
			case "Escape":
			case "Tab":
			case "ArrowDown":
			case "Down":
			case "ArrowUp":
			case "Up":
				e.preventDefault();
				return;
			case "ArrowLeft":
			case "Left":
			case "ArrowRight":
			case "Right":
				//- case "Delete":
				//- case "Backspace":
				return;
			default:
			// Cancel the default action to avoid it being handled twice
			//- e.preventDefault();
		}
		clearTimeout(_typing_timer);
		if ($('#goToModal #go_to_termm').val()) {
			$('#goToModal .goto-spinner').removeClass('hidden');
			_typing_timer = setTimeout(doneTyping, _done_typing_interval);
		}
		else {
			$('#goToModal .goto-spinner').addClass('hidden');
		}
	});
	function doneTyping() {
		//- If already submited return
		if (_submit) {
			return;
		}
		//- Value
		_term = $('#goToModal #go_to_termm').val();
		//- If term is already stored
		if (_term === _goto_term) {
			_submit = false;
			return;
		}
		//- Less than 3 chars
		if (_term.length < 2) {
			$('#goToModal #goto-append').addClass('hidden').html('');
			_submit = false;
			_goto_term = null;
			return;
		}
		//- Show loader
		$('#goToModal .goto-spinner').removeClass('hidden');
		//- Set submit
		_submit = true;
		//- Set term in var to prevent same query several times
		_goto_term = _term;
		//- Call sub function
		_goto_sub();
	}
	//- Here is the core :)
	$(document).on('keydown', '#goToModal #go_to_termm', function (e) {
		// Do nothing if event already handled
		if (e.defaultPrevented) {
			return;
		}
		//- If already submited return
		if (_submit) {
			return;
		}
		//- Code
		var _key = e.key;
		//- Check key
		switch (_key) {
			case "Shift":
			case "Meta":
			case "Control":
			case "Alt":
			case "Enter":
			case "Esc":
			case "Escape":
			case "Tab":
			case "ArrowDown":
			case "Down":
			case "ArrowUp":
			case "Up":
				e.preventDefault();
				return;
			case "ArrowLeft":
			case "Left":
			case "ArrowRight":
			case "Right":
			case "Delete":
			case "Backspace":
				return;
			default:
			// Cancel the default action to avoid it being handled twice
			//- e.preventDefault();
		}
	})
	//- Search again
	$(document).on('click', '.goto-search-again', function (e) {
		_goto_term = null;
		_term = $(this).data('term');
		//- Search
		_goto_sub();
		//- Add to input
		$('#go_to_termm').val(_term);
		//- Go to top
		$('.modal-body').animate({ scrollTop: 0 }, 1000)
	})
})
// Called from history
function _goToFromHistory(_term) {
	_goto_term = null;
	_term = _term;
	//- Set term
	$('#goToModal #go_to_termm').val(_term);
	//- Call sub function
	_goto_sub({ no_store: true, term: _term });
}
//- Calling search
function _goto_sub(options) {
	options = options || {};
	options.no_store = typeof options.no_store !== 'undefined' ? options.no_store : false;
	_term = options.term || _term || $('#goToModal #go_to_termm').val();
	// Capture
	captureEvent({ action: 'mail_search_executed' });
	// Get
	$.ajax({
		type: "POST",
		url: '/goto',
		data: { term: _term },
		statusCode: {
			// Error
			500: function (data) {
				$('#goToModal .goto-spinner').addClass('hidden');
				_submit = false;
				_goto_term = null;
				console.error('ERROR with goto');
				return;
			},
			// Done
			200: function (data) {
				//- console.log('data', data);
				if (!options.no_store) {
					document.title = i18n.__('search') + ': ' + _term;
					var _jsurl = createJSURL({ st: _term });
					setURLBar(`/search?g=${_jsurl}`, { st: _term, app: 'search', url: `/search?g=${_jsurl}` });
				}
				//- Vars
				var _html_navigation = '';
				var _html_emails = '';
				var _html_mailboxes = '';
				var _html_customers = '';
				var _html_companies = '';
				var _html_files = '';
				var _html = '';
				//- EMAILS
				$.each(data.emails, function (i, v) {
					_html_emails += _searchHtml(v, 'email');
				})
				var _more_emails = data.count_emails > 10 ? '<div class="mt-10"><a class="btn btn-info btn-sm" href="#" onclick="goToDoSearchEmails()" id="goto_more_emails"><span class="indicator-label">' + i18n.__('search_show_more') + '</span><span class="indicator-progress">Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span></a></div>' : '';
				_html += data.emails && data.emails.length ? '<div class="tab-pane fade show active" id="search_emails" role="tabpanel"><div class="mt-5">' + _html_emails + _more_emails + '</div></div>' : '';
				//- CUSTOMERS
				$.each(data.customers, function (i, v) {
					_html_customers += _searchHtml(v, 'customer');
				})
				var _more_customers = data.count_customers > 10 ? '<div class="mt-10"><a class="btn btn-info btn-sm" href="#" onclick="goToDoSearchCustomers()" id="goto_more_contacts"><span class="indicator-label">' + i18n.__('search_show_more') + '</span><span class="indicator-progress">Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span></a></div>' : '';
				_html += data.customers && data.customers.length ? '<div class="tab-pane fade" id="search_customers" role="tabpanel"><div class="mt-5">' + _html_customers + _more_customers + '</div></div>' : '';
				//- COMPANIES
				$.each(data.companies, function (i, v) {
					_html_companies += _searchHtml(v, 'companies');
				})
				var _more_companies = data.count_companies > 10 ? '<div class="mt-10"><a class="btn btn-info btn-sm" href="#" onclick="goToDoSearchCompanies()" id="goto_more_companies"><span class="indicator-label">' + i18n.__('search_show_more') + '</span><span class="indicator-progress">Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span></a></div>' : '';
				_html += data.companies && data.companies.length ? '<div class="tab-pane fade" id="search_companies" role="tabpanel"><div class="mt-5">' + _html_companies + _more_companies + '</div></div>' : '';
				//- FILES
				$.each(data.files, function (i, v) {
					_html_files += _searchHtml(v, 'files');
				})
				var _more_files = data.count_files > 10 ? '<div class="mt-10"><a class="btn btn-info btn-sm" href="#" onclick="goToDoSearchFiles()" id="goto_more_companies"><span class="indicator-label">' + i18n.__('search_show_more') + '</span><span class="indicator-progress">Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span></a></div>' : '';
				_html += data.files && data.files.length ? '<div class="tab-pane fade" id="search_files" role="tabpanel"><div class="mt-5">' + _html_files + _more_files + '</div></div>' : '';
				//- MAILBOXES
				$.each(data.mailboxes, function (i, v) {
					_html_mailboxes += _searchHtml(v, 'mailbox');
				})
				_html += data.mailboxes && data.mailboxes.length ? '<div class="tab-pane fade" id="search_mailboxes" role="tabpanel"><div class="mt-5">' + _html_mailboxes + '</div></div>' : '';
				//- NAVIGATION
				$.each(data.navigation, function (i, v) {
					var _click = v.js ? v.js : null;
					_html_navigation += _searchHtml(v, 'navigation');
				})
				_html += data.navigation && data.navigation.length ? '<div class="tab-pane fade" id="search_navigation" role="tabpanel"><div class="mt-5">' + _html_navigation + '</div></div>' : '';
				//- Tabs
				var _tabs = '<ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold flex-nowrap">';
				if (data.emails && data.emails.length) {
					_tabs += ` <li class="nav-item">
								<a class="nav-link text-active-primary me-6 active" data-bs-toggle="tab" href="#search_emails">Emails</a>
							</li>`;
				}
				if (data.customers && data.customers.length) {
					_tabs += ` <li class="nav-item">
								<a class="nav-link text-active-primary me-6" data-bs-toggle="tab" href="#search_customers">Contacts</a>
							</li>`;
				}
				if (data.companies && data.companies.length) {
					_tabs += ` <li class="nav-item">
								<a class="nav-link text-active-primary me-6" data-bs-toggle="tab" href="#search_companies">Companies</a>
							</li>`;
				}
				if (data.files && data.files.length) {
					_tabs += ` <li class="nav-item">
								<a class="nav-link text-active-primary me-6" data-bs-toggle="tab" href="#search_files">Files</a>
							</li>`;
				}
				if (data.mailboxes && data.mailboxes.length) {
					_tabs += ` <li class="nav-item">
								<a class="nav-link text-active-primary me-6" data-bs-toggle="tab" href="#search_mailboxes">Mailboxes</a>
							</li>`;
				}
				if (data.navigation && data.navigation.length) {
					_tabs += ` <li class="nav-item">
								<a class="nav-link text-active-primary me-6" data-bs-toggle="tab" href="#search_navigation">Navigation</a>
							</li>`;
				}

				_tabs += '</div>';
				//- HTML
				_html = _html ? '<div class="py-3">' + _tabs + '<div class="tab-content">' + _html + '</div></div>' : '';
				_html += '<div class="goto-items-end"></div>';
				//- Add to ele
				$('#goToModal #goto-append').removeClass('hidden').html(_html);
				//- Call function to use arrow keys
				//- callHmKeyNavigation();
				//- Reset submit
				_submit = false;
				//- Reset stored term
				_goto_term = null;
				//- goto-spinner
				$('#goToModal .goto-spinner').addClass('hidden');
			}
		}
	});
}
function _searchHtml(obj, type) {
	//- console.log('obj', obj);
	var _hover_color = `text-hover-${obj.color}`;
	var _color = `bg-light-${obj.color} bg-hover-light-${obj.color} ${_hover_color}`;
	var _icon = `${obj.icon}`;
	var _header = '';
	var _title = '';
	var _date = '';
	var _mailbox = '';
	var _mailbox_id = '';
	var _kind = '';
	var _text = '';
	var _click = '';
	var _status = '';
	var _modal_class = 'open-detail-modal';
	switch (type) {
		case 'email':
			_date = `<span class="fs-8 text-muted ms-3">${obj.last_communication_date}</span>`;
			_header = obj.subject;
			_title = obj.subject;
			_text = obj.excerpt;
			_mailbox = `<span class="noevent badge ms-2 fs-8 text-nowrap mail-item-mailbox bg-light-info text-gray-600" data-mailbox='${obj.mailbox_id._id}'><span>${obj.mailbox_id.name}</span></span>`;
			_mailbox_id = obj.mailbox_id._id;
			_status = `<span class="ms-3 noevent badge badge-secondary fs-8 text-nowrap text-uppercase">${obj.status}</span>`
			_kind = 'email';
			break;
		case 'customer':
			_header = obj.name;
			_title = obj.name;
			_text = obj.email + `<button class="btn btn-light btn-icon btn-xs btn-clipboardjs ms-1 noevent" type="button" data-clipboard-text='${obj.email}'><span class='${_fa_style_light}clone'></span></button>`;
			_kind = 'contact';
			break;
		case 'companies':
			_header = obj.name;
			_title = obj.name;
			_text = obj.dns;
			_kind = 'company';
			break;
		case 'mailbox':
			_header = `<span class="noevent mail-item-mailbox ms-n3" data-mailbox='${obj._id}'><span>${obj.name}</span></span>`;
			_title = obj.name;
			_text = obj.email + `<button class="btn btn-light btn-icon btn-xs btn-clipboardjs ms-1 noevent" type="button" data-clipboard-text='${obj.email}'><span class='${_fa_style_light}clone'></span></button>`;
			_hover_color = `${_hover_color} noevent mail-item-mailbox`;
			_mailbox_id = obj._id;
			break;
		case 'navigation':
			_header = obj.display;
			_title = obj.display;
			_click = `onclick="${obj.js}"`;
			break;
		case 'files':
			_header = obj.name;
			_title = obj.name;
			_text = obj.excerpt;
			_click = `onclick="filesFileSlider('${obj._id}');return false;"`;
			_modal_class = '';
			break;
	}
	return `<div data-type="${type}" data-title="${_title}" data-id="${obj._id}" data-mailbox="${_mailbox_id}" data-kind="${_kind}" ${_click} class="goto-item d-flex align-items-start mb-2 py-2 hoverable bg-hover-light ${_modal_class} ${_hover_color}">
				<div class="ps-2 w-30px symbol symbol-30px flex-shrink-0 me-4">
					<div class="symbol-label hoverable inherit-color ${_color}">
						<span class="fa-1x ${_fa_style_light}${_icon}"></span>
					</div>
				</div>
				<div class="ps-2">
					<span class="text-gray-800 fw-bold mb-1 inherit-color">${_header}${_status}${_date}
						<div class="text-muted fw-bold fs-8 goto-item-excerpt">${_text}${_mailbox}</div>
					</span>
				</div>
			</div>`;
}
//- Show advanced search form
function mailShowAdvancedSearch() {
	//- if div open close it
	if ($('.mail_advanced_search_div').is(':visible')) {
		$('.mail_advanced_search_div').addClass('hidden');
		return;
	}
	//- Show div
	$('.mail_advanced_search_div').removeClass('hidden');
	//- Status
	$('#mail-search-adv-status-ddm').select2({
		dropdownParent: $('#goToModal'),
		placeholder: {
			id: "-1",
			text: ""
		}
	});
	//- Mailboxes
	getMailboxes().then(mb => {
		$('#mail-search-adv-mailbox-ddm').select2({
			data: mb,
			dropdownParent: $('#goToModal'),
			placeholder: {
				id: "-1",
				text: ""
			}
		});
	});
	//- Users
	getAccountUsers().then(d => {
		$(`#mail-search-adv-users-ddm`).select2({
			data: d.select2,
			dropdownParent: $('#goToModal'),
			placeholder: {
				id: "-1",
				text: ""
			}
		});
	})
	//- Labels
	mailLabelsSelect2('#mail-search-adv-labels-ddm');
	//- Date
	$(`#mail-search-adv-date-datem`).flatpickr({
		dateFormat: 'Y-m-d',
		altFormat: 'F j, Y',
		altInput: true,
		enableTime: false,
		maxDate: 'today',
		//- inline: true,
		//- defaultDate: new Date( moment.tz(`#{cookies.time.time_zone}`).subtract(1, 'd') )
	});
	//- Focus on search term
	$('#goToModal #go_to_termm').focus();
}

// Function to remove script tags from emails
function removeScriptString(str) {
	var SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
	return str.replace(SCRIPT_REGEX, "");
}
// Escape text
function htmlEscape(str) {
	return String(str)
		.replace(/&/g, '&amp;')
		.replace(/"/g, '&quot;')
		.replace(/'/g, '&#39;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;');
}
// Unescape text
function htmlUnescape(value) {
	return String(value)
		.replace(/&quot;/g, '"')
		.replace(/&#39;/g, "'")
		.replace(/&lt;/g, '<')
		.replace(/&gt;/g, '>')
		.replace(/&amp;/g, '&');
}
function focusDelay(div) {
	setTimeout(function() {
		$('#' + div).focus();
	}, 100);
}

// This is a general service function that allows you to load your content into any div you define
// The function takes two arguments:
// route = define your full route, e.g. /settings/labels/someid
// div = The div you want to load your content in (by default this uses the modal content)
function loadDynamicContent(route, div, post_data) {
	post_data = post_data || {};
	// Define standard div
	var loadindiv = '#dynamicmodalcontent-' + div;
	// Clear content of div
	$(loadindiv).html('');
	// Post and set data
	$.post(route, post_data, function(data) {
		$(loadindiv).html(data);
		// Hide any loading div
		$('.loading-div-modal').addClass('hidden');
	});
}

// Global function to grab modalid
function getModalId(modalname) {
	modalname = modalname ? modalname + ' ' : '';
	var theid = $( modalname + '#modalid').val();
	return theid;
}

// Show detail pane
function showDetails() {
	// Remove class from maincontent
	$('#mainContent').removeClass('col-xs-12 stretch');
	// Resize maincontent part
	$('#mainContent').addClass('col-xs-4 resized');
	// Now show details div
	$('#divDetails').removeClass('hidden').addClass('show');
	// $("#divDetails").css("width",$(document).width()*0.5-640);
}

function hideDetails() {
	// Remove class from maincontent
	$('#mainContent').removeClass('col-xs-4');
	// Resize maincontent part
	$('#mainContent').addClass('col-xs-12 stretch');
	// Now show details div
	$('#divDetails').removeClass('show').addClass('hidden');
}

//- Flush Cache
function flushCache() {
	// Remove sessionstorage too
	sessionStorage.clear();
	// Add spinner to button
	$('#admin_flush_cache').attr('disabled', true).html(`<span class="${_fa_style}sync-alt fa-spin fa-1x"></span>`);
	// Post to API
	$.ajax({
		type: "POST",
		url: '/settings/flush_cache',
		statusCode: {
			// Error
			500: function(data) {
				$('#cache_success').removeClass('show').addClass('hidden');
				$('#cache_alert').removeClass('hidden').addClass('show').delay(5000).fadeOut('slow', function() {
					$('#cache_alert').removeClass('hidden').addClass('hidden')
				});
				$('#admin_flush_cache').attr('disabled', false).html(i18n.__('cache_flush_button'));
			},
			// Domain does NOT exists
			200: function(data) {
				$('#cache_alert').removeClass('show').addClass('hidden');
				$('#cache_success').removeClass('hidden').addClass('show').delay(5000).fadeOut('slow', function() {
					$('#cache_success').removeClass('hidden').addClass('hidden')
				});
				$('#admin_flush_cache').attr('disabled', false).html(i18n.__('cache_flush_button'));
			}
		}
	});
	return false;
}

//- Load ajax content
function loadContent(the_div, the_url) {
	$(the_div).load(the_url);
}

// Form: Get Action URL
function formaction(theid) {
	var theaction = $('#' + theid).attr("action");
	return theaction;
}

// Form: Serialize Data
function formserialize(theid) {
	var theser = $('#' + theid).serialize();
	return theser;
}

// Form: Serialize Data from Class
function formSerializeParam(theid) {
	var theser = $(theid).serialize();
	return theser;
}

// Check if any form values are empty
function formEmptyValues(items) {
	var _empty = true;
	var _values = items.split('&');
	$.each(_values, function(i, v) {
		var _v = v.split('=');
		// if value is there
		if (_v[1]) _empty = false;
	});
	// Return
	return _empty;
}

// Loads the view from the tree
function loadView(type, id) {
	// Load the view
	$('#mainContent').load('/' + type + '/' + id);
	// URL Bar
	setURLBar('/' + type + '/1/' + id);
}

// Set the URL bar
function setURLBar(theurl, options) {
	// console.log("🚀 ~ file: app_utils.js:184 ~ setURLBar ~ theurl:", theurl)
	if (!theurl) return;
	options = options || {};
	options.url = theurl;
	options._v = new Date().getTime();
	// var _append = theurl.indexOf('?') > -1 ? '&=' : '?=';
	// theurl += `${_append}${options._v}`;
	// console.log("🚀 ~ file: app_utils.js:195 ~ setURLBar ~ options:", options)
	history.pushState(options, null, theurl);
}

function getQueryString(encoded) {
	// encoded = encoded || false;
	var _query_string = document.location.search.substring(1);
	// if (encoded && _query_string.g) {
	// 	_query_string = window.atob(_query_string.g);
	// }
	return _parseQueryString(_query_string);
}

function _parseQueryString(query) {

	var parts = query.split('&');
	var params = Object.create(null);

	for (var i = 0, ii = parts.length; i < ii; ++i) {
		var param = parts[i].split('=');
		var key = param[0].toLowerCase();
		var value = param.length > 1 ? param[1] : null;
		params[decodeURIComponent(key)] = decodeURIComponent(value);
	}

	return params;

}

// Escape string
function escapeString(str) {
	var matchOperatorsRe = /[|\\{}()[\]^$+*?.'"]/g;
	return str.replace(matchOperatorsRe, '\\$&');
}

function getServerValidationErrorsHtml(errors) {
	var html = '<strong>Validation Errors</strong><br><ul>';
	for (var key in errors) {

		var display_message = errors[key].message || 'an error occured';

		display_message = typeof display_message === 'object' ? JSON.stringify(display_message) : display_message;

		html += '<li>' + display_message + '</li>';
	}
	return html + '</ul>';
}

function getParameterByName(name) {
	name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
	var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
		results = regex.exec(location.search);
	return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function uuid() {
	function s4() {
		return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
	}
	return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

function cancelBackspace(event) {
	if (event.which === 8) {
		event.preventDefault();
		return false;
	}
}

// Uncheck all messages in messagelist
function _uncheckAllMessages() {
	$('#messagelistonselect').addClass('hidden');
	$('#show_select_all').addClass('hidden');
	$('th input[type=checkbox]').prop('checked', false);
	var _checkboxes = $('#messagelist #table-rows').find(':checkbox');
	_checkboxes.prop('checked', false);
}

// Select all
function selectAllElements(ele) {
	// Check/Uncheck elements
	var _chk = $('.' + ele);
	_chk.prop("checked", !_chk.prop("checked"));
}

// Check if there are any emails left
function redirectEmailListIfEmpty(is_detail, is_search, is_mixed) {
	// Plugin
	var _pn = getGlobalPluginName();
	var _pid = getGlobalPluginId();
	// var _cookie_append = _pid ? _pid : _getMailboxId();
	// console.log("_cookie_append", _cookie_append);
	// Check if we have any summary
	// var _summary_assigned = $('#summary_assigned_body .card').length || 0;
	// var _summary_pending = $('#summary_pending_body .card').length || 0;

	// var _assignee_cookie = Cookies.remove('email_list_assignee_' + _cookie_append);
	// var _pending_cookie = Cookies.remove('email_list_pending_' + _cookie_append);

	// var _summary_here = _assignee_cookie ? $('#summary_user_' + _assignee_cookie.assignee).length : 0;

	// Always remove list cookies or else users cannot load assignee or pending lists properly
	// Cookies.remove('email_list_assignee_' + _cookie_append);
	// Cookies.remove('email_list_pending_' + _cookie_append);

	if ( $('#has_more').val() === 'true' ) return;

	// if (is_detail) {
	// 	Cookies.remove('email_list_assignee_' + _cookie_append);
	// 	Cookies.remove('email_list_pending_' + _cookie_append);
	// }

	var _summary_assigned = $('#summary_assigned_body div').length;
	var _summary_pending = $('#summary_pending_body div').length;

	//- if any summary is not 0 = reload the view
	// if ( !_summary_assigned ) {
	// 	// Delete cookie
	// 	console.log('remove cookie', 'email_list_assignee_' + _cookie_append)
	// 	return Cookies.remove('email_list_assignee_' + _cookie_append);
	// 	// Reload
	// 	// setTimeout(function() {
	// 	// 	document.location.href = document.location.href;
	// 	// }, 500);
	// 	// return;
	// }
	// if ( !_summary_pending ) {
	// 	// Delete cookie
	// 	Cookies.remove('email_list_pending_' + _cookie_append);
	// 	// Reload
	// 	// setTimeout(function() {
	// 	// 	document.location.href = document.location.href;
	// 	// }, 500);
	// 	return;
	// }
	// Do
	if (!_summary_assigned && !_summary_pending && !is_detail && !is_search && !is_mixed) {
		// Check if empty
		var _found = $('#messagelist #table-rows tr').is(':visible');
		if (_found) {
			return false;
		}
		// Get values
		var _status = _getGlobalHiddenStatus();
		// console.log("_status", _status);
		var _mailbox_id = _getMailboxId();
		var _label_id = _getLabelId();
		var _pn = getGlobalPluginName();
		var _pid = getGlobalPluginId();
		// Set URL
		var _url = _pn ? pluginGoToURL(_pn, _pid, _status) : '/mailbox/' + _mailbox_id + '/' + _status;
		// For label
		if (typeof _label_id !== 'undefined') {
			_url = _pn ? pluginGoToURL(_pn, _pid, _status, _label_id) : '/label/' + _mailbox_id + '/' + _label_id;
		}
		// Redirect
		// return location.href = _url;
		// Remove messagelist
		$('#messagelist').remove();
		$('.folders_navigation_buttons_list').remove();

		var _icon = '';
		var _text = '';
		var _div_empty = `<div id="messagelistpage_${_status.toLowerCase()}_empty_${_mailbox_id}"></div>`;

		switch(_status) {
			case 'Inbox':
				_icon = 'class="img img-fluid mx-auto d-block" src="' + _du + '/dist2/images/meditation-man-at-work-1417434-450-60p.png"';
				_text = i18n.__('no_messages');
				break;
			case 'Trash':
				_icon = 'class="img img-fluid mx-auto d-block" src="' + _du + '/dist2/images/meditation-man-at-work-1417434-450-60p.png"';
				_text = i18n.__('no_messages_trash') + '<br><small>' + i18n.__('remove_messages_in_trash_or_spam_%s','Trash') + '</small>';
				break;
			case 'Drafts':
				_icon = 'class="img img-fluid mx-auto d-block" src="' + _du + '/dist2/images/meditation-man-at-work-1417434-450-60p.png"';
				_text = i18n.__('no_drafts');
				break;
			case 'Collisions':
				_icon = 'class="img img-fluid mx-auto d-block" src="' + _du + '/dist2/images/meditation-man-at-work-1417434-450-60p.png"';
				_text = i18n.__('no_messages_collisions');
				break;
			default:
				_icon = 'class="img img-fluid mx-auto d-block" src="' + _du + '/dist2/images/meditation-man-at-work-1417434-450-60p.png"';
				_text = i18n.__('no_messages_general');
		}

		var _html = `<div class="d-flex justify-content-center paddingtop60">
			<div class="w-75 message-no">
				<img ${_icon}">
				<div class="pt-4"></div>
				<h3>${_text}</h3>
			</div>
		</div>${_div_empty}`.replace(/\t/gm,'').replace(/\n/gm,'');

		$('.main').html(_html);

	}
}

// -----------------------------------------------
// ------------------------------------- iOS Homescreen install
// -----------------------------------------------

function needsToSeeiOsPrompt() {
	if (navigator.standalone) {
		return false;
	}
	var lastPrompt = Cookies.get('lastSeeniOsPrompt');
	var isApple = ['iPhone', 'iPad', 'iPod'].includes(navigator.platform);
	// var isApple = true;
	return !lastPrompt && isApple;
}

function iOsPrompt() {
	if ('serviceWorker' in navigator && needsToSeeiOsPrompt()) {
		// Remove overlay
		removeBodyOverlay();
		// Set cookie
		Cookies.set('lastSeeniOsPrompt', true, _globalCookieOptions(14))
		// Show notification
		notyWrapper({
			text: '<div class="text-center"><div class="card"><div class="card-body"><img src="/images/Helpmonks-icon-white-192x192.png" class="img-fluid"><h3>Install Helpmonks</h3><p class="font-size-16">Install Helpmonks on your home screen for quick and easy access when you\'re on the go.</p><p class="font-size-14">Click on the "share" button and then select "Add to home screen".</p></div></div></div>',
			type: 'alert',
			timeout: false,
			layout: 'center',
			cb_onclose: function() {
				removeBodyOverlayClass('body-overlay-ios')
			}
		});
		// Add overlay without spinner
		loadBodyOverlayNoLoading('body-overlay-ios');
	}
}

// -----------------------------------------------
// ------------------------------------- THEME
// -----------------------------------------------

function resetTheme(dark) {
	// Get location
	// var _loc = location.href;
	// _loc = _loc.replace('#','');
	var _dark = $('#themeSwitch').is(':checked');
	// Update user pref
	$.get('/user/theme_selection/' + _dark, function() {
		KTMenu.hideDropdowns();
		_dark = _dark ? 'dark' : 'light';
		KTThemeMode.setMode(_dark);
		// return document.location.href = _loc;
	});
};



// Check Chat Status
function checkChat() {
	// Call route to remove record on success close the window and refresh list
	$.ajax({
		type: "GET",
		url: '/chat/status'
	}).done(function(data) {
		if (data === 'online') {
			$('#theLiveChatButton').html(`<i class="${_fa_style}comment"></i> Live Chat`);
		} else {
			$('#theLiveChatButton').html(`<i class="${_fa_style}envelope-o"></i> Feedback`);
		}
	});
}




// Animate Notification
function addAnimation(close_slider) {
	// var _new = getLocalStorage('hm_notifications_new');
	if (_new === 'true') {
		// // Animation
		// if (close_slider) {
		// 	$('#notification_badge').removeClass('hidden');
		// }
		// else {
		// $('#notification_badge').removeClass('hidden').addClass('animating');
		$('#notification_badge').removeClass('hidden');
		// removeAnimation('#notification_badge');
		// }
	} else {
		$('#notification_badge').addClass('hidden');
	}
}

// Remove Animation
function removeAnimation(thediv) {
	setTimeout(function() {
		$(thediv).removeClass('animating');
	}, 1000);
}

// -----------------------------------------------
// ------------------------------------- SPF / DKIM Check
// -----------------------------------------------

// function globalGetSpfDkimStatus(mailbox_id) {
// 	// Get mailbox
// 	mailbox_id = mailbox_id ? mailbox_id : _getMailboxId();
// 	// Get settings
// 	$.ajax({
// 		type: "GET",
// 		url: '/mailbox/settings/global_spf_dkim/' + mailbox_id,
// 		statusCode: {
// 			// Error
// 			500: function(data) {
// 				// console.log("500 data", data);
// 				console.log('Error getting global spf and dkim', data);
// 			},
// 			// Done
// 			200: function(data) {
// 				// console.log("200 data", data);
// 				// if ( !data.spf_status || !data.dkim_status ) {
// 				if ( !data.spf_status ) {
// 					waitForElementNew('.sidebar_spf_dkim_alert', function() {
// 						$('.sidebar_spf_dkim_alert').removeClass('hidden');
// 					}, 1000, 10000);
// 				}
// 			},
// 			// Sendgrid alert
// 			201: function() {
// 				notyWrapper({
// 					text: 'There is an issue with your Sendgrid DNS records.<br><br>Please click here to fix it immediately as this impacts the delivery of your emails!',
// 					type: 'warning',
// 					timeout: false,
// 					func: function() {
// 						mailMailboxSettingsModal({ _id : mailbox_id, name : '' }, 'smtp');
// 						// document.location.href = '/mailbox/settings/connection/' + mailbox_id;
// 					}
// 				})
// 			}
// 		}
// 	});
// }


function loadTranslations() {
	// console.log('loading trans')
	_subject = i18n.__('desktop_notification_header');
	_excerpt = i18n.__('desktop_notification_enabled');
}


function _urlPathname() {
	var _url_pathname = window.location.pathname;
	_url_pathname = _url_pathname === '/main' ? 'all' : _url_pathname;
	// if path is not all
	if (_url_pathname !== 'all') {
		// Split path (first in array will be empty as it starts with an /)
		var _split = _url_pathname.split('/');
		// If second starts with a number we need to take the first one
		var _re = /^[0-9]/;
		var _test_1 = _re.test(_split[1]);
		var _test_2 = _re.test(_split[2]);
		_url_pathname = _test_2 ? _split[1] : _split[2];
		_url_pathname = !_test_1 && !_test_2 ? _split.length === 2 ? _split[1] : _split[2] : _url_pathname;
	}
	return _url_pathname;
}

function getUserTimeZoneFormattedDate(date) {

	// set the default date to one hour in the future
	var _date = moment(date || Date.now()).add(5, 'm');

	var time_cookie = Cookies.get('time');
	var user_time_settings = JSON.parse(time_cookie.slice(2)) || {};

	var formatted_date = moment.tz(_date, user_time_settings.time_zone);
	// console.log('sending back:' + formatted_date);

	return formatted_date;
};


function loadApprovalLog(message_id, from) {
	loadDynamicContent('/message/approval_log', 'approvalLogModal', { message_id : message_id, from : from });
}

// -----------------------------------------------
// ------------------------------------- Upload Base64 Image
// -----------------------------------------------

function uploadBase64Image(img_data, callback) {
	// Object
	var _data = {
		data : img_data.dataURL,
		name : img_data.file.name,
		type : img_data.file.type,
		size : img_data.file.size
	}
	// Upload
	$.ajax({
		type: "POST",
		url: '/upload/base64',
		data: _data,
		statusCode: {
			// Error
			500: function(data) {
				// Show notification
				notyWrapper({
					text: 'There was an error adding your file. Please try again or upload the traditional way by selecting the file from your file system',
					type: 'error'
				});
				return callback && callback(data);
			},
			// Done
			200: function(data) {
				return callback && callback(data);
			}
		}
	});
}

// -----------------------------------------------
// ------------------------------------- Company User
// -----------------------------------------------

function markCompanyUserAsSafe(id) {
	// Remove Div
	$('#company_user_is_suspicious_div').remove();
	// Uncheck is_suspicious
	$('#form_company_user #is_suspicious').attr('checked', false);
	$('#form_company_user #is_hidden').attr('checked', false);
	// Mark record
	$.ajax({
		type: "GET",
		url: '/settings/customers/mark_safe/' + id
	});
}

// -----------------------------------------------
// ------------------------------------- TIME ZONE
// -----------------------------------------------

//- User dismisses the dialog
function timeZoneDismiss() {
	//- Set temp session to not bother anymore
	Cookies.set('check_time_zone', false, _globalCookieOptions(7));
	//- Refresh page
	location.reload();
}
//- User dismisses the dialog
function timeZoneSet() {
	//- Get hidden value
	var _tz = $('#timezone_hidden_tz').val();
	//- Save setting
	$.ajax({
		type: "POST",
		url: '/check_time_zone/set',
		data : { time_zone : _tz },
		statusCode: {
			200: function() {
				//- Refresh page
				location.reload();
			}
		}
	});
}



// -----------------------------------------------
// ------------------------------------- SOME OTHER SECTION
// -----------------------------------------------


// switch (document.readyState) {
// 	case "loading":
// 		// The document is still loading.
// 		console.log("Page loading");
// 		break;
// 	case "interactive":
// 		// The document has finished loading. We can now access the DOM elements.
// 		console.log('DOM loaded!');
// 		break;
// 	case "complete":
// 		// The page is fully loaded.
// 		console.log("Page is loaded completely");
// 		break;
// }

// function downloadJSAtOnload() {
// 	console.log("downloadJSAtOnload");
// }
// if (window.addEventListener)
// 	window.addEventListener("load", downloadJSAtOnload, false);
// else if (window.attachEvent)
// 	window.attachEvent("onload", downloadJSAtOnload);
// else window.onload = downloadJSAtOnload;

///////////////////////////////////
// NEW
///////////////////////////////////

var _todo_default_lists = ['incoming','upcoming','assigned_to_me','assigned_to_others','private','emails','archive'];

function createToDo(options) {
	// Options
	options = options || {};
	// if main we user slider
	// if (options.main) {
		// Reset form
		$('#createToDoMainDiv #todo_form').trigger('reset');
		$('#todo_assignee_0').val(_getUserCookie()).trigger('change');
		$('#todo_notify_users_0').val(null).trigger('change');
		$('#todo_notify_company_users_0').val(null).trigger('change');
		$('#todo_associate_company_users_0').val(null).trigger('change');
		$('#todo_assignee_select_0').removeClass('hidden');
		$('#todo_notify_users_select_0').removeClass('hidden');
		$('#todo_notify_company_users_select_0').removeClass('hidden');
		$('#todo_associate_company_users_select_0').removeClass('hidden');
		toDoListUpdateSelect(_getHostIdCookie(), '#globalToDoModal', '0')
		toDoAddAssociateUser('#globalToDoModal');
		toggleSlideDiv('#createToDoMainDiv');
		var _cid = $('#todo_category_id_tmp').val()
		if (_cid === 'private') {
			$('#todo_private_0').prop('checked', true);
			todoPrivateClick(0);
		}
		setTimeout(function() {
			// if list is private
			$('#createToDoMainDiv #todo_title_0').focus();
			$('#todo_category_0').val( _cid ).trigger('change');
		}, 1000)
	// }
}

// Open ToDo in new browser window
function openToDoWindow() {
	// Close the ToDos
	$('#globalToDoModal').modal('hide');
	// Call new window
	var _left = Number((screen.width / 2) - (1250 / 2));
	var _height = Number((screen.height / 2) - (800 / 2));
	window.open('/tasks', '_blank', "height=800,width=1250,resizable,scrollbars,status,left=" + _left + ',top=' + _height);
}

function openToDoFromShortcut() {
	$('#globalToDoModal').modal({
		backdrop: 'static',
		// keyboard: false,
		show: true
	});
	globalToDo(null, null, null);
}
function newToDoFromShortcut() {
	// If we are in the modal window
	if ( $('#globalToDoModal').hasClass('show') ) {
		createToDo();
	}
	else {
		globalToDoAdd();
	}
}

function closeToDoWindow() {
	// Check window
	var _window = $('#todo_window').val() === 'true' ? true : false;
	if (_window) {
		return window.close();
	}
	$('#globalToDoModal').modal('toggle');
}

function globalToDo(emailchain, todo_id, cat_id) {
	// Load editor
	// _loadJsAndCss(['editor']);
	// Var
	emailchain = emailchain ? emailchain : null;
	todo_id = todo_id ? todo_id : null;
	cat_id = cat_id ? cat_id : null;
	// Set
	var _options = {};
	var _url = '/todo';
	var _event_view = '';
	// For emailchain
	if (emailchain) {
		// Grab subject
		var _subject = $('#edit_subject_header').html();
		$('#global_tmp').val(_subject);
		_url = '/todo?emailchain=' + emailchain;
		_event_view = 'emailchain';
	}
	if (todo_id) {
		_url = '/todo?todo_id=' + todo_id;
		_event_view = 'todo_id';
	}
	if (cat_id) {
		_url = '/todos?category=' + cat_id;
		_event_view = 'todo_category';
	}

	// Capture
	var _capture_text = `todo_view_${_event_view}`;
	captureEvent({ action: _capture_text });

	// Get cached categories
	// var _hostid = _getHostIdCookie();
	// lfGetItem('todos_lists_' + _hostid).then(lists => {
		// _options.lists = lists ? lists : null;
		// Load window
		loadDynamicContent(_url, 'globalToDoModal', {

		});
		// Making sure we have counts and if not this will load them
		loadToDoCategoriesAndCounts();
		// Modal
		var _modal = new bootstrap.Modal(document.getElementById('globalToDoModal'), { backdrop:'static', keyboard:false });
		_modal.show();

		setTimeout(() => {
			var _window = $('#todo_window').val() === 'true' ? true : false;
			if (_window) {
				$('.todo-open-window-link').addClass('hidden');
			}
		}, 1000);

	// })
}

function globalToDoAdd(modal_id, record_id, type) {
	// console.log("modal_id", modal_id);
	// console.log("record_id", record_id);
	// console.log("type", type);
	modal_id = modal_id || null;
	record_id = record_id || null;
	type = type || null;
	var emailchain_subject = null;
	// Load editor
	// _loadJsAndCss(['editor']);
	// Customer record
	var _cu = $(`#${record_id}`).data('cu');
	// return
	if (_cu) {
		addCuToHiddenField( JSON.stringify( _cu ) );
	}
	// Get cached categories
	// var _hostid = _getHostIdCookie();
	// lfGetItem('todos_lists_' + _hostid).then(lists => {
		// if type is email we store subject
		if (modal_id === 'globalMailMessageModal') {
			// Grab subject
			emailchain_subject = $(`#edit_subject_header_${record_id}`).html() || $(`#edit_subject_header_${record_id}`).val();
		}
		else if (type === 'email') {
			emailchain_subject = $(`.mail_subject_${record_id}`).html();
		}
		// Add id to hidden field
		if (type === 'email') {
			$('#global_todo_emailchain').val(record_id);
		}
		// Load window
		loadDynamicContent('/todoglobaladd', 'globalToDoAddModal', {
			// lists: lists ? lists : null,
			emailchain_id: type === 'email' || modal_id === 'globalMailMessageModal' ? record_id : null,
			emailchain_subject
		});
		// Modal
		var _modal = new bootstrap.Modal(document.getElementById('globalToDoAddModal'), { backdrop:'static', keyboard:false });
		_modal.show();
		// if we have a modal open already
		if (modal_id) {
			// Set it
			$('#global_modal_open').val(modal_id);
			if (record_id) $('#global_modal_open_record_id').val(record_id);
			// Toggle it
			// var _modalOpen = $('#' + modal_id);
			// _modalOpen.toggle();
		}
	// });
	// There are also events on app_document for this modal !!!
}

// Open from anywhere
function openToDo(todo_id) {
	globalToDo(null, todo_id);
}

// Open category from anywhere
function openToDoCategory(cat_id) {
	// Set cookie
	Cookies.set('todo_category', cat_id);
	globalToDo(null, null, null);
}

// Remove emailchain
function toDoEmailchainRemove() {
	$('#todo_emailchain_0').remove();
	$('#todo_emailchain_subject_0').remove();
	$('#globalToDoAddModal #todo_associate_company_users_0').val(null).trigger('change');
}

function toDoAddAssociateUser(modal_id) {
	var _cu = $('#global_cu_obj').val();
	// console.log("🚀 - file: app_todo.js:195 - toDoAddAssociateUser - _cu:", _cu)
	_cu = _cu ? JSON.parse(_cu) : null;
	if (!_cu) return;
	// Check if there is a value already. If so abort
	var _s2v = $(modal_id + ' #todo_associate_company_users_0').find(':selected');
	if ( _s2v.length ) return;
	var option = new Option(_cu.full_name, _cu._id, true, true);
	$(modal_id + ' #todo_associate_company_users_0').append(option).trigger('change');
}


function todoPrivateClick(id) {
	//- Get value of checkbox
	var _checked = $('#todo_private_' + id).is(':checked');
	//- Depending on checked hide or show assignee
	if (_checked) {
		$('#todo_assignee_' + id).val(null).trigger('change');
		$('#todo_notify_users_' + id).val(null).trigger('change');
		$('#todo_notify_company_users_' + id).val(null).trigger('change');
		$('#todo_assignee_select_' + id).addClass('hidden');
		$('#todo_notify_users_select_' + id).addClass('hidden');
		$('#todo_notify_company_users_select_' + id).addClass('hidden');
		$('#todo_associate_company_users_col_' + id).removeClass('col-md-6').addClass('col-12');
	}
	else {
		$('#todo_assignee_select_' + id).removeClass('hidden');
		$('#todo_notify_users_select_' + id).removeClass('hidden');
		$('#todo_notify_company_users_select_' + id).removeClass('hidden');
		$('#todo_associate_company_users_col_' + id).removeClass('col-12').addClass('col-md-6');
	}
}

// Reschedule actions
// function toDoRescheduleActions(id) {
// 	// console.log("id", id);
// 	// Change color of div
// 	$('.todo_schedule_div').addClass('todo-schedule-actions');
// 	// Add buttons to schedule
// 	$('.todo_schedule_div').html('<div class="d-flex justify-content-center mt-n3 text-center"><div class="px-3 todo-schedule-action" onclick="toDoRescheduleDo(\'' + id + '\', \'today\')"><img class="img-fluid" src="/images/calendar-899778.png" style="width:40px;height:40px;"><div class="mt-1 fs-8">Today</div></div><div class="px-3 todo-schedule-action" onclick="toDoRescheduleDo(\'' + id + '\', \'tomorrow\')"><img class="img-fluid" src="/images/24-hours.png" style="width:40px;height:40px;"><div class="mt-1 fs-8">Tomorrow</div></div><div class="px-3 todo-schedule-action" onclick="toDoRescheduleDo(\'' + id + '\', \'2days\')"><img class="img-fluid" src="/images/clock-repeat.png" style="width:40px;height:40px;"><div class="mt-1 fs-8">In 2 days</div></div><div class="px-3 todo-schedule-action" onclick="toDoRescheduleDo(\'' + id + '\', \'nextweek\')"><img class="img-fluid" src="/images/week-899777.png" style="width:40px;height:40px;"><div class="mt-1 fs-8">Next week</div></div><div class="px-3 todo-schedule-action" onclick="toDoRescheduleDo(\'' + id + '\', \'nextmonth\')"><img class="img-fluid" src="/images/last-date-899764.png" style="width:40px;height:40px;"><div class="mt-1 fs-8">Next month</div></div><div class="px-3 todo-schedule-action" onclick="toDoRescheduleDo(\'' + id + '\', \'remove\')"><img class="img-fluid" src="/images/busy-day-899771.png" style="width:40px;height:40px;"><div class="mt-1 fs-8">No date</div></div><div class="px-3 todo-schedule-action" onclick="toDoRescheduleDo(\'' + id + '\', \'custom\')"><img class="img-fluid" src="/images/calendar-899768.png" style="width:40px;height:40px;"><div class="mt-1 fs-8">Custom date</div></div><div class="px-3 todo-schedule-action" onclick="toDoRescheduleDo(\'' + id + '\', \'cancel\')"><img class="img-fluid" src="/images/power-button.png" style="width:40px;height:40px;"><div class="mt-1 fs-8">Cancel</div></div></div>');
// }

function todoRescheduleTo(unit) {
	var _date;
	// For custom
	if (unit === 'custom') {
		_date = $('#todo_reschedule_custom_date').val();
	}
	$.ajax({
		type: "POST",
		url: '/todo/reschedule',
		data: { unit : unit, custom_date : _date },
		statusCode: {
			// Error
			500: function(data) {
				console.log('ERROR todos reschedule');
			},
			// Done
			200: function(data) {
				$('#todos_list_div').load('/todos?category=upcoming');
			}
		}
	});
}

// function toDoRescheduleDoCustom(id) {
// 	// Get Date
// 	var _date = $('.todo-schedule-date #todo_schedule_date').val();
// 	// Submit
// 	$.ajax({
// 		type: "POST",
// 		url: '/todo/reschedule',
// 		data: { id : id, unit : 'custom', custom_date : _date },
// 		statusCode: {
// 			// Error
// 			500: function(data) {
// 				console.log('ERROR todos reschedule custom date');
// 			},
// 			// Done
// 			200: function(data) {
// 				$('#todos_list_div').load('/todos?category=upcoming');
// 			}
// 		}
// 	});
// }


// Add ToDo
function newToDo(id, div) {
	// console.log("id", id);
	// console.log("div", div);

	//- Make sure we have a title
	var _title = $(div + ' #todo_title_' + id).val();

	if (_title === '') {
		return false;
	}

	// Remove lf
	lfRemoveItem(`todos_count_${_getHostIdCookie()}_${_getUserCookie()}`);

	//- Grab all fields with class
	var _fields = {};

	_fields.todo_id = id;

	_fields.title = _title;

	_fields.todo_description = $(div + ' #todo_description_' + id).val();

	_fields.todo_type = $(div + ' #todo_type_' + id).val();

	// Emailchain
	_fields.todo_emailchain = $('#global_todo_emailchain').val() !== 'undefined' ? $('#global_todo_emailchain').val() : null;
	// _fields.todo_emailchain = !_fields.todo_emailchain && $(div + ' #todo_emailchain_0').val() !== 'undefined' ? $(div + ' #todo_emailchain_0').val() : _fields.todo_emailchain;

	// Add date
	var _due_date = $(div + ' #todo_due_date_' + id).val();
	_fields.todo_due_date = _due_date ? moment(_due_date).format('YYYY-MM-DD 08:00') : null;

	// Assignee
	_fields.todo_assignee = $(div + ' #todo_assignee_' + id + ' option:selected').val();

	// Notify users
	var _notify_users = [];
	$(div + ' #todo_notify_users_' + id).find(':selected').each(function(i,v) {
		_notify_users.push( v.value );
	});
	_fields.todo_notify_users = _notify_users;

	// Notify company users
	var _notify_company_users = [];
	$(div + ' #todo_notify_company_users_' + id).find(':selected').each(function(i,v) {
		_notify_company_users.push( v.value );
	});
	_fields.todo_notify_company_users = _notify_company_users;

	// Associate company users
	var _associate_company_users = [];
	$(div + ' #todo_associate_company_users_' + id).find(':selected').each(function(i,v) {
		_associate_company_users.push( v.value );
	});
	_fields.todo_associate_company_users = _associate_company_users;

	var _category = [];
	$(div + ' #todo_category_' + id).find(':selected').each(function(i,v) {
		_category.push( v.value );
	});
	_fields.todo_categories = _category;

	// Private
	_fields.todo_private = $(div + ' #todo_private_' + id).is(':checked');
	// console.log("_fields", _fields);

	var _url = id === '0' ? '/todo/new' : '/todo/update';

	//- Submit
	$.ajax({
		type: "POST",
		url: _url,
		data: _fields,
		statusCode: {
			// Error
			500: function(data) {
				console.log('ERROR adding todo', data);
			},
			// Done
			200: function(data) {
				// console.log("data", data);
				// Noty
				notyWrapper({
					text: id === '0' ? i18n.__('todo_notification_new') : i18n.__('todo_notification_updated'),
					type: 'success',
					timeout: 3000
				});
				// For mail
				mailLoadToDos();
				// If from global
				if (div === '#globalToDoAddModal') {
					$('#globalToDoAddModal').modal('hide');
					var _open_modal = $('#global_modal_open').val() || null;
					// If modal is contacts we refresh the record
					if ( _open_modal === 'contactsModal' ) {
						var _id = $('#global_modal_open_record_id').val();
						// Reload
						$('#contacts_todo_overview').load(`/todos/contacts/ajax?kind=active&id=${_id}&limit=3` );
						$('#contacts_todo_active').load(`/todos/contacts/ajax?kind=active&id=${_id}` );
					}

					// If we have an open modal before restore it
					// var _open_modal = $('#global_modal_open').val() || null;
					// if (_open_modal) {
					// 	// If modal is contacts we refresh the record
					// 	if ( _open_modal === 'contactsModal' ) {
					// 		var _id = $('#global_modal_open_record_id').val();
					// 		// globalOpenModal(_open_modal, `/contacts/edit/${_id}?admin=true`);
					// 		// Reload
					// 		$('#contacts_todo_overview').load(`/todos/contacts/ajax?kind=active&id=${_id}&limit=3` );
					// 		$('#contacts_todo_active').load(`/todos/contacts/ajax?kind=active&id=${_id}` );
					// 	}
					// 	// Toggle it
					// 	var _modalOpen = $('#' + _open_modal);
					// 	_modalOpen.toggle();
					// }
					// return false;
				}
				// Close form div
				$('#createToDoMainDiv').css('display','none');
				// If we have an emailchain
				if (_fields.todo_emailchain) {
					getToDosOfEmail(_fields.todo_emailchain);
				}
				// New
				else if ( id === '0' ) {
					$('#todos_list_div').load('/todos?category=' + Cookies.get('todo_category'));
					// loadDynamicContent('/todo', 'globalToDoModal');
				}
				else {
					// Or simply reload this page
					$('#todos_list_div').load('/todo/' + id);
					// Hide edit and show header again
					// toDoEditDetails(id, false);
					// Need to add what has changed here

				}
			}
		}
	});

	return false;

}

function toDoEditDetails(id, show) {
	// Open edit view
	toggleSlideDiv('#editToDoMainDiv');
	$('#editToDoMainDiv #todo_title_' + id).focus();
	toDoListUpdateSelect(_getHostIdCookie(), '#editToDoMainDiv', id);
	// Hide headers
	if (show) {
		$('#todo_detail_header_' + id).addClass('hidden');
		$('#todo_detail_top_' + id).addClass('hidden');
		//- Hide bottom of todos
		$('.todo_bottom').addClass('hidden');
	}
	else {
		$('#todo_detail_header_' + id).removeClass('hidden');
		$('#todo_detail_top_' + id).removeClass('hidden');
		$('.todo_bottom').removeClass('hidden');
	}
}



// This marks it as complete and moves it in the list down and waits 7 seconds
function markToDoCompleteTemp(id) {
	// Show header
	$('#todo_done_div').removeClass('hidden animate__animated animate__fadeIn').append('<div class="card shadow-sm mb-3 bg-light-info" id="todo_list_done_' + id + '"><div class="card-body text-center"><span>' + i18n.__('todo_marked_desc') + '</span><br><span><a class="ps-1" href="#" onclick="toDoMarkCompleteUndo(\'' + id + '\')">' + i18n.__('you_can_undo') + '</a></span><span class="ps-1">' + i18n.__('within_seven_seconds') + ' ' + i18n.__('or') + '<a class="ps-1" href="#" onclick="toDoMarkCompleteNow(\'' + id + '\')">' + i18n.__('complete_now') + '</a></span></div></div>');
	// Mark to do as checked
	$('#todo_done_' + id).prop('checked', true)
	$('#todo_right_flex_' + id).addClass('animate__animated animate__fadeOut').on('animationend', function() {
		$('#todo_right_flex_' + id).addClass('hidden').removeClass('animate__animated animate__fadeOut');
	});
	// Show reset option
	$.doTimeout('todo_done_queue_' + id, 7000, function() {
		// Remove question div
		$('#todo_list_done_' + id).addClass('animate__animated animate__fadeOut').on('animationend', function() {
			$('#todo_list_done_' + id).removeClass('animate__animated animate__fadeOut').remove();
		});
		// Complete
		markToDoComplete(id, true);
	})

}

function toDoMarkCompleteUndo(id) {
	// Abort queue
	$.doTimeout('todo_done_queue_' + id);
	// Uncheck checkbox
	$('#todo_done_' + id).prop('checked', false)
	// Remove question div
	$('#todo_list_done_' + id).addClass('animate__animated animate__fadeOut').on('animationend', function() {
		$('#todo_list_done_' + id).removeClass('animate__animated animate__fadeOut').remove();
	});
	// Show ToDo again
	$('#todo_right_flex_' + id).removeClass('hidden');
}

function toDoMarkCompleteNow(id) {
	// Abort queue
	$.doTimeout('todo_done_queue_' + id);
	// Remove question div
	$('#todo_list_done_' + id).addClass('animate__animated animate__fadeOut').on('animationend', function() {
		$('#todo_list_done_' + id).removeClass('animate__animated animate__fadeOut').remove();
	});
	// Complete
	markToDoComplete(id, true);
}


// Check complete from detail
function toDoMarkCompleteInDetail(id) {
	// Check if checked
	var _checked = $('#todo_done_detail_' + id).is(':checked');
	// Checked
	if (_checked) {
		// Call to submit
		markToDoComplete(id, _checked);
		// Noty
		notyWrapper({
			text: i18n.__('todo_mark_completed_detail'),
			type: 'success'
		});
	}
	else {
		toDoUnarchive(id)
	}
}

// Mark as complete (from slider)
function markToDoComplete(id, completed) {
	// Remove lf
	lfRemoveItem(`todos_count_${_getHostIdCookie()}_${_getUserCookie()}`);
	// Ajax that todo is done
	$.ajax({
		type: "POST",
		url: '/todo/done',
		data: { todo_id: id, completed : completed },
		statusCode: {
			// Error
			500: function(data) {

			},
			// Done
			200: function(data) {

			}
		}
	});
}

function toDoUnarchive(id, is_list) {
	is_list = is_list || false;
	// Unmark
	$(`#todo_done_${id}`).prop('checked', false);
	// Noty
	notyWrapper({
		text: i18n.__('todo_reopen_text'),
		type: 'success'
	});
	// Unmark
	markToDoComplete(id, false);
	// If list
	if (is_list) {
		$(`#todo_right_flex_${id}`).addClass('animate__animated animate__fadeOut').on('animationend', function() {
			$(this).remove();
		});
	}
}

function toDoChangeLog(id) {
	$('#todos_list_div').load('/todo/log/' + id);
}

function toDoGoToDetail(id) {
	$('#todos_list_div').load('/todo/' + id);
}

function getToDosOfEmail(id) {
	// Get todos
	$('#todos_list_div').load('/todos/emailchain/' + id);
	// Get subject
	if ( ! $('#global_tmp').val() ) {
		var _subject = $('#todo_email_subject_' + id).html();
		$('#global_tmp').val(_subject);
	}
	// Get todos count for emailchain
	setToDoBadgeEmailchain(id);
}

function setToDoBadgeEmailchain(id) {
	setTimeout(function() {
		$.ajax({
			type: "GET",
			url: '/todos/emailchain/count/' + id,
			statusCode: {
				// Error
				500: function(data) {
					console.log('ERROR todos count for emailchain');
				},
				// Done
				200: function(data) {
					if (data !== '0') {
						$('#todos_count_' + id).removeClass('hidden').html(data);
					}
					else {
						$('#todos_count_' + id).addClass('hidden');
					}
					if ( $('#tabmail_details_todos').length ) {
						$('#tabmail_details_todos').load(`/mail/message/todos/${id}`);
					}
				}
			}
		});
	}, 800);
}

// -----------------------------------------------
// TO DO CATEGORIES
// -----------------------------------------------

function toDoAddCategory(div) {
	// Parent div
	var _parent = div + ' '
	var _id = $(_parent + '#todo_category_id').val();
	var _name = $(_parent + '#todo_category_main').val();
	var _color = $(_parent + '#todo_category_color').val();
	// If name is empty
	if (!_name) return false;
	var _hostid = _getHostIdCookie();
	$.ajax({
		type: "POST",
		url: '/todo/update/category',
		data: { id: _id, category : _name, color : _color },
		statusCode: {
			// Error
			500: function(data) {

			},
			// Done
			200: function(data) {
				if (_id) {
					// Changes on page
					$('#todo_category_header_' + _id).html( _name );
					$('#todo_category_text_' + _id).html( _name );
					$('#todo_category_' + _id).css('border-left', '5px solid ' + _color);
					// Close div
					toggleSlideDiv('#editToDoListDiv');
				}
				else {
					//- Set category to selected one
					$('.todo .list-group-item').removeClass('font-weight-800');
					// Close div
					toggleSlideDiv('#createToDoListDiv');
					// Clear input
					$(_parent + '#todo_category_main').val('');
					// $(_parent + '#todo_category_color').spectrum('set', 'rgb(186, 191, 191)');
					//- Set cookie category
					Cookies.set('todo_category', data, _globalCookieOptions(365));
					// Load right side
					$('#todos_list_div').load('/todos?category=' + data);
				}
			}
		}
	});

}

function toDoGoToCategory(cat_id) {
	$('#todos_list_div').load('/todos?category=' + cat_id);
	_closeAllDropdown();
}

function toDoSetCategory(category, category_name, category_color) {
	//- Set cookie category
	Cookies.set('todo_category', category, _globalCookieOptions(365));
	Cookies.set('todo_category_name', category_name, _globalCookieOptions(365));
	if (category_color) Cookies.set('todo_category_color', category_color, _globalCookieOptions(365));
	//- Set category to selected one
	$('.todo .list-group-item').removeClass('font-weight-800');
	$('#todo_category_' + category).addClass('font-weight-800');
	//- Add to hidden field
	$('#todo_category_id_tmp').val(category);
	// Set header
	$('#todo_category_header').html(category_name);
	// Depending on category we enable editing
	if ( !_todo_default_lists.includes(category) ) {
		$('#todo_category_edit_dd').removeClass('hidden');
	}
}

function toToSlideListDiv() {
	toggleSlideDiv('#createToDoListDiv');
	$('#createToDoListDiv #todo_category_main').focus();
}
function toToSlideListEditDiv() {
	toggleSlideDiv('#editToDoListDiv');
	$('#editToDoListDiv #todo_category_main').focus();
	toDoListUpdateSelect(_getHostIdCookie(), $('#todo_modal_id').val(), $('#todo_id').val())
}

// Update select2 with updated lists
function toDoListUpdateSelect(host_id, modal_id, todo_id) {
	// $script.ready(['vendors_pre'], function() {
		// Get cache
		lfGetItem('todos_lists_' + host_id).then(lists => {
			// Only if not edit
			if ( modal_id !== '#editToDoMainDiv' ) {
				$(modal_id + ' #todo_category_' + todo_id + ' option').each(function(i, v) {
					if ( $(this).val() !== '-1' ) $(this).remove();
				});
			}

			var _lists = $('#todo_lists_' + todo_id).val() || '[]';
			try {
				_lists = JSON.parse(_lists);
			}
			catch(e) {}

			// Update select2 with update list
			var _data = lists.categories.map(function (l) {
				var _found = _lists.find(e => e._id === l._id);
				var _selected = _found ? true : false;
				return { 'id': l._id, 'text': l.category, selected: _selected };
			})

			$(modal_id + ' #todo_category_' + todo_id).select2({
				data: _data,
				width: '100%',
				dropdownParent: $(modal_id),
				placeholder: {
					id: '-1',
					text: i18n.__('todo_category')
				}
			})
		});
	// })
}

// -----------------------------------------------
// TO DO GROUPS
// -----------------------------------------------

function toDoAddGroup(id) {
	var _name = $('#todo_group_name_' + id).val();
	var _color = $('#todo_group_color_' + id).val();
	// If name is empty
	if (!_name) return false;
	// Category
	var _cat_id = Cookies.get('todo_category');
	// Submit
	$.ajax({
		type: "POST",
		url: '/todo/update/group',
		data: { id: id, name : _name, color : _color, category_id : _cat_id },
		statusCode: {
			// Error
			500: function(data) {

			},
			// Done
			200: function(data) {
				// Just refresh list
				$('#todos_list_div').load('/todos?category=' + _cat_id);
			}
		}
	})
}

// -----------------------------------------------
// COMMENTS
// -----------------------------------------------

// Add Email
function newToDoComment(comment_id, todo_id) {

	var _editor = new FroalaEditor('#todo_comment_edit');

	//- Make sure we have something
	// var _message = $('#todo_comment_edit').val();
	var _message = _editor.html.get('');;
	// console.log("🚀 - file: app_todo.js:764 - newToDoComment - _message:", _message)

	if (_message === '') {
		return false;
	}

	// Do we have a hidden edit id?
	var _edit_id = $('#todo_comment_edit_id').val();

	comment_id = _edit_id ? _edit_id : comment_id;

	// Submit
	$.ajax({
		type: "POST",
		url: '/todo/update/comment',
		data: { todo_id : todo_id, comment_id : comment_id, comment : _message },
		statusCode: {
			// Error
			500: function(data) {
				console.log('ERROR adding todo comment', data);
			},
			// Done
			200: function(data) {
				//- Show comments
				$('#todo_detail_comments').removeClass('hidden');
				// Clear edit_id
				$('#todo_comment_edit_id').val('');
				// Clear textarea
				try {
					_editor.html.set('');
				} catch(e) {}
				// Get user name
				var _user = Cookies.get('first_last_name');
				// HTML
				var _html = `<div class="card card-flush mb-10 no-shadow" id="todo_comment_${data}">
					<input type="hidden" id="todo_comment_text_${data}" value="">
					<div class="card-body p-7">
						<div class="mb-6 todo_comment_text">${_message}</div>
						<div class="todo_comment_actions d-flex align-items-center">
							<div class="text-muted fs-9">${_user} - ${i18n.__('just_now')}</div>
							<div class="ms-auto">
								<a class="btn btn-sm btn-light-info" href="#" onclick="editToDoComment('${data}')">${i18n.__('edit')}</a>
								<a class="ms-2 btn btn-sm btn-light-danger" href="#" onclick="deleteRecord('todo_comment', '${todo_id}-${data}')">${i18n.__('delete')}</a>
							</div>
						</div>
					</div>
				</div>`;


				// Append comment
				if ( comment_id === '0' ) {
					$('#todo_detail_comments').prepend(_html);
				}
				else {
					$('#todo_comment_' + data).replaceWith(_html);
				}
				// Fix links
				fixLinks('#globalToDoModal .todo_comment_text');
				//- Show action buttons
				$('.todo_comment_actions').removeClass('hidden');
				//- Hide cancel button
				// $('#todo_comment_edit_cancel').addClass('hidden');
				setTimeout(function() {
					$(`#todo_comment_text_${data}`).val(_message);
				}, 1000);
			}
		}
	});

	return false;

}


// -----------------------------------------------
// TO DO UTILS
// -----------------------------------------------

function toDoGoBackToList(emailchain) {
	// For emailchain
	if (emailchain && emailchain !== 'undefined') {
		//- Load todos of emailchain
		getToDosOfEmail(emailchain);
		// $('#todos_list_div').load('/todos/emailchain/' + _emailchain);
	}
	else {
		//- Get cookie
		var _c = Cookies.get('todo_category');
		var _cat = _c ? _c : 'incoming';
		// Load todos
		$('#todos_list_div').load('/todos?category=' + _cat);
	}
}

// Add company user object to global field
function addCuToHiddenField(cu) {
	$('#global_cu_obj').val( cu );
}





// Reregister plugin
function deRegisterPlugin() {
	// Grab the id
	var theid = getModalId();
	// Call route to remove record on success close the window and refresh list
	$.ajax({
		type: "POST",
		url: '/settings/addons/uninstall',
		data: {
			id: theid
		},
		success: function(data) {
			window.location.href = '/settings/addons';
		}
	});
}

function installPlugin(plugin_name) {
	$.ajax({
		type: "POST",
		url: '/settings/addons/install',
		data: {
			plugin_name: plugin_name
		},
		success: function(data) {
			window.location.href = '/settings/addons';
		}
	});
}
// Mixed Button modal
function mixedButtonsModal(what_to_load) {
	// Get request info and check for groups plugin
	var _request_info = getGlobalRequestInfo();
	var _groups = _request_info.plugin_name && _request_info.plugin_name === 'groups' ? true : false;
	// Get type
	var type = _groups ? 'groups' : _getGlobalHiddenType();
	// Hide buttons
	_hideMixedButtons(type);
	// Modal name
	var _modal_name = 'mixedModal';
	// Write model name into global var as select2 needs it in the dropdownparent params
	$('#global_modal_name').val(_modal_name);
	// Set as we pull values from cookies
	var _cookie_values = _groups ? { 'mailbox' : null, 'assignee' : null, 'status' : _request_info.params.status || 'inbox' } : _dashboardGetCookies(type);
	var _status = _cookie_values.status;
	var _mailboxes = type === 'search' ? '' : _cookie_values.mailbox;
	var _label_id = _request_info.label_id || null;
	// var _assignee = _cookie_values.assignee;
	// Wait for global selection div
	waitForValue('#global_selection_div', function() {
		// Get selection
		var post_data = getMixedSelectedConversations();
		post_data.mailboxes = _mailboxes;
		post_data.what_to_load = what_to_load;
		post_data.status = _status;
		post_data.listing_status = _status;
		post_data.label_id = _label_id;
		// Load modal
		loadDynamicContent('/mixed_modal', _modal_name, post_data);
		// Text for modal
		var _text_s = post_data.selected.length === 1 ? '' : 's';
		var _text;
		switch (what_to_load) {
			case 'assignees':
				_text = i18n.__('assign_selected_%s', _text_s);
				break;
			case 'labels':
				_text = i18n.__('label_selected_%s', _text_s);
				break;
			case 'label_folder':
				_text = i18n.__('move_selected_%s', _text_s);
				break;
		}
		// Add title to modal
		setModalTitle(_modal_name, _text);
	});
	// Add close event for this modal to uncheck all emails
	if (!_groups && type !== 'search') {
		$('#' + _modal_name).on('hide.bs.modal', function(e) {
			// Uncheck
			_uncheckMixedSelectedConversations();
		});
	}
}

// Mixed Button modal - NEW
function mixedButtonsModalNew(what_to_load, section_id) {
	// Get request info and check for groups plugin
	var _request_info = getGlobalRequestInfo();
	var _groups = _request_info.plugin_name && _request_info.plugin_name === 'groups' ? true : false;
	// Get type
	var type = _groups ? 'groups' : _getGlobalHiddenType();
	// Hide buttons
	// _hideMixedButtons(type);
	// Modal name
	var _modal_name = 'mixedModal';
	// Write model name into global var as select2 needs it in the dropdownparent params
	$('#global_modal_name').val(_modal_name);
	// Set as we pull values from cookies
	var _cookie_values = _groups ? { 'mailbox' : null, 'assignee' : null, 'status' : _request_info.params.status || 'inbox' } : _dashboardGetCookies(type);
	var _status = _cookie_values.status;
	var _mailboxes = type === 'search' ? '' : _cookie_values.mailbox;
	var _label_id = _request_info.label_id || null;
	// var _assignee = _cookie_values.assignee;
	// Wait for global selection div
	// waitForValue('#global_selection_div', function() {
		// Get selection

		var post_data = {};
		post_data.selected = _getSectionMailsSelected(section_id);
		post_data.mailboxes = _mailboxes;
		post_data.what_to_load = what_to_load;
		post_data.status = _status;
		post_data.listing_status = _status;
		post_data.label_id = _label_id;
		post_data.section_id = section_id;
		// Store section id
		$('#section_id_selected').val( section_id );
		// Load modal
		loadDynamicContent('/mail/get_mb_labels_modal', _modal_name, post_data);
		// Text for modal
		var _text_s = post_data.selected.length === 1 ? '' : 's';
		var _text;
		switch (what_to_load) {
			case 'assignees':
				_text = i18n.__('assign_selected_%s', _text_s);
				break;
			case 'labels':
				_text = i18n.__('label_selected_%s', _text_s);
				break;
			case 'label_folder':
				_text = i18n.__('move_selected_%s', _text_s);
				break;
		}
		var mixedModal = new bootstrap.Modal(document.getElementById(_modal_name));
		mixedModal.show();
		// Add title to modal
		setModalTitle(_modal_name, _text);
	// });
}

// Mixed Modal Assign Conversation
function mixedModalAssignConversation(mb_id, the_user_id) {
	// Modal name
	var _modal_name = $('#global_modal_name').val();
	// Get selection
	var _user_id = $('#mailbox_panels_for_assignees_' + mb_id + ' option:selected').val();
	// If selection is -1 or 0
	if (_user_id === '-1' || _user_id === '0') return false;
	// Get name of user
	var _name = $('#assignee_selection_' + mb_id + '_' + _user_id).val();
	// Add user to div
	// $('#assignee_selection_' + mb_id).removeClass('hidden');
	// $('#assignee_selection_name_' + mb_id).html(_name);
	// $('#assignee_selection_trash_' + mb_id).attr('onclick', 'mixedModalRemoveAssignee("' + mb_id + '");');
	// If this is for the user himself
	_name = _user_id === the_user_id ? 'him/herself' : _name;
	// Show alert for waiting
	var _alert_text = i18n.__('execution_revert_seconds_%s_%s', '7', "mixedModalRemoveAssignee('" + mb_id + "');return false;") + ' ' + i18n.__('or') + ' <a href="#" onclick="mixedModalAssignConversationNow(\'' + mb_id + '\', \'' + _user_id + '\', \'' + _name + '\')" style="text-decoration:underline;">' + i18n.__('assign_now') + '</a>';
	$('#assignee_selection_revert_' + mb_id).removeClass('hidden');
	$('#assignee_selection_revert_alert_' + mb_id).html(_alert_text);
	// Disable selection
	$('#mailbox_panels_for_assignees_' + mb_id).prop('disabled', true);
	// Queue execution
	$.doTimeout('assignee_selection_queue_' + mb_id, 7000, function() {
		// Set assignee
		setAssignee(_user_id, _name, _isSearch(), false, true);
		// When executed remove from view
		$('#assignee_selection_body_' + mb_id).fadeOut("slow", function() {
			_name = _name === 'him/herself' ? i18n.__("yourself_lowercase") : _name;
			var _text = _name === 'noone' ? i18n.__("selected_have_been") + ' ' + i18n.__("unassigned_lowercase") : i18n.__("selected_have_been") + ' ' + i18n.__("assigned_to") + ' ' + _name;
			$(this).fadeIn("fast").html('<div class="font-center">' + _text + '</div>');
		});
		// Uncheck
		_uncheckAllMessages();
	});
}

// Will cancel the assignee timeout and reset everything
function mixedModalRemoveAssignee(mb_id) {
	// Cancel execution
	$.doTimeout('assignee_selection_queue_' + mb_id);
	// Disable selection
	$('#mailbox_panels_for_assignees_' + mb_id).prop('disabled', false);
	// Remove alert and text
	$('#assignee_selection_revert_' + mb_id).addClass('hidden');
	$('#assignee_selection_revert_alert_' + mb_id).html('');
	// $('#assignee_selection_' + mb_id).addClass('hidden');
	// $('#assignee_selection_name_' + mb_id).html('');
}

// Will execute assignee immediately
function mixedModalAssignConversationNow(mb_id, user_id, user_name) {
	// Cancel execution
	$.doTimeout('assignee_selection_queue_' + mb_id);
	// Disable selection
	$('#mailbox_panels_for_assignees_' + mb_id).prop('disabled', false);
	// Remove alert and text
	$('#assignee_selection_revert_' + mb_id).addClass('hidden');
	$('#assignee_selection_revert_alert_' + mb_id).html('');
	// Set assignee
	setAssignee(user_id, user_name, _isSearch(), false, true);
	// When executed remove from view
	$('#assignee_selection_body_' + mb_id).fadeOut("slow", function() {
		user_name = user_name === 'him/herself' ? i18n.__("yourself_lowercase") : user_name;
		var _text = user_name === 'noone' ? i18n.__("selected_have_been") + ' ' + i18n.__("unassigned_lowercase") : i18n.__("selected_have_been") + ' ' + i18n.__("assigned_to") + ' ' + user_name;
		$(this).fadeIn("fast").html('<div class="font-center">' + _text + '</div>');
	});
	// Uncheck all messages
	_uncheckAllMessages();
}

// Mixed Modal LabeL Folder
function mixedModalLabelFolder(mb_id, is_status) {
	// is status
	is_status = typeof is_status === 'undefined' ? '' : is_status;
	// Modal name
	var _modal_name = $('#global_modal_name').val();
	// Selected name
	var _label_name = $('#labels_mixed_' + mb_id + ' option:selected').html();
	// Show alert for waiting
	var _alert_text = i18n.__('execution_label_folder_revert_seconds_%s_%s', '7', "mixedModalLabelFolderCancel('" + mb_id + "');return false;") + ' ' + i18n.__('or') + ' <a href="#" onclick="mixedModalLabelFolderNow(\'' + mb_id + '\', \'' + is_status + '\')" style="text-decoration:underline;">' + i18n.__('execution_label_folder_now') + '</a>';
	$('#label_folder_selection_revert_' + mb_id).removeClass('hidden');
	$('#label_folder_selection_revert_alert_' + mb_id).html(_alert_text);
	// Disable selection
	$('#labels_mixed_' + mb_id).prop('disabled', true);
	// Queue execution
	$.doTimeout('label_folder_selection_queue_' + mb_id, 7000, function() {
		// Set label folder
		if (is_status) {
			setStatus(is_status, _isSearch(), false, true, true);
		}
		else {
			// Get selection
			var _label_id = $('#labels_mixed_' + mb_id + ' option:selected').val();
			// If selection is -1 or 0
			if (_label_id === '-1' || _label_id === '0') return false;
			addLabelFromTree(_label_id, _label_name, null, JSON.stringify({ is_mixed : true, mailbox_id : mb_id, mtf : true }) );
		}
		// When executed remove from view
		$('#label_folder_selection_body_' + mb_id).fadeOut("slow", function() {
			$(this).fadeIn("fast").html('<div class="font-center">' + i18n.__('label_folder_moved') + '</div>');
		});
		// Uncheck
		_uncheckAllMessages();
	});
}

// Will cancel the label folder timeout and reset everything
function mixedModalLabelFolderCancel(mb_id) {
	// Cancel execution
	$.doTimeout('label_folder_selection_queue_' + mb_id);
	// Disable selection
	$('#labels_mixed_' + mb_id).prop('disabled', false);
	// Remove alert and text
	$('#label_folder_selection_revert_' + mb_id).addClass('hidden');
	$('#label_folder_selection_revert_alert_' + mb_id).html('');
}

// Will execute label folder immediately
function mixedModalLabelFolderNow(mb_id, is_status) {
	// is status
	is_status = typeof is_status === 'undefined' ? '' : is_status;
	// Label
	var _label_id = $('#labels_mixed_' + mb_id + ' option:selected').val();
	var _label_name = $('#labels_mixed_' + mb_id + ' option:selected').html();
	// Cancel execution
	$.doTimeout('label_folder_selection_queue_' + mb_id);
	// Disable selection
	$('#labels_mixed_' + mb_id).prop('disabled', false);
	// Remove alert and text
	$('#label_folder_selection_revert_' + mb_id).addClass('hidden');
	$('#label_folder_selection_revert_alert_' + mb_id).html('');
	// Set label folder
	if (is_status) {
		setStatus(is_status, _isSearch(), false, true, true);
	}
	else {
		addLabelFromTree(_label_id, _label_name, null, JSON.stringify({ is_mixed : true, mailbox_id : mb_id, mtf : true }) );
	}
	// When executed remove from view
	$('#label_folder_selection_body_' + mb_id).fadeOut("slow", function() {
		$(this).fadeIn("fast").html('<div class="font-center">' + i18n.__('label_folder_moved') + '</div>');
	});
	// Uncheck all messages
	_uncheckAllMessages();
}



// -----------------------------------------------
// ------------------------------------- Subfunctions for emails from different mailboxes
// -----------------------------------------------

function getMixedSelectedConversations() {
	// ID array
	var _id_array = [];
	// Get status
	var _status = _getGlobalHiddenStatus();
	// Get div
	var _div = _getGlobalSelectionDiv();
	// Return
	var _ret = {
		selected: '',
		status: _status,
		listing_status: _status,
		is_mixed: true,
		type: _getGlobalHiddenType(),
		count: 0
	};
	// If all is selected
	if (_select_all) {
		_ret.selected = 'all';
		_ret.messageid = 'all';
		return _ret;
	}
	// Get all the IDs of the checked conversations
	$('#' + _div + ' #table-rows input[type=checkbox]:checked').each(function() {
		_id_array.push($(this).val());
	});
	// Return
	_ret.selected = _id_array;
	_ret.messageid = _id_array;
	_ret.count = _id_array.length;
	_ret.request_info = getGlobalRequestInfo();
	return _ret;
}

function _uncheckMixedSelectedConversations() {
	// Set select all
	_select_all = false;
	// Get div
	var _div = _getGlobalSelectionDiv();
	// Get all the IDs of the checked conversations
	$('#' + _div + ' input[type=checkbox]').prop('checked', false);
	// Reset select all link
	_showSelectAllLink(_getGlobalHiddenType());
}

// Set reminder from specific dialog
// function setReminderSpecific() {
// 	// Get is_detail value
// 	var is_detail = $('#form_reminder #is_detail').val();
// 	// Get is_mixed value
// 	var is_mixed = $('#form_reminder #is_mixed').val();
// 	// Since above is a string covert to boolean
// 	is_detail = is_detail === 'true' ? true : false;
// 	is_mixed = is_mixed === 'true' ? true : false;
// 	// Get date value
// 	var reminder_date = $('#form_reminder #reminder_date').val();

// 	// Close window
// 	$('#setReminderTime').modal('hide');

// 	// Call function below to set reminder
// 	setReminder('some_other_time', reminder_date, false, is_detail, is_mixed);
// }

// // Set reminder from messagelist
// function setReminder(delay_type, delay_amount, is_search, is_detail, is_mixed) {

// 	// is_Mixed means the records can be from different mailboxes
// 	is_mixed = is_mixed || is_mixed === 'true' ? true : false;

// 	// Reminder notification text
// 	var _reminder_text = is_mixed ? i18n.__('reminder_set_selected') : i18n.__('reminder_set');

// 	// If is_mixed
// 	if (is_mixed) {
// 		// Get what is selected
// 		var post_data = getMixedSelectedConversations();
// 		post_data.delay_type = delay_type;
// 		post_data.delay_amount = delay_amount;
// 	} else {
// 		var post_data = getBulkActionPostDataArray();
// 		post_data.push({
// 			'name': 'delay_type',
// 			'value': delay_type
// 		});
// 		post_data.push({
// 			'name': 'delay_amount',
// 			'value': delay_amount
// 		});

// 		var _mailbox_id = $('#mailbox_id').val() || '';
// 		post_data.push({
// 			'name': 'mailbox_id',
// 			'value': _mailbox_id
// 		});
// 	}

// 	// Now add to db
// 	$.ajax({
// 		'type': "POST",
// 		'url': '/message/setreminder',
// 		'data': post_data,
// 		success: function(data) {
// 			// Delete cache for counts
// 			// deleteFolderCountsLc();
// 			// For search
// 			if (is_search) {
// 				handlePostSearchAction('set_reminder_suceess');
// 			}
// 			// Set Notification
// 			if (!is_detail || is_search) {
// 				notyWrapper({
// 					text: _reminder_text,
// 					type: 'success'
// 				});
// 			}
// 			// Show reminder icon
// 			if (is_mixed || is_search) {
// 				// Get the proper ids
// 				var _message_ids = post_data.messageid;
// 				// Loop over ids
// 				$.each(_message_ids, function(i, v) {
// 					$('#reminder_icon_' + v).removeClass('hidden');
// 					// Set notifications
// 					// markNotificationForEmailChainRead(v, true);
// 				})
// 			}

// 			// Only show if detail view
// 			if (is_detail) {
// 				// console.log("TCL: setReminder -> data.reminder_date", data.reminder_date)

// 				// format the reminder date to the user's tz
// 				var time_cookie = Cookies.get('time');
// 				var user_time_settings = JSON.parse(time_cookie.slice(2)) || {};
// 				var time_zone = user_time_settings.time_zone;
// 				var time_format = user_time_settings.time_format === '24' ? 'H:mm' : 'h:mm a';

// 				// Convert to current date
// 				// var _this_date = moment(data.reminder_date);
// 				// var _this_date_tz = delay_type === 'some_other_time' ? new Date(delay_amount) : moment.tz(data.reminder_date, time_zone);
// 				var _this_date_tz = moment.tz(data.reminder_date, time_zone);

// 				var date_to_display = moment(_this_date_tz).calendar(null, {
// 					sameDay: '[Today] @ ' + time_format,
// 					nextDay: '[Tomorrow] @ ' + time_format,
// 					nextWeek: 'dddd @ ' + time_format,
// 					lastDay: '[Yesterday] @ ' + time_format,
// 					lastWeek: '[Last] dddd @ ' + time_format,
// 					sameElse: 'DD/MM/YYYY @ ' + time_format
// 				});

// 				showReminderDiv(data.reminder_id, date_to_display, data.message_id);
// 				// Set notifications
// 				// markNotificationForEmailChainRead(data.message_id);
// 				// showReminderDiv(data.reminder_id, data.reminder_date, data.message_id);
// 			}

// 			// Action Navigation
// 			_nextActionNavigation(is_detail, 'reminder');

// 			// Toogle the dropdown
// 			if (delay_type !== 'some_other_time' && !is_mixed) {
// 				$('#reminder_dropdown').dropdown('toggle');
// 			}

// 			// If mixed hide mixed buttons
// 			if (is_mixed) {
// 				_hideMixedButtons(_getGlobalHiddenType());
// 				_uncheckMixedSelectedConversations();
// 			}

// 			// Deselect all messages
// 			if (!is_detail && !is_mixed) {
// 				_uncheckAllMessages();
// 			}

// 			return false;
// 		}
// 	});
// }

// //- Called from setReminder to show div
// function showReminderDiv(reminder_id, reminder_date, message_id) {
// 	// FORMAT HERE!!!!!!!!!!!!!!!
// 	$('#reminder_div').append('<div id="' + reminder_id + '"><div class="reminder"><div class="d-flex flex-nowrap"><div class="pr-3"><img class="img-fluid" src="/images/alarm-clock.svg" style="width:60px"></div><div class="mr-auto"><span>' + i18n.__("reminder_set_at") + ' <strong>' + reminder_date + '</strong></span><div class="checkbox mt-3"></div><label class="paddingtop6"><span class="mr-2">Do not remove this reminder when someone replies</span><input type="checkbox" data-id="' + reminder_id + '" autocomplete="off" class="reminder-remove-on-reply toggle color-primary is-material has-animation"></label></div><a href="#" onclick="removeReminder(\'' + reminder_id + '\',\'' + message_id + '\');return false;"><span class="fal fa-trash fa-1-2"></span></a></div></div></div><div class="pt-3"></div>');
// }


// Set reminder custom
// function setReminderCustom(is_detail, is_mixed) {
// 	// is_Mixed means the records can be from different mailboxes
// 	is_mixed = is_mixed ? true : false;
// 	// Hide loading gif
// 	$('#setReminderTime-statusloading').addClass('hide');
// 	// Load modal
// 	loadDynamicContent('/message/reminder/' + is_detail, 'setReminderTime', {
// 		is_mixed: is_mixed
// 	});
// 	// Toggle dropdown
// 	$('#reminder_dropdown').dropdown('toggle');
// }

// //- Remove Reminder
// function removeReminder(reminderid, messageid) {
// 	$.ajax({
// 		type: "POST",
// 		url: '/message/removereminder',
// 		data: {
// 			reminderid: reminderid,
// 			messageid: messageid
// 		},
// 		statusCode: {
// 			200: function(data) {
// 				$("#" + reminderid).fadeOut("slow");
// 				var _mailbox_id = $('#mailbox_id').val() || '';
// 				// Hide reminder icon
// 				// $('#reminder_icon_' + messageid).addClass('hidden');
// 			}
// 		}
// 	});
// }

// Delete draft from editor
// function deleteDraftFromEditor(id, autoload, cb) {
// 	// Hide the draft div
// 	$('#draftdiv').addClass('hidden');
// 	// Close editor
// 	$('#divreplyandnote').toggle();
// 	$('#divreplyandnotespace').toggle();
// 	// Get values
// 	var _mailbox_id = _getMailboxId();
// 	var _pn = getGlobalPluginName();
// 	var _pid = getGlobalPluginId();
// 	var _status = $('#message_status').val();
// 	var _emailchain_id = $('#emailchain_id').val();
// 	// Call route to remove draft
// 	$.ajax({
// 		type: "POST",
// 		url: '/editor/draft_remove',
// 		data: {
// 			id: id, mailbox_id : _mailbox_id
// 		},
// 		statusCode: {
// 			200: function(data) {
// 				// Delete cache for counts
// 				// deleteFolderCountsLc();
// 				// reloadDraftRightSide(_mailbox_id);
// 				return cb && cb();
// 			}
// 		}
// 	});
// 	// if (autoload) {
// 	// 	document.location.href = _pn ? pluginGoToURL(_pn, _pid, _status) + '/' + _emailchain_id : '/mailbox/' + _mailbox_id + '/' + _status + '/' + _emailchain_id;
// 	// }

// 	// Only if editor has been loaded
// 	if (editor) {
// 		// Get sig
// 		var _sig = $('#thesig').val();
// 		// Set editor
// 		editor.html.set(_sig);
// 	}

// }

function removeSelectedDrafts() {

	// Param
	var draft_ids = 0;
	// Get selected ones
	draft_ids = getSelectedMessagesWithType(_type);
	// send to delete. route will redirect
	$.ajax({
		type: "POST",
		url: '/editor/draft_remove_selected',
		data: {
			draft_ids: draft_ids
		},
		statusCode: {
			200: function(data) {
				// Remove row
				if (draft_ids === 'all') {
					$('#drafts_' + _type).remove();
				}
				else {
					$.each(draft_ids, function(i, v) {
						$('.table-drafts-' + _type + ' #' + v).remove();
					});
				}
				// Check how many rows we have
				var _how_many_users = $('.table-drafts-user tr').length;
				var _how_many_shared = $('.table-drafts-shared tr').length;
				// Hide card
				if (!_how_many_users) {
					$('#drafts_user').remove();
				}
				if (!_how_many_shared) {
					$('#drafts_shared').remove();
				}
				// Close modal
				$('#removeSelectedDraft').modal('hide');
				// Redirect
				if (!_how_many_users && !_how_many_shared) {
					setTimeout(function() {
						document.location.href = _pn ? pluginGoToURL(_pn, _pid, 'drafts') : '/mailbox/' + data.mailboxid + '/drafts';
					}, 500);
				}
			}
		}
	});
}

// Open edit message in modal
function editMessageInModal(draft_id, draft_mailbox_id) {
	// Vars
	var _pn = getGlobalPluginName();
	var _pid = getGlobalPluginId();
	var _request_info = getGlobalRequestInfo();
	// Load edit window
	loadDynamicContent('/message/edit/modal', 'editMessageModal', {
		'pn': _pn,
		'pid': _pid,
		'request_info': _request_info,
		'emailchain_id': draft_id,
		'mailbox_id': draft_mailbox_id
	});
	// Show modal
	$('#editMessageModal').modal('show');
}
// Check which mailboxes are enabled for this user and show/hide divs
function checkSelectedMailboxes(setting) {
	// Var
	var _mbs_checked = [];
	// Get mailboxes from hidden field
	var _mbs = JSON.parse($('#session_mailboxes').val());
	// Loop over each mb and see if checked
	$.each(_mbs, function(i, mb) {
		// Hide divs
		// For notifications
		if (setting === 'user_notifications') {
			$('#notifications_mailbox_' + mb._id).addClass('hidden');
		}
		if (setting === 'user_email_preferences') {
			$('#signature_mailbox_' + mb._id).addClass('hidden');
			$('#sig_mb_' + mb._id).removeClass('in');
		}
		// Check if checked
		var _checked = $('#mbid-' + mb._id + ':checked').val();
		if (_checked) {
			_mbs_checked.push(_checked);
		}
	});

	// We got all the checked mbs now. Continue...

	// For notifications
	if (setting === 'user_notifications') {
		// Show the ones on the array
		$.each(_mbs_checked, function(i, id) {
			$('#notifications_mailbox_' + id).removeClass('hidden');
		});
	}
	// For user prefs
	if (setting === 'user_email_preferences') {
		// Show the ones on the array
		$.each(_mbs_checked, function(i, id) {
			$('#signature_mailbox_' + id).removeClass('hidden');
			$('#sig_mb_' + id).addClass('in');
		});
	}

}

// Remove Mailbox
function removeMailbox() {
	// Grab the id
	var mb_id = getModalId();
	// Call route to remove record on success close the window and refresh list
	$.ajax({
		type: "POST",
		url: '/mailbox/remove',
		data: {
			'id': mb_id
		},
		success: function(data) {
			try {
				location.href = '/settings/mailboxes';
			} catch (e) {
				/* no need to do anything */
				// console.log(e);
			}
		}
	});
}

function removeUserFromMailbox(user_id) {
	// remove user from table
	var table_row = $('#user_row_' + user_id);
	table_row.css("background-color", "#FFFFCCC");
	table_row.fadeOut(300, function() {
		table_row.remove();
	});

	// find the option of that user
	var option = $('#all_users option[value="' + user_id + '"]');
}

// capture the change event to selecting a user in the add to the user mailbox table
//$('#all_users').on('change', function() {
function addUserToMailbox(user_id, user_name) {
	// If placeholder
	if (user_id === '-1') return false;
	// Show user div
	$('.mailbox_new_users').removeClass('hidden');
	// create the html representing the new table row
	var table_row_open = "<tr id='user_row_" + user_id + "' name='user_row_" + user_id + "'>";
	var hidden_input = "<input type='hidden' name='added_user' value='" + user_id + "'></input>";
	//input(type='hidden', name='added_user', value=user.id)
	var td = "<td>" + user_name + "</td><td class='text-right'>";
	var a_link_open = "<a href='#' onclick=\"removeUserFromMailbox(\'" + user_id + "\');\">";
	var span = `<span class='${_fa_style}trash fa-fw'></span>`;
	var all_close = "</a></td></tr>";

	// add the new row to the table
	$('#mailbox_users tbody').append(table_row_open + hidden_input + td + a_link_open + span + all_close);

	// disable the user in the select
	// var option = $('#all_users option[value="' + user_id + '"]');

	// Reset
	$('#all_users').val('-1').trigger('change');
}

// Saved replies and workflow show copy buttons
function showMailboxCopyButtons() {
	// How many checkboxes are selected
	var howmany = $('#check_checkboxes input[type=checkbox]:checked').length;
	// If 0 then hide else show
	if (!howmany) {
		$('#saved_replies_on_select').addClass('hidden');
	} else {
		$('#saved_replies_on_select').removeClass('hidden');
	}
}

// Copy saved replies category to mailbox
function copySavedRepliesCategoryToMailbox(id) {
	// Get mailbox id
	var _mailbox_id = $('.copy-to-mailbox-' + id + ' option:selected').val();
	// if empty do nothing
	if (_mailbox_id === '') return false;
	// Check if saved replies also need to be copied
	var _copy_saved_replies = $('#copy-to-mailbox-saved-replies-' + id).is(':checked');
	// Else submit
	$.ajax({
		type: "POST",
		url: '/copy/saved_replies_category',
		data: {
			mailbox_id: _mailbox_id,
			copy_saved_replies: _copy_saved_replies,
			category: id
		},
		statusCode: {
			200: function() {
				$('#copy_status').removeClass('hidden alert-danger').addClass('alert-success').html(i18n.__('status_copy_to_mailbox_category_success'));
			},
			500: function() {
				$('#copy_status').removeClass('hidden alert-success').addClass('alert-danger').html(i18n.__('status_copy_to_mailbox_category_error'));
			}
		}
	});
}

// Copy saved replies to mailbox
function copySavedRepliesToMailbox() {
	// Get mailbox id
	var _mailbox_id = $('.copy-to-mailbox option:selected').val();
	// if empty do nothing
	if (_mailbox_id === '') return false;
	// Set array
	var _selected = [];
	// Grab the selected saved replies
	$('#check_checkboxes input[type=checkbox]:checked').each(function() {
		_selected.push($(this).val());
	});
	var _saved_replies = JSON.stringify(_selected);
	// Else submit
	$.ajax({
		type: "POST",
		url: '/copy/saved_replies',
		data: {
			mailbox_id: _mailbox_id,
			saved_replies_ids: _saved_replies
		},
		statusCode: {
			200: function() {
				$('#copy_status').removeClass('hidden alert-danger').addClass('alert-success').html(i18n.__('status_copy_to_mailbox_success'));
			},
			500: function() {
				$('#copy_status').removeClass('hidden alert-success').addClass('alert-danger').html(i18n.__('status_copy_to_mailbox_error'));
			}
		}
	});
}

// Copy workflows to mailbox
function copyWorkflowsToMailbox() {
	// Get mailbox id
	var _mailbox_ids = $('#copy-to-mailbox').select2('data');
	_mailbox_ids = _mailbox_ids.map(m => m._id);
	// if empty do nothing
	if (!_mailbox_ids.length) return false;
	// console.log('_mailbox_ids: ', _mailbox_ids, _mailbox_ids.length)
	// Set array
	var _selected = [];
	// Grab the selected saved replies
	$('#check_checkboxes input[type=checkbox]:checked').each(function() {
		_selected.push($(this).val());
	});
	// console.log('_selected: ', _selected)
	// var _workflows = JSON.stringify(_selected);
	// Else submit
	$.ajax({
		type: "POST",
		url: '/copy/workflows',
		data: {
			mailbox_ids: _mailbox_ids,
			workflow_ids: _selected
		},
		statusCode: {
			200: function() {
				$('#copy_status').removeClass('hidden alert-danger').addClass('alert-success').html(i18n.__('status_copy_to_mailbox_success'));
			},
			500: function() {
				$('#copy_status').removeClass('hidden alert-success').addClass('alert-danger').html(i18n.__('status_copy_to_mailbox_error'));
			}
		}
	});
}

// Populate user drop down within summary
function populateUserDropdown(status) {
	//- Plugins
	var _pn = getGlobalPluginName();
	var _pid = getGlobalPluginId();
	var _mailbox_id = $('#mailbox_id').val();

	var _cookie_append = _pid ? _pid : _mailbox_id;

	// Name for cookies and elements (legacy)
	var _status_name = status === 'assigned' ? 'assignee' : status;

	// Show element
	$('#navbar_select_' + _status_name + '_filter_el').removeClass('hidden');

	var summ_users = $('#summary_data_hidden').val();
	//- ensure the page still renders if no value's set for summary_data_hidden
	var _users = summ_users && summ_users !== 'undefined' ? JSON.parse(summ_users) : [];
	var _c_selected = Cookies.get('email_list_' + _status_name + '_' + _cookie_append);
	var _user_selected = _c_selected ? JSON.parse(_c_selected).assignee : null;
	var _c_gid = _getWorkgroupCookie();
	var _group_id = _pn && _pn === 'groups' ? _c_gid : null;

	//- Get width of browser
	var _browser_width = $(window).width(); // returns width of browser viewport

	var _users_select = [];

	_users_select.push({
		id : '-1',
		text : ''
	});

	$.each(_users, function(u) {
		var _count = parseInt(_users[u].count) > 100 ? '99+' : _users[u].count;
		_users_select.push({
			id : _users[u].assignee_id,
			text : _users[u].assignee_display + ' (' + _count + ')',
			selected : _users[u].assignee_id === _user_selected ? true : false
		})
	})

	_users_select.push({
		id : '-',
		text : '---------------------'
	});

	_users_select.push({
		id : 0,
		text : i18n.__('show_all_users')
	});

	//- For groups we remove the group user
	if ( _group_id ) {
		_users_select = _users_select.filter(function(u) { return u.id.toString() !== _group_id.toString() });
	}

	//- Load select2 stuff
	$('#navbar_select_' + _status_name + '_filter').select2({
		width : _browser_width >= 768 ? '160px' : undefined,
		data : _users_select,
		placeholder : {
			id : '-1',
			text : status === 'assigned' ? i18n.__('filter_by_assignee') : i18n.__('filter_by_user')
		}
	});

	$('#navbar_select_' + _status_name + '_filter').on('select2:select', function(evt) {
		//- Search
		var _id = evt.params.data.id;
		var _text = evt.params.data.text;
		var _mid = $('#mailbox_id').val();
		//- Call subfunction
		if (status === 'assigned') {
			redirectAssigneeSelection(_id, _text, _mid);
		}
		else {
			redirectPendingSelection(_id, _text, _mid);
		}
	});
}




















function redirectAssigneeSelection(id, text, mailbox_id) {
	// Plugin
	var _pn = getGlobalPluginName();
	var _pid = getGlobalPluginId();
	var _cookie_append = _pid ? _pid : mailbox_id;
	//- Value is -
	if (id === '-') return false;
	//- Value is 0 reset cookie
	if (id === '0' || !id) {
		Cookies.remove('email_list_assignee_' + _cookie_append);
	} else {
		Cookies.set('email_list_assignee_' + _cookie_append, _globalCookieOptions(14, {
			"assignee": id,
			"user_name": text
		}) );
	}
	//- Load page again
	document.location.href = _pn ? pluginGoToURL(_pn, _pid, 'assigned') : '/mailbox/' + mailbox_id + '/assigned';
}

//- Reset Assignee in reply dropdown if inbox is choosen
function resetAssigneeToAnyone(kind, is_global) {
	is_global = is_global || false;
	// Type
	var _wrapper = kind === 'note' ? '.compose_wrapper_note' : '.compose_wrapper';
	_wrapper = is_global ? '_global' : _wrapper;
	_wrapper = kind === 'forward' ? '' : _wrapper;
	_wrapper = kind === 'collision' ? '.mail-collision' : _wrapper;
	//- Get value of status
	var _status = $(_wrapper + ' #status_new option:selected').val();
	var _assignee = $(_wrapper + ' #assigned_to_reply option:selected').val();
	// If assignee status is already noone abort here or else we get endless recursions
	if (_assignee === 'noone') return false;
	//- Only on inbox
	if (_status === 'inbox') {
		$(_wrapper + ' #assigned_to_reply option:last').prop('selected', 'selected').trigger('change.select2');
	}
}

function resetAssigneeInReply(kind, is_global) {
	is_global = is_global || false;
	// Type
	var _wrapper = kind === 'note' ? '.compose_wrapper_note' : '.compose_wrapper';
	_wrapper = is_global ? `${_wrapper}_global` : _wrapper;
	_wrapper = kind === 'forward' ? '' : _wrapper;
	_wrapper = kind === 'collision' ? '' : _wrapper;
	_wrapper = kind === 'collision' ? '.mail-collision' : _wrapper;
	//- Assignee value
	var _assignee = $(_wrapper + ' #assigned_to_reply option:selected').val();
	//- Not when assignee is 'noone'
	if (_assignee === 'noone') {
		$(_wrapper + ' #status_new_inbox').prop('selected', 'selected').trigger('change.select2');
	}
	else {
		// Check the status
		var _sel_status = $(_wrapper + ' #status_new').val();
		// if status is inbox change to assigned
		if (_sel_status === 'inbox') {
			$(_wrapper + ' #status_new_assigned').prop('selected', 'selected').trigger('change.select2');
		}
	}
}



function syncTestConnection() {
	// Alert
	$('#sync_form_status').addClass('hidden');
	$('#sync_test_status').addClass('hidden');
	$('#sync_test_alert_status').removeClass('alert-success alert-danger').html('');
	// Change button
	$('#sync_test').attr('disabled', true).html(`<span class="${_fa_style}sync-alt fa-spin fa-1x"></span>`);
	// Hide submit button
	$('#sync_submit_button').addClass('hidden');
	//- Check that all forms are here
	var _values = formSerializeParam('.form-serialize');
	var _empty = formEmptyValues(_values);

	//- If any of the form values are empty
	if (_empty) {
		$('#sync_form_status').removeClass('hidden');
		// Change button
		$('#sync_test').attr('disabled', false).html(i18n.__('sync_mail_server_test_button'));
		return false;
	}

	// Get type
	var _type = $('input[name="sync_provider"]:checked').val();

	// Tag on to values
	_values += '&provider=' + _type;

	// If all good
	$.ajax({
		type: "POST",
		url: '/mailbox/settings_sync_test_connection',
		data: _values,
		statusCode: {
			// Error
			500: function(data) {
				$('#sync_test_status').removeClass('hidden');
				$('#sync_test_alert_status').removeClass('alert-success').addClass('alert-danger').html(data.responseText);
				// Change button
				$('#sync_test').attr('disabled', false).html(i18n.__('sync_mail_server_test_button'));
			},
			// Done
			200: function(data) {
				$('#sync_test_status').removeClass('hidden');
				$('#sync_test_alert_status').removeClass('alert-danger').addClass('alert-success').html('We could successfully connect to your mail server. Please select the folder to store outgoing messages below.');
				$('#sync_test_status').delay(5000).fadeOut('slow', function() {
					$(this).addClass(('hidden'))
				});
				// Change button
				$('#sync_test').attr('disabled', false).html(i18n.__('sync_mail_server_test_button'));
				// Show folders
				syncGetFolders(data);
			}
		}
	});

}

function syncGetFolders(data) {
	// console.log("data", data);
	// Show folder selection
	$('#sync_folders_div').removeClass('hidden');
	// Switch inputs (in case this is an edit)
	$('#sync_folder_input').addClass('hidden');
	$('#sync_folder_select').removeClass('hidden');
	// Do a select2
	$('#sync_folder').select2({
		data: data,
		placeholder: {
			id: '-1', // the value of the option
			text: 'Select a folder'
		},
		allowClear: true
	});
}

function syncFolderSelection() {
	var _option = $('#sync_folder option:selected').val();
	// If option is -1 we hide the submit button
	if (_option === '-1') {
		$('#sync_submit_button').addClass('hidden');
		return false;
	}
	// Show submit button
	$('#sync_submit_button').removeClass('hidden');
}
/////////////////////////////////////////
// Bookmark Functions
/////////////////////////////////////////

// Add record
function bookmarkAddRecord(id, type, options) {
	// Remove pulse classes
	$('#nav_bookmarks_link').removeClass('pulse pulse-info');
	$('#nav_bookmarks_ring').removeClass('pulse-ring border-3 animation-iteration-3 animation-duration-2').addClass('hidden');
	// Notification
	notyWrapper({
		text: i18n.__('bookmark_record_added'),
		type: 'success'
	});
	// Call route
	$.ajax({
		type: "POST",
		url: '/bookmark/add',
		data : { id, type, options },
		statusCode: {
			// Error
			500: function(data) {
				console.log('ERROR adding bookmark');
			},
			// Exists
			201: function(data) {
				console.log('Bookmark already created');
			},
			// Done
			200: function(data) {
				// Show pulse on bookmark icon
				$('#nav_bookmarks_link').addClass('pulse pulse-info');
				$('#nav_bookmarks_ring').removeClass('hidden').addClass('pulse-ring border-3 animation-iteration-3 animation-duration-2');
			}
		}
	});
	return false;
}

// Add record many
function bookmarkAddRecords(ids, type, options) {
	// Remove pulse classes
	$('#nav_bookmarks_link').removeClass('pulse pulse-info');
	$('#nav_bookmarks_ring').removeClass('pulse-ring border-3 animation-iteration-3 animation-duration-2').addClass('hidden');
	// Notification
	notyWrapper({
		text: i18n.__('bookmark_record_added'),
		type: 'success'
	});
	// Get mailbox_id of each record
	var _ids = ids;
	if (type === 'email') {
		_ids = [];
		$.each(ids, function(i, id) {
			_ids.push({ id, mailbox_id: $(`#${id}`).data('mailboxid') });
		})
	}
	// Call route
	$.ajax({
		type: "POST",
		url: '/bookmark/add/many',
		data : { ids: _ids, type, options },
		statusCode: {
			// Error
			500: function(data) {
				console.log('ERROR adding bookmark');
			},
			// Exists
			201: function(data) {
				console.log('Bookmark already created');
			},
			// Done
			200: function(data) {
				// Show pulse on bookmark icon
				$('#nav_bookmarks_link').addClass('pulse pulse-info');
				$('#nav_bookmarks_ring').removeClass('hidden').addClass('pulse-ring border-3 animation-iteration-3 animation-duration-2');
			}
		}
	});
	return false;
}

// Remove one record
function bookmarkRemoveRecord(id) {
	// Call route
	$.ajax({
		type: "GET",
		url: '/bookmark/remove/' + id,
		statusCode: {
			// Error
			500: function(data) {
				console.log('ERROR adding bookmark');
			},
			// Done
			200: function(data) {
				notyWrapper({
					text: i18n.__('bookmark_record_removed'),
					type: 'success'
				});
				// Refresh bookmarks
				bookmarksReload();
			}
		}
	});
	return false;
}

// Remove all record in section
function bookmarksRemoveRecords(type) {
	// Call route
	$.ajax({
		type: "GET",
		url: '/bookmarks/remove/all/' + type,
		statusCode: {
			// Error
			500: function(data) {
				console.log('ERROR adding bookmark');
			},
			// Done
			200: function(data) {
				notyWrapper({
					text: i18n.__('bookmarks_records_removed'),
					type: 'success'
				});
				// Refresh bookmarks
				bookmarksReload();
			}
		}
	});
	return false;
}

// Load bookmarks (just render)
function bookmarksReload() {
	$.ajax({
		type: "GET",
		url: '/bookmarks',
		statusCode: {
			// Error
			500: function(data) {
			},
			// Done
			200: function(data) {
				$('#bookmarks_slider').html(data);
			}
		}
	});
}
//- Get querystring
var _qs = getQueryString()
//- According to querystring
switch(_qs.g) {
	case 'messages':
		loadChatMessages(_qs.c);
		break
	default:
}

///////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////// FUNCTIONS CALLED FROM SOCKET EVENTS
///////////////////////////////////////////////////////////////////////////////


// Update chat counts
function engageUpdateBadge(widget) {
	// Show count in top navigation
	if (widget.count) {
		$('#nav_badge_engage').html(widget.count).addClass('animate__animated animate__heartBeat animate__delay-2s').removeClass('hidden');
		$('#engage_sidebar_conversations_active_count').html(widget.count);
	}
	else {
		$('#nav_badge_engage').addClass('hidden').removeClass('animate__animated animate__heartBeat animate__delay-2s');
		$('#engage_sidebar_conversations_active_count').html('');
	}
}
// Refresh on join request
function engageChatPageCheck() {
	// Are we on this page
	var _page = $('#engage_chat_id').length;
	var _page_chat_id = _page ? $('#engage_chat_id').val() : null;
	// Update chat list
	if ( _page && _page_chat_id === msg.chat_id ) {
		// Clear Noty
		Swal.close();
	}
}
// Chat detail page update
function engageChatDetailUpdate(msg, agent_id) {
	// Check if user is an agent
	var _userid = _getUserCookie();
	if ( _userid !== agent_id ) return false;
	// console.log("msg", msg);
	var _page = $('#engage_chat_id').length;
	var _page_chat_id = $('#engage_chat_id').val();
	if ( !_page || msg.chat_id !== _page_chat_id ) {
		//- Send Noty
		notyWrapper({
			id: msg.chat_id,
			text: `New chat update arrived. Click, to go to the conversation.`,
			type: 'success',
			timeout: 30000,
			go: '/chat?g=messages&c=' + msg.chat_id
		})
	}
	else {
		// Clear Noty
		Swal.close();
	}
}

// When customer closes chat
function engageChatUserClose(msg) {
	// Clear Noty
	Swal.close();
	// Refresh
	loadChatList(true);
}
// When customer closes local
function engageChatUserCloseGlobal(msg) {
	// Update chat list
	loadChatList(true);
	//- Only on the same page
	if ( $('#engage_chat_id').val() !== msg.chat_id ) return false;
	//- Var
	var _html = '';
	//- Status
	if (msg.body_type === 'status') {
		_html = _statusHtml(msg);
		_postMessageToAgent(_html);
	}
}
// Update agent chat
function engageUpdateAgentChat(msg) {
	//- Count update
	// engageGetChatCounts(true);
	//- Only on the same page
	if ( $('#engage_chat_id').val() !== msg.chat_id ) return false;
	// Show reply field
	$('#engage_chat_agent_message').removeClass('hidden');
	// Mark Active
	markChatActive(msg._id);
	//- Var
	var _html = '';
	//- Status
	if (msg.body_type === 'status') {
		_html = _statusHtml(msg);
		_postMessageToAgent(_html);
		// Clear Noty
		Swal.close();
	}
	else if (msg.body_type === 'message') {
		_html = _messageHtml(msg);
		_postMessageToAgent(_html);
	}
	// Scroll to bottom
	scrollToBottom();
}
// When customer types
function engageChatUserTyping(msg, chat_id) {
	// if msg empty
	if (msg === '') {
		$('#customer_typing_' + chat_id).addClass('hidden');
		return;
	}
	//- Show hidden section
	$('#customer_typing_' + chat_id).removeClass('hidden');
	// Add messages
	$('#customer_typing_' + chat_id + ' .message_only').html(msg + '...');
	// Mark Active
	markChatActive(chat_id);
}
// When customer rates
function engageChatUserRating(msg, rating) {
	// Update sidebar
	$('#engage_chat_detail_rating span').removeClass('text-danger text-primary text-success fw-bold');
	$('#engage_chat_detail_rating').removeClass('hidden');
	var _rating_css = 'text-danger';
	_rating_css = rating === 2 ? 'text-primary' : rating === 3 ? 'text-success' : _rating_css;
	$('#engage_chat_rating_' + rating).addClass(_rating_css + ' fw-bold');
	//- Only on the same page
	if ( $('#engage_chat_id').val() !== msg.chat_id ) return false;
	//- Var
	var _html = '';
	//- Status
	_html = _statusHtml(msg);
	_postMessageToAgent(_html);
	// Scroll to bottom
	scrollToBottom();
}
// When agent uploads a file
function engageAgentUploadFile(msg) {
	//- Only on the same page
	if ( $('#engage_chat_id').val() !== msg.chat_id ) return false;
	//- Var
	var _html = '';
	msg.for = 'agent';
	//- Status
	_html = _fileHtml(msg);
	_postMessageToAgent(_html);
	var _att = msg.attachment;
	var _url = _att.url;
	var _file = '<a href="' + _url + '" target="_blank">' + _att.file_name + '</a>'
	var _prepend = `<li class="list-group-item font-size-13" id="${_att.checksum}">${_file}</li>`
	// Update sidebar
	$('#engage_chat_detail_attachments').removeClass('hidden');
	// Prepend
	$('#engage_chat_detail_attachments ul').prepend(_prepend);
	// Scroll to bottom
	scrollToBottom();
}

///////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////// READY
///////////////////////////////////////////////////////////////////////////////

// $script.ready(['jquery'], function() {
	$(function () {

		// On agent reply
		$(document).on('submit', '#engage_chat_agent_message', function(e) {
			// Get message
			var _msg = $('#msg').val();
			// On empty
			if (_msg === '') {
				return false;
			}
			// Disable button
			$('#engage_agent_submit').prop('disabled', true);
			// Chat ID
			var _chat_id = $('#engage_chat_id').val();
			var _widget_id = $('#engage_chat_widget_id').val();
			// Submit
			$.ajax({
				method: "POST",
				url: '/chat/post_message_agent',
				data: { msg : _msg, chat_id : _chat_id, widget_id : _widget_id },
				statusCode: {
					200: function() {
						// Enable button
						$('#engage_agent_submit').prop('disabled', false);
						// Empty input
						$('#msg').val('');
						// Scroll content down
						scrollToBottom();
					},
					500: function() {
						console.log('Error post : ')
					}
				}
			})

			return false;
		})

		// Upload button
		// $(document).on('click', '.chat-upload-input', function(e) {
		// 	$('#chat_file_upload_input').triggerHandler('click');
		// })


		// When agent types
		$(document).on('input', '#engage_chat_agent_message', function(e) {
			// Clear Noty
			Swal.close();

			e.stopPropagation();
			// Is this a file upload
			var _file = $(e.target).parents().addBack().is("#chat_file_upload_input");
			// File upload
			if (_file) {
				var data = new FormData();
				data.append('file', $('#chat_file_upload_input')[0].files[0]);
				data.append('chat_id', $('#engage_chat_id').val());
				data.append('type', 'agent');
				$.ajax({
					type: "POST",
					url: '/chat/file_upload',
					data: data,
					processData: false,
					contentType: false,
					enctype: 'multipart/form-data',
					statusCode: {
						200: function() {
							scrollToBottom();
						},
						500: function() {
							console.log('upload error')
						}
					}
				})
				return false;
			}
			else {
				// Post to user chat
				$.ajax({
					type: "POST",
					url: '/chat/chat_typing',
					data: { 'chat_id' : $('#engage_chat_id').val(), 'message' : e.target.value, 'type' : 'agent' }
				})
			}
		})

		// Init a timeout variable to be used below
		var _engage_timeout_notes = null;

		// Saving notes while typing
		$(document).on('keyup', '#engage_chat_notes', function(e) {
			// Clear the timeout if it has already been set.
			// This will prevent the previous task from executing
			// if it has been less than <MILLISECONDS>
			clearTimeout(_engage_timeout_notes);
			// Grab values
			var _chat_id = $('#engage_chat_id').val();
			var _notes = $('#engage_chat_notes').val();
			// Post notes
			_engage_timeout_notes = setTimeout(function() {
				$.ajax({
					type: "POST",
					url: '/chat/details/update_notes',
					data: { 'chat_id' : _chat_id, 'notes' : _notes },
					statusCode: {
						200: function() {
							// Show success save
							$('#engage_chat_notes_save').removeClass('hidden').delay(3000).fadeTo('slow', 0, function() {
								$(this).addClass('hidden').css( "opacity", 1 );
							});
						}
					}
				})
			}, 2000)
		})

	})
// })

function markChatActive(chat_id) {
	$(`#chat_active_${chat_id}`).removeClass('hidden');
	$(`#${chat_id}`).addClass('bg-light-info');
}

function engageGetChatWidgetId() {
	return $('#engage_chat_widget_id').val();
}

function engageCheckStatus(chat_id) {
	var _status = engageGetChatStatus();
	if (_status !== 'unread') return;
	if ( localStorage.getItem('engage_chat_joined_' + chat_id) === 'true' ) return;
	engageChatJoinDiv();
}

function engageChatJoinDiv() {
	waitForElementNew('.main-panel-right-parent', function() {
		$('.join-chat-div').slideToggle( "fast" );
	}, 1000, 10000);
}

function _postMessageToAgent(html) {
	//- Hide typing of customer
	setTimeout(function() {
		$('#customer_typing_' + $('#engage_chat_id').val()).addClass('hidden');
	}, 500)
	$('#engage_chat_message_append').append( html );
	// Linkify messages
	$('.body_type_message').linkify({ className: 'text-underline' });
}

function engageCheckChatListForRecords() {
	var _div = engageGetActiveDiv();
	var _list = $('#' + _div + ' .chat-list .card').length;
	if ( !_list ) {
		$('#engage_no_chats_text').removeClass('hidden');
	}
}



function _messageHtml(msg) {
	var _actions = '';
	var _form = '';
	var _html = '';
	// Date
	var _date_full = moment();
	var _date = _date_full.tz( moment.tz.guess() ).format("h:mm A");
	// page
	var _page = msg.user_type === 'customer' ? `<br><span>on page: <a class="pl-1" href="${msg.page}" target="_blank">${msg.page}</a></span>` : '';
	var _receipt = msg.user_type === 'agent' ? `<span id="chat_receipt_${msg._id}" class="ps-2 chat_receipt">Not seen</span>` : '';
	// Actions
	if (msg.user_type === 'customer') {
		_actions = `<a href="#" onclick="engageEditMessage('${msg._id}', 'reply');return false;" class="text-muted text-hover-primary"><span class="${_fa_style}quote-right"></span></a>`;
	}
	else {
		_actions = `<a href="#" onclick="engageEditMessage('${msg._id}');return false;" class="text-muted text-hover-primary">
			<span class="${_fa_style}pen-to-square"></span>
		</a>
		<a href="#" onclick="engageDeleteMessage('${msg._id}');return false;" class="text-muted text-hover-primary ps-3">
			<span class="${_fa_style}trash"></span>
		</a>`;
	}
	_form = `<div id="inline_message_${msg._id}" style="display:none;">
		<div class="row">
			<div class="col-md-6 offset-md-3">
				<label id="message_edited_label_${msg._id}" class="fw-bold">Edit message</label>
				<textarea style="height:100px;" id="message_edited_${msg._id}" class="form-control w-100">${msg.body}</textarea>
				<div class="d-flex justify-content-end mt-1">
					<button type="button" onclick="engageEditMessage('${msg._id}')" class="btn btn-sm btn-light">Cancel</a>
					<button type="button" onclick="engageEditMessageSave('${msg._id}')" class="btn btn-sm btn-light-info ms-2">Update</button>
				</div>
			</div>
		</div>
	</div>`
	// Customer
	if (msg.user_type === 'customer') {
		var _avatar = '';
		if (msg.user && msg.user.email) {
			_avatar = getAva({ seed: msg.user.email });
		}
		_html = `
		<div id="${msg._id}" class="my-8 body_type_${msg.body_type}">
			<div class="d-flex justify-content-start mb-10">
				<div class="d-flex flex-column align-items-start">
					<div class="d-flex align-items-center mb-2">
						<div class="symbol symbol-40px">
							${_avatar}
						</div>
						<div class="ms-2">
							<div class="font-weight-500 text-dark">${msg.user.first_name} ${msg.user.last_name}</div>${_receipt}
							<div class="text-muted fs-8">${_date}</div>
						</div>
					</div>
					<div class="px-5 py-3 rounded-0-7 bg-light-info text-dark mw-lg-400px text-start message_only" id="chat_message_${msg._id}">${msg.body}</div>
					${_actions}
					${_page}
				</div>
			</div>
		</div>`.replace(/\t/gm,'').replace(/\n/gm,'');
	}
	else {
		_html = `
		<div id="${msg._id}" class="my-8 body_type_${msg.body_type}">
			<div class="d-flex justify-content-end mb-10">
				<div class="d-flex flex-column align-items-end">
					<div class="d-flex align-items-center mb-2">
						<div class="me-2 text-end">
							<div class="font-weight-500 text-dark">${msg.user.first_name} ${msg.user.last_name}</div>
							<div class="text-muted fs-8">${_date}</div>
						</div>
						<div class="symbol symbol-40px">
							<div class="symbol-label" style="background-image:url(${encodeURI(msg.user.avatar)})"></div>
						</div>
					</div>
					<div class="px-5 py-3 rounded-0-7 bg-light-primary text-dark mw-lg-400px text-end message_only" id="chat_message_${msg._id}">${msg.body}</div>
					<div class="d-flex justify-content-end">${_actions}</div>
				</div>
			</div>
		</div>${_form}`.replace(/\t/gm,'').replace(/\n/gm,'');
	}
	// Return
	return _html;
}

function _loadingHtml() {
	var _html = `<section class="chat_loading_admin">
	<div class="d-flex d-inline-block flex-row-reverse pr-3">
		<div class="flex-column d-flex">
			<section>
				<div class="message py-2">
					<div class="dot-pulse"></div>
				</div>
			</section>
		</div>
		<div class="px-4"></div>
	</div>
	<div class="mb-3"></div>
</section>`.replace(/\t/gm,'').replace(/\n/gm,'');
	// Ret
	return _html;
}




///////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////// HTML
///////////////////////////////////////////////////////////////////////////////

function _statusHtml(msg) {
	// Date
	var _date_full = moment(msg.timestamp);
	var _date = _date_full.tz( moment.tz.guess() ).format("h:mm A")
	// HTML
	var _html = `<section class="p-3">
		<div class="divider thin-line one-line text-center">
			<span class="font-size-14">
				${msg.body}
				<br />
				<span class="small text-muted mt-2 fs-8">${_date}</span>
			</span>
		</div>
	</section>`.replace(/\t/gm,'').replace(/\n/gm,'');
	// Return
	return _html;
}

function _fileHtml(msg) {
	// Date
	var _date_full = moment(msg.timestamp);
	var _date = _date_full.tz( moment.tz.guess() ).format("h:mm A")
	// Attachment
	var _att = msg.attachment;
	var _url = _att.url;
	var _file = '';
	var _actions = '';
	if (_att.content_disposition === 'inline') {
		_file = '<a href="' + _url + '" target="_blank"><img class="img-fluid" src="' + _url + '" style="max-width:250px"><br><span>' + _att.file_name + '</span></a>'
	}
	else {
		_file = '<a href="' + _url + '" target="_blank">' + _att.file_name + '</a>'
	}
	// If agent append actions
	if (msg.for === 'agent') {
		_actions = `<div class="actions_message">
			<a href="#" onclick="engageDeleteFile('${msg._id}', '${_att.checksum}');return false;">
				<span class="${_fa_style}trash font-size-1-1-r"></span>
			</a>
		</div>`;
	}
	// HTML
	var _html = `<section class="p-3" id="${msg._id}">
		<div class="divider thin-line one-line text-center">
			<div class="content">
				<span class="font-size-14">
					${_file}
					<br />
					${msg.body}
					<br />
					<span class="small text-muted mt-2 fs-8">${_date}</span>
				</span>
				${_actions}
			</div>
		</div>
	</section>`.replace(/\t/gm,'').replace(/\n/gm,'');
	// Return
	return _html;
}


var _dtad;
var _modal_detail_list_name = '';

$(document).ready(function() {
	if (!document.location.href.includes('login') && !document.location.href.includes('register') && !document.location.href.includes('account') && !document.location.href.includes('admin')) {
		if ( _app_instance === 'helpmonks' ) {
			// Check for sending emails delayed
			// setTimeout(function() {
			// 	marketingLoadSendingEmails();
			// }, 1000);
			// Lets make sure we have contact labels loaded
			getContactLabels();
		}
	}

	//- Load detailed campaigns when user clicks on link
	$('.tab-campaign-templates').on('show.bs.tab', function(e) {
		engageLoadEmailTemplates();
	});
	//- Load overview with drafts when user clicks on link
	$('.tab-campaign-overview').on('show.bs.tab', function(e) {
		engageLoadCampaignDrafts();
	});
	//- Load lists when user clicks on link
	$('.tab-campaign-lists').on('show.bs.tab', function(e) {
		engageLoadLists();
	});
	//- Load lists when user clicks on link
	$('.tab-campaign-settings').on('show.bs.tab', function(e) {
		marketingLoadSettings();
	});
	//- Archive detail modal
	$(document).on('shown.bs.modal', '#marketingArchiveDetail', function(e) {

		// Hide engage container
		$('#engage-container').addClass('hidden');
		// Remove hidden
		$('.loading-div-modal').addClass('hidden');


		$('.modal-header').removeClass('hidden');

		setTimeout(function() {

			var _id = $('#marketingArchiveDetail #engage_campaign_archive_id').val();

			//- Set modal header
			$('#marketingArchiveDetail .modal-title').html( $('#engage_campaign_archive_subject').val() );

			// var _iframe_content = $('#engage_template_preview_body').val();
			// _iFrameResizer({ id: `#engage_message_frame_${_id}`, load_id: 'srcdoc', load_url: _iframe_content });

			$('#marketing_archive_html').removeClass('hidden');

			Redactor('#marketing_archive_html', {
				plugins: ['email', 'fullscreen'],
				theme: localStorage.getItem('data-bs-theme') || 'light',
				scrollTarget: '.modal-body',
				path: '/dist2/',
				minHeight: '80vh',
				maxHeight: '100%',
				email: {
					dark: false,
					spacing: "0px"
				},
				container: {
					border: false
				},
				source: false,
				addbar: false,
				context: false,
				control: false,
				extrabar: false,
				toolbar: false,
				buttons: {
					context: [],
					toolbar: []
				},
			});

			// if we already got a dt initialized
			// if ( $.fn.dataTable.isDataTable( '#dt_campaigns_recipients' ) ) {
			// 	_dtad.search('').draw('page');
			// }
			// else {

				//- Archive Details
				_dtad = $('#dt_campaigns_recipients').DataTable( {
					"searching": true,
					"processing": true,
					"serverSide": true,
					"destroy": true,
					"pageLength": 25,
					"lengthMenu": [ 25, 50, 75, 100, 200, 500 ],
					"ajax": {
						"url" : `/marketing/archive/detail/logs/${_id}`,
						"type": 'POST'
					},
					"rowId" : "_id",
					"deferRender": true,
					"order": [ [ 2, "desc" ] ],
					"columns": [
						{
							data: null,
							wrap: true,
							orderable: true,
							render: function (item) {
								if (!item.user_id) return '';
								var _email = `<div class="text-muted fs-9">${item.user_id.email}</div>`;
								var _html = `
								<a href="#" class="menu-link open-detail-modal" data-id="${item.user_id._id}" data-kind="contact" data-direct="/contacts/p/${item.user_id._id}">
									<div class="d-flex align-items-center">
										<div class="symbol symbol-35px">${getAva({ seed: item.user_id.email, contacts: true })}</div>
										<div class="ms-3">
											<div class="fw-semibold">${item.user_id.full_name}</div>
											${_email}
										</div>
									</div>
								</a>
								${item.clicks}
								`;
								return _html;
							}
						},
						{
							name: 'tracking.timestamp',
							data: null,
							wrap: true,
							orderable: true,
							render: function (item) {
								//- var _open = item.tracking && item.tracking.seen ? item.tracking.timestamp_display : null;
								return `<span class="text-muted fs-8">${item.tracking && item.tracking.seen ? item.tracking.timestamp_display : ''}`;
							}
						},
						{
							name: 'timestamp',
							data: null,
							orderable: true,
							render: function (item) {
								return `<span class="text-muted fs-8">${item.timestamp_display}</span>`;
							}
						}
					]
				});
				_dtCampaignsSentDetail = hmDataTables(_dtad, '#dt_campaigns_recipients_div');

			// }

		}, 1000)

	});

	// This is a very dumb hack
	if (!document.location.href.includes('login') && !document.location.href.includes('register') && !document.location.href.includes('account') && !document.location.href.includes('admin')) {
		if ( _app_instance === 'helpmonks' ) {

			//- Archive
			var _dtc = $('#dt_campaigns_sent').DataTable( {
				"searching": true,
				"processing": true,
				"serverSide": true,
				//- "pageLength": 15,
				"lengthMenu": [ 15, 25, 50, 75, 100 ],
				"ajax": {
					"url" : `/marketing/archive`,
					"type": 'POST'
				},
				"rowId" : "_id",
				"deferRender": true,
				"order": [ [ 1, "desc" ] ],
				"columns": [
					{
						data: null,
						wrap: true,
						orderable: true,
						render: function (item) {
							return `<a href="#" class="dtModal" data-id="${item._id}" data-type="marketing_archive">
								<div class="fw-bold">${item.subject}</div>
								<div class="text-muted fs-8">${item.to}</div>
							</a>`;
						}
					},
					{
						name: 'date_sent',
						data: null,
						orderable: true,
						render: function (item) {
							return `<span class="text-muted fs-8">${item.date_sent_display}</span>`;
						}
					},
					{
						name: 'user',
						data: null,
						orderable: true,
						render: function (item) {
							return `<span class="text-muted fs-8">${item.user.header_display_name}</span>`;
						}
					},
					{
						data: null,
						orderable: false,
						render: function (item) {
							return `<a href="#" onclick="deleteRecord('marketing_archive', '${item._id}');" class="btn btn-icon btn-sm btn-light-info"><span class="${_fa_style}trash"></span></a>`;
						}
					}
				]
			});
			_dtCampaignsSent = hmDataTables(_dtc, '#dt_campaigns_sent_div');

			_dtc.on('draw', function(e) {
				var info = _dtc.page.info();
				if (info.recordsTotal) {
					$('#dt_campaigns_sent_div').removeClass('hidden');
					$('#dt_campaigns_sent_empty_div').addClass('hidden');
				}
				else {
					$('#dt_campaigns_sent_div').addClass('hidden');
					$('#dt_campaigns_sent_empty_div').removeClass('hidden');
				}
			});

		}
	}

	//- Event for list settings form
	$(document).on('submit', '#marketing_lists_settings_form', function() {
		//- Get values
		var _data = formserialize('marketing_lists_settings_form');
		//- Update
		$.ajax({
			type: "POST",
			url: `/marketing/lists/settings_update`,
			data: _data,
			statusCode: {
				// Already here
				500: function(data) {
					notyWrapper({
						text: 'There was an error saving the settings',
						type: 'error'
					});
				},
				// Refresh
				200: function(data) {
					notyWrapper({
						text: i18n.__('success_save'),
						type: 'success'
					});
					//- Close modal
					$('#listsSettingsModal').modal('hide');
					//- Refresh list page
					engageLoadLists();
					loadCampaignsData();
					//- Set list name
					_modal_detail_list_name = $('#marketing_lists_settings_form #engage_list_name').val();
					setTimeout(function() {
						$('.modal.show #myModalLabel').html( _modal_detail_list_name );
					}, 1000)
				}
			}
		});
		return false;
	})

	$(document).on('submit', '#newCampaignsListForm', function() {
		//- Get values
		var name = $('#newCampaignsListForm #name').val();
		var description = $('#newCampaignsListForm #description').val();
		//- If name is empty
		if (!name) {
			return false;
		}
		//- Submit
		$.ajax({
			type: "POST",
			url: '/marketing/lists/new',
			data : { name, description },
			statusCode: {
				// Error
				500: function(data) {
					notyWrapper({
						text: i18n.__('general_error'),
						type: 'error'
					});
				},
				// Done
				200: function(id) {
					//- Close this model
					var _modal = bootstrap.Modal.getInstance( document.getElementById('newListModal') );
					_modal.toggle();
					//- Reload lists
					engageLoadLists();
					loadCampaignsData();
					//- Open edit modal with company id
					globalOpenModal('listsDetailsModal', `/marketing/lists/detail/${id}`);
				}
			}
		});
		return false;
	})

	$(document).on('submit', '#newCampaignForm', function() {
		//- Get values
		var name = $('#newCampaignForm #name').val();
		var description = $('#newCampaignForm #description').val();
		//- If name is empty
		if (!name) {
			return false;
		}
		//- Submit
		$.ajax({
			type: "POST",
			url: '/marketing/campaign/new',
			data : { name, description },
			statusCode: {
				// Error
				500: function(data) {
					notyWrapper({
						text: i18n.__('general_error'),
						type: 'error'
					});
				},
				// Done
				200: function(id) {
					//- Close this model
					var _modal = bootstrap.Modal.getInstance( document.getElementById('newCampaignModal') );
					_modal.toggle();
					//- Reload lists
					engageLoadCampaignDrafts();
					//- Open edit modal with company id
					globalOpenModal('campaignDetailsModal', `/marketing/campaign/edit/${id}`);
				}
			}
		});
		return false;
	})

	// Open detail model from here
	$(document).on('click', '.open-marketing-detail-modal', function(e) {
		var _no_event = $(e.target).parents().addBack().is(".noevent");
		if (_no_event) return true;
		// Grab id
		var _id = $(this).data('id');
		// Load window
		loadDynamicContent(`/marketing/campaign/edit/${_id}`, 'campaignDetailsModal');
		// Modal
		var _modal = new bootstrap.Modal(document.getElementById('campaignDetailsModal'), { backdrop:'static', keyboard:false });
		_modal.show();

	})

	//- Campaign detail modal
	$('#campaignDetailsModal').on('shown.bs.modal', function(e) {
		//- Set modal header
		$('#campaignDetailsModal .modal-title').html( $('#campaign_edit_name').val() );

		setTimeout(function() {
			$("#campaign_edit_lists").select2({
				placeholder: {
					id: '-1',
					text: ' '
				},
				dropdownParent: $('#campaignDetailsModal')
			});
			$("#engage_campaign_from_email").select2({
				placeholder: {
					id: '-1',
					text: ' '
				},
				dropdownParent: $('#campaignDetailsModal')
			});
		}, 1000)

	});

	// New sending email
	$(document).on('submit', '#newSendingEmailsForm', function() {
		// Disable button
		$('.add_sending_email_button').attr('disabled', true);
		//- Get values
		var full_name = $('#newSendingEmailsForm #full_name').val();
		var email = $('#newSendingEmailsForm #email').val();
		//- Submit
		$.ajax({
			type: "POST",
			url: '/marketing/sending_emails/add',
			data : { full_name, email },
			statusCode: {
				// Error
				500: function(data) {
					notyWrapper({
						text: i18n.__('general_error'),
						type: 'error'
					});
					$('.add_sending_email_button').attr('disabled', false);
				},
				// Validation error
				401: function(data) {
					notyWrapper({
						text: data.responseText,
						type: 'warning',
						timeout: false
					});
					$('.add_sending_email_button').attr('disabled', false);
				},
				// 201
				201: function(id) {
					//- Close this model
					var _modal = bootstrap.Modal.getInstance( document.getElementById('newSendingEmailsModal') );
					_modal.toggle();
					//- Reload lists
					// marketingSendingEmailsLoad();
				},
				// 200
				200: function(id) {
					//- Close this model
					var _modal = bootstrap.Modal.getInstance( document.getElementById('newSendingEmailsModal') );
					_modal.toggle();
					//- Reload lists
					// marketingSendingEmailsLoad();
					// Show modal
					notyWrapper({
						modal: true,
						title: i18n.__('heads_up'),
						html: i18n.__('ses_email_verify_desc'),
						confirm_text: i18n.__('verification_resend_button'),
						type: 'info',
					});
				}
			}
		});
		return false;
	})

})

// function marketingSendingEmailsLoad() {
// 	lfGetItem(`marketing_sending_emails_${_getHostIdCookie()}`).then(emails => {
// 		if (emails && !emails.length) {
// 			$('#marketing_sending_emails_alert').removeClass('hidden');
// 			$('.btn-campaign-new').addClass('hidden');
// 		}
// 		else {
// 			$('#marketing_sending_emails_alert').addClass('hidden');
// 			$('.btn-campaign-new').removeClass('hidden');
// 		}
// 		marketingLoadDivSendingEmails();
// 	})
// }

function marketingLoadDivSendingEmails() {
	$('#sending_emails_body').load('/marketing/sending_emails');
}

function marketingReSendVerificationEmail(id) {
	$('#dummy').load(`/marketing/sending_emails_resend_verification?id=${id}`, function() {
		notyWrapper({
			modal: true,
			title: i18n.__('heads_up'),
			html: i18n.__('ses_email_verify_desc'),
			confirm_text: i18n.__('verification_resend_button'),
			type: 'info',
		});
	});
}

//- Final
function engageCampaignFinal() {
	//- Get values
	var _data = formserialize('campaign_edit_form');
	var _from_select = $('#campaignDetailsModal #engage_campaign_from_email').select2('data')[0];
	_data += `&from_select_mailbox=${_from_select.id}&from_select_email=${_from_select.text}`
	// If to is empty
	var _lists = $('#campaign_edit_lists').val();
	var _all_hosts = $('#engage_campaign_all_hosts').is(':checked');
	var _all_razuna = $('#engage_campaign_all_razuna').is(':checked');
	var _all_mailboxes = $('#engage_campaign_all_mailboxes').is(':checked');
	if (!_lists.length && !_all_hosts && !_all_razuna && !_all_mailboxes) {
		notyWrapper({
			text: 'Please enter to whom you want to send the campaign',
			type: 'warning'
		});
		return false
	}
	// Save draft
	$.ajax({
		type: "POST",
		url: '/marketing/campaign/update',
		data: _data,
		statusCode: {
			200: function(data) {
				// Get
				$.ajax({
					type: "POST",
					url: '/marketing/campaign/final',
					data: _data,
					statusCode: {
						// Already here
						500: function(data) {
							notyWrapper({
								text: 'There was an error getting the final preview',
								type: 'error'
							});
						},
						// No SMTP
						201: function() {
							notyWrapper({
								text: 'Please enter a valid SMTP server before sending emails',
								type: 'warning'
							});
						},
						// Refresh
						200: function(data) {
							//- Hide modal header
							$('.modal-header').addClass('hidden');
							$('#campaign_edit_container').html(data);
						},
						202: function(data) {
							notyWrapper({
								modal: true,
								title: i18n.__('heads_up'),
								html: i18n.__('message_spam_desc'),
								confirm_text: i18n.__('i_understand'),
								type: 'warning',
							});
							return false;
						}
					}
				})
			}
		}
	})
	return false;
}

function engageCampaignScheduleSendDo() {
	// Draft id
	var _draft_id = $('#engage_campaign_draft_id').val();
	// Close dropdown
	$('#campaignFinalSchedule').dropdown('hide')
	// Get date value
	var _date = $('#campaign_edit_container #marketing_campaign_schedule_date').val();
	// Noty
	notyWrapper({
		modal: true,
		title: i18n.__('campaign_scheduled_confirmation_header'),
		html: i18n.__('campaign_scheduled_confirmation_desc'),
		type: 'success'
	});
	// Save
	$.ajax({
		type: "POST",
		url: '/marketing/campaign/draft_scheduled',
		data: { draft_id : _draft_id, scheduled : _date },
		statusCode: {
			200: function() {
				//- Reload drafts
				engageLoadCampaignDrafts();
				//- Close modal
				$('#campaignDetailsModal').modal('hide');
			}
		}
	})
}

function engageCampaignSend() {
	// Draft id
	var _draft_id = $('#engage_campaign_draft_id').val();
	// Noty
	notyWrapper({
		modal: true,
		title: i18n.__('campaign_sent_confirmation_header'),
		html: i18n.__('campaign_sent_confirmation_desc'),
		type: 'success'
	});
	// Save
	$.ajax({
		type: "GET",
		url: `/marketing/campaign/send/${_draft_id}`,
		statusCode: {
			200: function() {
				//- Reload drafts
				engageLoadCampaignDrafts();
				//- Reload sent
				dtRefreshTable('#dt_campaigns_sent');
				//- Close modal
				$('#campaignDetailsModal').modal('hide');
			}
		}
	})
}

//- Campaign edit update
function campaignEditUpdate(div, notify) {
	// console.log("div", div);
	notify = notify || false;
	//- Get values
	var _data = formserialize('campaign_edit_form');
	_data += `&from_save=${div}`;
	if ( $('#workflow_event').val() !== '0' ) _data += `&workflow_event=` + $('#workflow_event').val();
	// Get mailbox selection
	var _from_select = $('#campaignDetailsModal #engage_campaign_from_email').select2('data')[0];
	_data += `&from_select_mailbox=${_from_select.id}&from_select_email=${_from_select.text}`
	// Submit
	$.ajax({
		type: "POST",
		url: '/marketing/campaign/update',
		data: _data,
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error saving the campaign',
					type: 'error'
				});
			},
			// Refresh
			200: function(data) {
				// console.log('data: ', data)
				// Notification
				if (notify) {
					notyWrapper({
						text: 'Your campaign has been saved. You will find it in the \"Drafts\" section.',
						type: 'success'
					});
				}
				//- Update modal title
				$('#campaignDetailsModal .modal-title').html( $('#campaign_edit_form #name').val() );
				//- Close div and update with values
				campaignEditToggleDiv(div);
				campaignUpdateWorkflowDiv();
				//- Depending on div update the preview
				switch(div) {
					case 'campaign_subject':
						var _subject = $('#campaignDetailsModal #engage_campaign_subject').val();
						if (_subject) {
							$(`#${div}_preview`).removeClass('hidden').html( _subject );
							$(`#${div}_preview_no`).addClass('hidden');
						}
						break;
					case 'campaign_from':
						var _from_name = $('#campaignDetailsModal #engage_campaign_from_name').val();
						var _from_email = _from_select.text;
						var _from_mailbox = _from_select.id;
						Cookies.set('engage_campaign_from_name', _from_name, _globalCookieOptions(365));
						Cookies.set('engage_campaign_from_email', _from_email, _globalCookieOptions(365));
						Cookies.set('engage_campaign_from_mailbox', _from_mailbox, _globalCookieOptions(365));
						$(`#${div}_preview`).removeClass('hidden');
						$(`#${div}_preview_name`).html( _from_name );
						$(`#${div}_preview_email`).html( _from_email );
						// $(`#${div}_preview_email`).html( _from_email + '@' + $('#campaignDetailsModal #engage_campaign_from_domain').val() );
						$(`#${div}_preview_no`).addClass('hidden');
						break;
					case 'campaign_to':
						var _list = $('#campaignDetailsModal #campaign_edit_lists').val();
						var _all_hosts = $('#engage_campaign_all_hosts').is(':checked');
						var _all_razuna = $('#engage_campaign_all_razuna').is(':checked');
						var _all_mailboxes = $('#engage_campaign_all_mailboxes').is(':checked');
						$(`#${div}_preview`).removeClass('hidden');
						$(`#${div}_preview_no`).addClass('hidden');
						//- Hide all lists
						$('.campaign_to_preview_lists_name').addClass('hidden');
						$('.campaign_to_preview_all').addClass('hidden');
						//- Show list
						if (_list && _list.length) {
							$(`#${div}_preview_lists`).removeClass('hidden');

							$.each(_list, function(i, v) {
								$('#campaign_list_id_' + v).removeClass('hidden');
							})
						}
						if (_all_hosts) {
							$('.preview_all_count').html(data.count);
							$(`#${div}_preview_all_hosts`).removeClass('hidden');
						}
						if (_all_razuna) {
							$('.preview_all_count').html(data.count);
							$(`#${div}_preview_all_hosts`).removeClass('hidden');
						}
						if (_all_mailboxes) {
							$('.preview_all_count').html(data.count);
							$(`#${div}_preview_all_mailboxes`).removeClass('hidden');
						}
						break;
					case 'campaign_workflow':
						$('#workflow_actions_json').val( JSON.stringify(data.workflow) );
						// How many divs
						var _actions = data.workflow.length;
						// console.log("_actions", _actions);
						if (_actions) {
							$('.campaign_workflow_desc_nope').addClass('hidden');
							$('.campaign_workflow_desc_here').removeClass('hidden').html(`You have ${_actions} workflow action(s) configured for this campaign.`);
						}
						else {
							$('.campaign_workflow_desc_here').addClass('hidden');
							$('.campaign_workflow_desc_nope').removeClass('hidden');
						}
						break;
				}
				//- Refresh draft list
				engageLoadCampaignDrafts();
				// Check limit
				if ( !getGlobalPaidPlan() ) {
					lfGetItem('sendmonks_account_info_' + _getHostIdCookie()).then(d => {
						//- Get limit
						var _limit = $('#global_plan_limit_sent').val() ? parseInt($('#global_plan_limit_sent').val()) : 0;
						var _list_sent = data.count + d.sent;
						if ( _list_sent >= _limit ) {
							// Disable button
							$('#engage_campaign_submit').attr('disabled', true);
							// Show alert
							$('.marketing-edit-upgrade').removeClass('hidden');
						}
						else {
							// Enable button
							$('#engage_campaign_submit').attr('disabled', false);
						}
					})
				}
			}
		}
	});
	return false;
}

// Workflow

// Load Workflow Actions
function marketingCampaignLoadWorkflowActions() {
	var _actions = JSON.parse( $('#workflow_actions_json').val() );
	// When empty
	if ( !_actions.length ) return;
	// Loops over actions and add
	$.each(_actions, function(i, v) {
		marketingCampaignAddWorkflowAction(v);
	});
}

// Add workflow action
function marketingCampaignAddWorkflowAction(obj) {
	// console.log("obj", obj);
	// Disable event selection
	$('#workflow_event').attr("disabled", true);
	//- Existing
	obj = obj || { _id : new oid.ObjectId().toHexString(), notify_text: '' };
	var _action_id = obj._id;
	// Get which action to add
	var _action = obj.action ? obj.action : $('#workflow_action').val();
	//- If 0
	if (_action === '0') return;
	//- Reset selection
	$('#workflow_action').val(0).trigger('change');
	//- Var
	var _header = '';
	var _text = '';
	var _html_action = '';
	var _html = '';
	// Depending on action
	switch(_action) {
		case 'labels':
			_header = `You are changing the labels of the contact and...`;
			_html_action = `
			<div class="pt-3">
				<div class="fs-6">...add the following labels</div>
				<select id='campaign_action_labels_add_${_action_id}' name='campaign_action_labels_add' class='form-select form-select-solid' multiple='multiple'>
					<options value="-1">
				</select>
			</div>
			<div class="pt-3">
				<div class="fs-6">...remove the following labels</div>
				<select id='campaign_action_labels_remove_${_action_id}' name='campaign_action_labels_remove' class='form-select form-select-solid' multiple='multiple'>
					<options value="-1">
				</select>
			</div>
			<input type="hidden" name="campaign_action_labels" value="true">
			`;
			//- Disable action selection
			$('#workflow_action').find('[value=labels]').prop('disabled', true);
			break;
		case 'send_again':
			var _subject = $('#engage_campaign_subject').val();
			_header = `You are re-sending this campaign`;
			_html_action = `<div class="pt-3">
				<div class="fs-6">Re-send with the following subject (Tip: Re-sending with different subject might increase your open rate)</div>
				<input id="resend_subject" name="campaign_action_resend_subject" class="form-control form-control-solid" value="${_subject}">
			</div>
			<input type="hidden" name="campaign_action_resend" value="true">
			`;
			//- Disable action selection
			$('#workflow_action').find('[value=send_again]').prop('disabled', true);
			break;
		case 'remove':
			_header = `You are removing the contact from the associated mailing list`;
			_html_action = `<input type="hidden" name="campaign_action_remove" value="true">`;
			//- Disable action selection
			$('#workflow_action').find('[value=remove]').prop('disabled', true);
			break;
		case 'move':
			_header = `You are moving the contact from the associated mailing list...`;
			_html_action = `<div class="pt-3">
				<div class="fs-6">... and add the contact to the following list(s)</div>
				<select id='campaign_action_move_to_list_${_action_id}' name='campaign_action_move_to_list' class='form-select form-select-solid' multiple='multiple'>
					<options value="-1">
				</select>
			</div>
			<input type="hidden" name="campaign_action_move" value="true">
			`;
			//- Disable action selection
			$('#workflow_action').find('[value=move]').prop('disabled', true);
			break;
		case 'notify':
			_text = obj.notify_text;
			_header = `You are sending a notification to...`;
			_html_action = `
			<div class="pt-3">
				<div class="fs-6">...these users...</div>
				<select name="campaign_action_notify_users" id="campaign_action_notify_users_${_action_id}" multiple='multiple' class="form-select form-select-solid">
					<options value="-1">
				</select>
			</div>
			<div class="pt-3">
				<div class="fs-6">... with the following message</div>
				<textarea name="campaign_action_notify_text" id="campaign_action_notify_text_${_action_id}" class="form-control form-control-solid" style="height:100px;">${_text}</textarea>
			</div>
			<input type="hidden" name="campaign_action_notify" value="true">
			`;
			//- Disable action selection
			$('#workflow_action').find('[value=notify]').prop('disabled', true);
			break;
	}
	//- HTML
	_html = `
	<div class="card border bordered mb-3 campaign-action-card border-info" id="campaign_action_${_action_id}">
		<div class="card-body">
			<div class="d-flex">
				<span class="fw-bold fs-6">${_header}</span>
				<div class="ms-auto">
					<a href="#" onclick="marketingCampaignRemoveAction('${_action_id}','${_action}');return false;">Remove action</a>
				</div>
			</div>
			${_html_action}
		</div>
	</div>
	`;
	// Show div
	$('#campaign_workflow_actions_div').removeClass('hidden');
	//- Append
	$('#campaign_workflow_actions').append(_html);
	// functions for the above actions
	// Depending on action
	switch(_action) {
		case 'send_again':
			if (obj.resend_subject) {
				$('#resend_subject').val( obj.resend_subject );
			}
			break;
		case 'notify':
			// Get users and add to select
			getAccountUsers().then(data => {
				var _users = data.select2;
				$(`#campaign_action_notify_users_${_action_id}`).select2({
					data: _users,
					dropdownParent: $('#campaignDetailsModal'),
					placeholder: {
						id: "-1",
						text: i18n.__('search_for_users')
					}
				});
				// Existing
				$(`#campaign_action_notify_users_${_action_id}`).val(obj.notify_users).trigger('change');
			});
			break;
		case 'labels':
			// Get contact labels
			getContactLabels().then(data => {
				var _labels = data.select2;
				$(`#campaign_action_labels_add_${_action_id}`).select2({
					data: _labels,
					dropdownParent: $('#campaignDetailsModal'),
					placeholder: {
						id: "-1",
						text: i18n.__('search_for_label')
					}
				});
				$(`#campaign_action_labels_remove_${_action_id}`).select2({
					data: _labels,
					dropdownParent: $('#campaignDetailsModal'),
					placeholder: {
						id: "-1",
						text: i18n.__('search_for_label')
					}
				});
				// Existing
				$(`#campaign_action_labels_add_${_action_id}`).val(obj.labels_add).trigger('change');
				$(`#campaign_action_labels_remove_${_action_id}`).val(obj.labels_remove).trigger('change');
			});
			break;
		case 'move':
			lfGetItem(`engage_campaign_data_${_getHostIdCookie()}`).then(data => {
				var _lists = data.contact_lists;
				$(`#campaign_action_move_to_list_${_action_id}`).select2({
					data: _lists,
					dropdownParent: $('#campaignDetailsModal'),
					placeholder: {
						id: "-1",
						text: i18n.__('type_to_filter')
					}
				});
				// Existing
				$(`#campaign_action_move_to_list_${_action_id}`).val(obj.move_to).trigger('change');
			});
			break;
	}
}

function marketingCampaignRemoveAction(id, action) {
	//- Remove
	$('#campaign_action_' + id).remove();
	// Depending on action
	switch(action) {
		case 'labels':
			$('#workflow_action').find('[value=labels]').prop('disabled', false);
			break;
		case 'send_again':
			//- Enable action selection
			$('#workflow_action').find('[value=send_again]').prop('disabled', false);
			break;
		case 'remove':
			$('#workflow_action').find('[value=remove]').prop('disabled', false);
			break;
		case 'move':
			$('#workflow_action').find('[value=move]').prop('disabled', false);
			break;
		case 'notify':
			$('#workflow_action').find('[value=notify]').prop('disabled', false);
			break;
	}
	// How many cards are left
	if ( !$('.campaign-action-card').length ) {
		// Enable event selection
		$('#workflow_event').attr("disabled", false);
		// Reset event
		$('#workflow_event').val('0').trigger('change');
	}
}

//- Save body only
function marketingCampaignEditContentSave(options) {
	// console.log("🚀 - file: page_campaigns.js:928 - marketingCampaignEditContentSave - options:", options)
	//- Draft id
	var id = options.template_id;
	//- Content
	var body = options.html;
	var body_editor = options.content;
	// Check that we have a unsubscribe link
	var _valid = body_editor.includes('$unsubscribe$') || body_editor.includes('$link_unsubscribe$') ? true : false;
	_valid = Cookies.get('host_id') === '53837271b7b1cbce6da6ce06' ? true : _valid;
	if ( !_valid ) {
		notyWrapper({
			modal: true,
			title: i18n.__('heads_up'),
			html: i18n.__('needs_unsubscribe_desc'),
			confirm_text: i18n.__('i_understand'),
			type: 'warning',
		});
		return false;
	}
	//- Save
	$.ajax({
		type: "POST",
		url: '/marketing/campaign/edit_content_save',
		data: { id, body, body_editor },
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error saving the campaign',
					type: 'error'
				});
			},
			// Refresh
			200: function(data) {
				// Notification
				if (options.notify) {
					notyWrapper({
						text: 'Your content has been saved',
						type: 'success'
					});
				}
			},
			201: function(data) {
				notyWrapper({
					modal: true,
					title: i18n.__('heads_up'),
					html: i18n.__('needs_unsubscribe_desc'),
					confirm_text: i18n.__('i_understand'),
					type: 'warning',
				});
				return false;
			},
			202: function(data) {
				notyWrapper({
					modal: true,
					title: i18n.__('heads_up'),
					html: i18n.__('needs_unsubscribe_desc'),
					confirm_text: i18n.__('i_understand'),
					type: 'warning',
				});
				return false;
			}
		}
	})
}

function engageCampaignTest(div) {
	//- Get values
	var _data = formserialize('campaign_edit_form');
	var _from_select = $('#campaignDetailsModal #engage_campaign_from_email').select2('data')[0];
	_data += `&from_select_mailbox=${_from_select.id}&from_select_email=${_from_select.text}`
	// Save draft
	$.ajax({
		type: "POST",
		url: '/marketing/campaign/update',
		data: _data,
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error sending the test email',
					type: 'error'
				});
			},
			200: function(data) {
				marketingCampaignTestSend(div);
			}
		}
	})
	return false;
}

function marketingCampaignTestSend(div) {
	//- Get id
	var id = $('#campaign_edit_container #id').val() || $('#campaign_edit_container #engage_template_id').val();
	// Get test emails
	// var test_emails = $('#engage_email_test').val();
	// Send email
	$.ajax({
		type: "POST",
		url: '/marketing/campaign/test',
		data: { id },
		statusCode: {
			// Error
			500: function() {
				notyWrapper({
					text: 'There was an error sending the test email',
					type: 'error'
				});
			},
			// No SMTP
			201: function() {
				notyWrapper({
					text: 'Please enter a valid SMTP server before sending emails!',
					type: 'warning'
				});
			},
			// No SMTP
			202: function() {
				notyWrapper({
					text: `Hey there! It looks like you haven't written your message yet. Please do so before sending a test email.`,
					type: 'warning'
				});
			},
			// Refresh
			200: function(data) {
				notyWrapper({
					text: 'The test email has been sent. Check your email client.',
					type: 'success'
				});
				// Close div
				// $(div).slideToggle();
			}
		}
	})
}

//- Campaign edit design
function campaignEditTemplates() {
	//- Get values
	var _data = formserialize('campaign_edit_form');
	var _from_select = $('#campaignDetailsModal #engage_campaign_from_email').select2('data')[0];
	_data += `&from_select_mailbox=${_from_select.id}&from_select_email=${_from_select.text}`
	// Save draft
	$.ajax({
		type: "POST",
		url: '/marketing/campaign/update',
		data: _data,
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error sending the test email',
					type: 'error'
				});
			},
			200: function(data) {
				//- Hide modal header
				$('.modal-header').addClass('hidden');
				$.ajax({
					type: "POST",
					url: '/marketing/campaign/edit_templates',
					data: _data,
					statusCode: {
						// Already here
						500: function(data) {
						},
						200: function(data) {
							$('#campaign_edit_container').html(data);
						}
					}
				})
			}
		}
	})
}

//- Campaign edit content
function campaignEditContent() {
	//- Get values
	var _data = formserialize('campaign_edit_form');
	var _from_select = $('#campaignDetailsModal #engage_campaign_from_email').select2('data')[0];
	_data += `&from_select_mailbox=${_from_select.id}&from_select_email=${_from_select.text}`
	// Save draft
	$.ajax({
		type: "POST",
		url: '/marketing/campaign/update',
		data: _data,
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error sending the test email',
					type: 'error'
				});
			},
			200: function(data) {
				//- Hide modal header
				$('.modal-header').addClass('hidden');
				$.ajax({
					type: "POST",
					url: '/marketing/campaign/edit_content',
					data: _data,
					statusCode: {
						// Already here
						500: function(data) {
						},
						200: function(data) {
							$('#campaign_edit_container').html(data);
						}
					}
				})
			}
		}
	})
}

function engageLoadCampaignArchive() {
	switchToTab('tabsent');
	_dtCampaignsSent.dataTablesRefresh();
}

function marketingLoadEdit(id) {
	//- Show modal header
	$('.modal-header').removeClass('hidden');
	$('#campaign_edit_container').load(`/marketing/campaign/edit/${id}`, { id }, function() {
		setTimeout(function() {
			$("#campaign_edit_lists").select2({
				placeholder: {
					id: '-1',
					text: ' '
				},
				dropdownParent: $('#campaignDetailsModal')
			});
			$("#engage_campaign_from_email").select2({
				placeholder: {
					id: '-1',
					text: ' '
				},
				dropdownParent: $('#campaignDetailsModal')
			});
		}, 1000)
	});
}

function marketingLoadEditTemplates(id) {
	$('#campaign_edit_container').load('/marketing/campaign/edit_templates', { id });
}

function marketingLoadEditTemplate(id, template_id, template) {
	template = template || false;
	$('#campaign_edit_container').load('/marketing/campaign/edit_template_load', { id, template_id, template });
}

//- Campaign edit toggle
function campaignEditToggleDiv(div) {
	$(`#${div}`).toggleClass('hidden');
	$(`#${div}_edit`).toggleClass('hidden');
}

function campaignUpdateWorkflowDiv() {
	$(`#campaign_workflow_actions`).html('');
}

function marketingGoToCampaignSettings() {
	$('.modal').modal('hide');
	switchToTab('tabsettings');
}

//- Load Settings
function marketingLoadSettings() {
	$('#campaigns_settings_div').load(`/marketing/campaigns/settings`);
};

//- Load lists
function engageLoadLists() {
	$('#campaigns_lists_div').load(`/marketing/lists`);
	$('.marketing_list_loading').addClass('hidden');
};



function _engageRemoveLabelFromList(data, type) {
	var _list_id = $('.modal.show #engage_campaign_list_detail_id').val();
	var _type_id = data._id || data.id;
	var _label_id = type === 'label' ? _type_id : null;
	var _company_id = type !== 'label' ? _type_id : null;
	$.ajax({
		type: "POST",
		url: '/marketing/lists/remove/label_or_company',
		data: { list_id: _list_id, label_id: _label_id, company_id: _company_id },
		statusCode: {
			// Error
			500: function (data) {
				console.log('ERROR removing label or company from list');
			},
			// Done
			200: function (data) {
				//- Refresh list
				// _dtCampaignsListsDetailsUsers.dataTablesRefresh();
				//- Refresh list page
				// engageLoadLists();
			}
		}
	});
}



function _engageAddLabelsToExcludeToList(data, action) {
	var list_id = $('.modal.show #engage_campaign_list_detail_id').val();
	var label_id = data._id || data.id;
	$.ajax({
		type: "POST",
		url: '/marketing/lists/detail/labels_to_exclude',
		data: { list_id, label_id, action },
		statusCode: {
			// Done
			200: function (data) {

			}
		}
	});
}

//- Lists Import all contacts
function marketingListsImportAllContacts(list_id) {
	//- Hide datatables
	$('#dt_campaigns_list_details_subscribers_div').addClass('hidden');
	$('#dt_campaigns_list_details_import_div').addClass('hidden');
	//- Show import div
	$('#dt_campaigns_list_details_import_all_contacts_div').removeClass('hidden');
	// $('#campaigns_list_details_import').load(`/settings/customers/import/modal?list_id=${list_id}`, {});
}

function marketingListsImportAllContactsDo(list_id) {
	$('#dt_campaigns_list_details_import_all_contacts_div_body').html(`<div class="fs-4 text-center">We have started the import of all your contacts to this list. You can continue using the system. We will notify you once the import is done.</div>`);
	$.ajax({
		type: "GET",
		url: `/marketing/lists/add_all_contacts/${list_id}`,
		statusCode: {
			200: function (data) {
				if (data === 'limit_reached') {
					$('#dt_campaigns_list_details_import_all_contacts_div_body').html(`<div class="fs-4 text-danger text-center">You have reached your contact limit and we cannot add more contacts to this list</div>`);
					return false;
				}
			}
		}
	});
}

//- Lists Import users
function marketingListsImport(list_id) {
	//- Hide datatables
	$('#dt_campaigns_list_details_subscribers_div').addClass('hidden');
	$('#dt_campaigns_list_details_import_all_contacts_div').addClass('hidden');
	//- Show import div
	$('#dt_campaigns_list_details_import_div').removeClass('hidden');
	$('#campaigns_list_details_import').load(`/settings/customers/import/modal?list_id=${list_id}`, {});
}



//- Load drafts
function engageLoadCampaignDrafts() {
	$.ajax({
		type: "GET",
		url: `/marketing/drafts`,
		statusCode: {
			200: function(data) {
				$('#campaignsdrafts').html(data);
			}
		}
	});
};

//- Archive log filter
function _applyCampaignsArchiveLogFilter(type) {
	switchToTab('tabcampaignarchievereport');
	type = type || null;
	if (type) {
		_dtad.search( `_filter:${type}`, false, false).draw();
	}
	else {
		_dtad.search('').draw(false);
	}
}
// Copy campaign
function engageCopyCampaign(id) {
	// Copy and when we are done open edit
	$.ajax({
		type: "GET",
		url: `/marketing/archive/copy/${id}`,
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error in copying the campaign',
					type: 'error'
				});
			},
			// Refresh
			200: function(data) {
				notyWrapper({
					text: 'The campaign has been copied and is now available in your drafts',
					type: 'success'
				});
				//- Reload drafts
				engageLoadCampaignDrafts();
			}
		}
	});
	return false;
}

// Update default templates
function engageUpdateDefaultTemplates() {
	//- Submit
	$.ajax({
		type: "GET",
		url: '/marketing/templates/update/default',
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error updating the default templates',
					type: 'error'
				});
			},
			// Refresh
			200: function(data) {
				notyWrapper({
					text: 'Default templates have been refreshed',
					type: 'success'
				});
				engageLoadEmailTemplates();
			}
		}
	});
	return false;
}
//- Reload templates
function engageLoadEmailTemplates() {
	//- Reload page
	$('#campaigns_templates_div').load(`/marketing/templates`);
}
// Copy template to user template
function engageCopyTemplate() {
	//- Get modal id
	var _id = $(".modal.show .modal-body #modalid").val();
	// Copy and when we are done open edit
	$.ajax({
		type: "GET",
		url: '/marketing/templates/copy?id=' + _id,
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error in copying the template',
					type: 'error'
				});
			},
			// Refresh
			200: function(data) {
				// Refresh background page
				engageLoadEmailTemplates();
				// Close modal
				$('#engage_email_template').modal('hide');
				notyWrapper({
					text: 'The template was copied and is now available for editing',
					type: 'success'
				});
			}
		}
	});
	return false;
}
//- Update template
function engageUpdateEmailTemplate(notify) {
	notify = notify || false;
	// Get values
	var _title = $('#engage_template_title').val();
	// If title empty
	if ( !_title ) _title = "My template";
	// Other values
	var _active = $('#engage_template_active').is(':checked');
	var _desc = $('#engage_template_description').val();
	var _body = $('#engage_template_editor_html').val();
	var _body_editor = $('#engage_template_editor_body').val();
	var _id = $('#engage_template_id').val();
	var _isnew = $('#engage_template_isnew').val();
	// Check that we have a unsubscribe link
	var _valid = _body_editor.includes('$unsubscribe$') || _body_editor.includes('$link_unsubscribe$') ? true : false;
	// if ( !_valid && Cookies.get('host_id') !== '53837271b7b1cbce6da6ce06' ) {
	if ( !_valid ) {
		notyWrapper({
			modal: true,
			title: i18n.__('heads_up'),
			html: i18n.__('needs_unsubscribe_desc'),
			confirm_text: i18n.__('i_understand'),
			type: 'warning',
		});
		return false;
	}
	// Submit
	$.ajax({
		type: "POST",
		url: '/marketing/templates/update',
		data: { id : _id, title : _title, description : _desc, body : _body, body_editor : _body_editor, isnew : _isnew, active : _active },
		statusCode: {
			200: function() {
				if (notify) {
					notyWrapper({
						text: 'The template has been saved successfully',
						type: 'success'
					});
				}
				// Set is new to false
				$('#engage_template_isnew').val('false');
				//- Refresh
				engageLoadEmailTemplates();
			},
			500: function() {
				notyWrapper({
					text: 'There was an error saving the template',
					type: 'error'
				});
			}
		}
	})
	return false;
}


///////////////////
// Select2 stuff //
///////////////////

// Create Tag
function engageSelect2CreateTag(params) {
	// Don't offset to create a tag if there is no @ or .
	if (params.term.indexOf('@') === -1 || params.term.indexOf('.') === -1) {
		// Return null to disable tag creation
		return null;
	}
	return {
		id: params.term,
		text: params.term,
		email: params.term
	};
}
// Show results
function engageSelect2TemplateResults(user) {
	if (user && !user._id) return;
	var _firstname = user.first_name || 'New';
	var _lastname = user.last_name || 'User';
	var _firstlast = _firstname + ' ' + _lastname;
	var _email = user.email || user.id;
	return _firstlast + ' ' + '(' + _email + ')';
}
function engageSelect2TemplateSelection(user) {
	var _email = user.text || user.email || user.id;
	return _email;
}
function engageSelect2EscapeMarkup(markup) {
	return markup;
}

///////////////////
// SMTP stuff //
///////////////////

//- Switch SMTP selection
function mailboxSwitchSmtpDiv(type) {
	//- Set all to display none first
	$('.smtp_div').css('display','none');
	toggleSlideDiv('#div_mailbox_smtp_' + type);
}

function smtpSendgridDomainAdd(domain, domain_id, mailbox_id) {
	$.ajax({
		type: "POST",
		url: '/marketing/campaigns/smtp_save',
		data: { domain, domain_id, mailbox_id },
		statusCode : {
			200 : function(data) {
				notyWrapper({
					text: 'Sendgrid is now configured for all email campaigns.',
					type: 'success'
				});
				//- Disable button
				$('#stmp_sendgrid_dns_div').addClass('hidden');
				//- Show check dns
				$('#stmp_sendgrid_success').removeClass('hidden');
			},
			201 : function(data) {
				notyWrapper({
					text: "#{__('sendgrid_button_domain_add_error')}",
					type: 'error',
					timeout: false
				});
			},
			202 : function(data) {
				notyWrapper({
					text: "#{__('sendgrid_button_domain_add_retry')}",
					type: 'warning',
					timeout: false
				});
			}
		}
	});
}

function engageCampaignTestSmtpSettings() {
	//- Some UI stuff
	// toggleSlideDiv('#engageSmtpTestEmailDiv');
	$('#engageSmtpTestEmailStatusDiv').removeClass('hidden').html('<span class="fw-bold">Please wait while we are testing your SMTP-Server. Hold on...</span>');
	//- Gather imap settings
	var _data = {
		'host' : $('#engage_smtp_server').val(),
		'port' : $('#engage_smtp_port').val(),
		'username' : $('#engage_smtp_username').val(),
		'password' : $('#engage_smtp_password').val(),
		'test_from' : $('#engage_smtp_test_from').val(),
		'test_to' : $('#engage_smtp_test_to').val()
	}
	// if empty
	if ( !_data.test_from || !_data.test_to ) {
		$('#engageSmtpTestEmailStatusDiv').addClass('hidden');
		return false;
	}
	// Save from into cookie
	Cookies.set('engage_campaign_smtp_test_from', _data.test_from, _globalCookieOptions(365));
	//- Post to route
	$.ajax({
		type: "POST",
		url: '/marketing/campaigns/smtp_test',
		data: _data,
		success: function() {
			$('#engageSmtpTestEmailStatusDiv').html('<span class="fw-bold text-success">A test message was sent to your mailbox address. Please check your inbox! Click the button below to save your SMTP settings.</span>');
			$('#engage_smtp_save_button').removeClass('hidden');
		},
		error: function(data) {
			$('#engageSmtpTestEmailStatusDiv').html('<span class="fw-bold text-danger">' + data.responseText + '</span>');
			$('#engage_smtp_save_button').addClass('hidden');
		}
	});
}

// Save SMTP
function engageSmtpSave() {
	var _data = {
		'host' : $('#engage_smtp_server').val(),
		'port' : $('#engage_smtp_port').val(),
		'username' : $('#engage_smtp_username').val(),
		'password' : $('#engage_smtp_password').val(),
		'selected' : $('#engage_smtp_select option:selected').val()
	}
	$.ajax({
		type: "POST",
		url: '/marketing/campaigns/smtp_save',
		data: _data,
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error saving your SMTP settings',
					type: 'error'
				});
			},
			// Refresh
			200: function(data) {
				notyWrapper({
					text: 'Your SMTP settings have been saved',
					type: 'success'
				});
			}
		}
	});
	return false;
}

// Just here so we can check if this script got loaded
function campaignsLoadInit() {
	return true;
}

$(document).ready(function() {

	// //- Load overview with drafts when user clicks on link
	// $('.tab-campaign-active').on('show.bs.tab', function(e) {
	// 	engageLoadDripCampaign(true);
	// });
	// //- Load overview with drafts when user clicks on link
	// $('.tab-campaign-inactive').on('show.bs.tab', function(e) {
	// 	engageLoadDripCampaign(false);
	// });

	// Wizard 1
	$(document).on('click', '.auto_template_wizard_button_1', function(e) {
		// Simply hide the template selection and show the form fields
		$('.auto_templates_div').addClass('hidden');
		$('.auto_templates_text_div').addClass('hidden');
		// Show the title field
		$('.auto_wizard_field_div').removeClass('hidden');
		// Focus
		$('.auto_wizard_field_div #title').focus();
		// Remove class from button so we listen to another class
		$('.auto_template_wizard_button_1').removeClass('auto_template_wizard_button_1').addClass('auto_template_wizard_button_2');
	});

	$(document).on('keydown', '#newCampaignWizard1', function (e) {
		if (e.key === 'Enter') {
			_submitAutoWizard();
			return false;
		}
	});

	// Wizard 2
	$(document).on('click', '.auto_template_wizard_button_2', function(e) {
		_submitAutoWizard();
	});

	function _submitAutoWizard() {
		// Get template
		var template = $('#newCampaignWizard1 input[type="radio"]:checked').val();
		// Get title
		var title = $('#newCampaignWizard1 #title').val();
		// Title should not be empty
		if (!title) {
			notyWrapper({
				text: 'Please provide a name for your campaign',
				type: 'error'
			});
			return false;
		}
		// Hide divs
		$('.auto_wizard_field_div').addClass('hidden');
		$('.auto_wizard_button_div').addClass('hidden');
		// Show placeholder
		$('.auto_wizard_animation_div').removeClass('hidden');
		// Submit
		$.ajax({
			type: "POST",
			url: `/marketing/automation/new/wizard/create`,
			data: { template, title },
			statusCode: {
				// Error
				500: function(data) {
					notyWrapper({
						text: i18n.__('general_error'),
						type: 'error'
					});
				},
				// Done
				200: function(id) {
					//- Close this model
					var _modal = bootstrap.Modal.getInstance( document.getElementById('newCampaignModalWizard') );
					_modal.toggle();
					//- Reload lists
					engageLoadDripCampaign(false);
					//- Open edit modal with company id
					globalOpenModal('campaignDetailsModal', `/marketing/automation/edit/${id}`);
				}
			}
		});
	}


	//- Campaign detail modal
	$('#campaignDetailsModal').on('shown.bs.modal', function(e) {

		setTimeout(function() {

			//- ID
			var _id = $('#engage_campaign_id').val();

			//- Set modal header
			$('#campaignDetailsModal .modal-title').html( $('#campaign_edit_name').val() );

			var _select2_lists = $("#campaign_edit_lists").select2({
				placeholder: {
					id: '-1'
				}
			})

		}, 1000);

	});

	$(document).on('submit', '#newCampaignForm', function() {
		//- Get values
		var title = $('#newCampaignForm #title').val();
		var description = $('#newCampaignForm #description').val();
		//- If title is empty
		if (!title) {
			return false;
		}
		//- Submit
		$.ajax({
			type: "POST",
			url: '/marketing/automation/new',
			data : { title, description },
			statusCode: {
				// Error
				500: function(data) {
					notyWrapper({
						text: i18n.__('general_error'),
						type: 'error'
					});
				},
				// Done
				200: function(id) {
					//- Close this model
					var _modal = bootstrap.Modal.getInstance( document.getElementById('newCampaignModal') );
					_modal.toggle();
					//- Reload lists
					engageLoadDripCampaign(false);
					//- Open edit modal with company id
					globalOpenModal('campaignDetailsModal', `/marketing/automation/edit/${id}`);
				}
			}
		});
		return false;
	})

	//- If active/inactive is changed
	$(document).on('change', '#engage_campaign_active', function() {
		//- Check active state
		// var campaign_active = $(this).is(':checked');
		//- if set to enable check if name, email, and template are set
		// if (campaign_active) {
		// 	var _name = $('#engage_campaign_from_name').val();
		// 	var _email = $('#engage_campaign_from_email').val();
		// 	var _template = $('#engage_campaign_template option:selected').val();
		// 	//- If any are empty
		// 	if ( !_name || !_email || !_template ) {
		// 		notyWrapper({
		// 			text: 'Your campaign is missing values for essential fields for sending and templates!',
		// 			type: 'error',
		// 			timeout: 8000
		// 		});
		// 		//- Reset actived
		// 		$('#engage_campaign_active').prop('checked', false);
		// 		//- Switch to tab
		// 		switchToTab('tabsettings');
		// 		return false;
		// 	}
		// }
		//- Show/hide edit
		_engageShowHideEditOption();
		//- Issue save
		engageCampaignAutoSave();
	});

	//- Submit edit
	$(document).on('submit', '#engage_campaign_auto_detail_form', function() {
		//- Check active state
		// var campaign_active = $(this).is(':checked');
		//- if set to enable check if name, email, and template are set
		// if (campaign_active) {
		// 	var _name = $('#engage_campaign_from_name').val();
		// 	var _email = $('#engage_campaign_from_email').val();
		// 	// var _template = $('#engage_campaign_template option:selected').val();
		// 	//- If any are empty
		// 	if ( !_name || !_email ) {
		// 		notyWrapper({
		// 			text: 'Your campaign is missing values for essential fields for sending and templates!',
		// 			type: 'error',
		// 			timeout: 8000
		// 		});
		// 		//- Reset actived
		// 		$('#engage_campaign_active').prop('checked', false);
		// 		//- Switch to tab
		// 		switchToTab('tabsettings');
		// 		return false;
		// 	}
		// }
		//- Show/hide edit
		_engageShowHideEditOption();
		//- Issue save
		engageCampaignAutoSave();

		return false;
	})

	$('#campaignDetailsModal').on('shown.bs.modal', function(e) {

		//- Hide all edit links when we get to the page
		_engageShowHideEditOption();

		var _id = $('.modal.show #engage_campaign_id').val();

		//- When trigger selection changes
		$(document).on('change', '#engage_campaign_auto_trigger_selection', function() {
			//- Get value
			var _v = $(this).val();
			//- If labels show the labels selection
			if ( _v === 'labels' ) {
				//- Show labels
				$('#engage_campaign_auto_trigger_labels_add_div').removeClass('hidden');
				$('#engage_campaign_auto_trigger_labels_remove_div').removeClass('hidden');
				$('#engage_campaign_auto_trigger_labels_alert_div').removeClass('hidden');
			}
			else {
				$('#engage_campaign_auto_trigger_labels_add_div').addClass('hidden');
				$('#engage_campaign_auto_trigger_labels_remove_div').addClass('hidden');
				$('#engage_campaign_auto_trigger_labels_alert_div').addClass('hidden');
			}
		})

		//- Select2
		setTimeout(function() {
			$("#engage_campaign_auto_trigger_labels_add").select2({
				dropdownParent : $('#campaignDetailsModal')
			})
			$("#engage_campaign_auto_trigger_labels_remove").select2({
				dropdownParent : $('#campaignDetailsModal')
			})
		}, 1000)

		setTimeout(function() {
			$("#engage_campaign_from_email").select2({
				placeholder: {
					id: '-1',
					text: ' '
				},
				dropdownParent: $('#campaignDetailsModal')
			});
		}, 1000)

		//- Select2
		var _select2_trigger_labels_add = $("#engage_campaign_auto_trigger_labels_add").select2({
			dropdownParent : $('#campaignDetailsModal')
		})
		//- Select2
		var _select2_trigger_labels_remove = $("#engage_campaign_auto_trigger_labels_remove").select2({
			dropdownParent : $('#campaignDetailsModal')
		})

		//- When any dropdown is changes in automation actions
		$(document).on('change', '.engage-action-item-select', function(e) {
			var _name = $(this).data('name');
			var _id = $(this).data('id');
			var _seq_id = $(this).data('seqid');
			//- Get automation field
			var _auto = _engageAutomationGet(_seq_id);
			//- Get item
			var _item = _auto.find(function(a) { return a._id === _id });
			switch(_name) {
				case 'labels_add':
					_item.labels_add = $('#engage_action_item_labels_add_' + _id).val();
					break;
				case 'labels_remove':
					_item.labels_remove = $('#engage_action_item_labels_remove_' + _id).val();
					break;
				case 'move':
					_item.move_to = $('#engage_action_item_move_' + _id).val();
					break;
				case 'goto':
					_item.goto = $('#engage_action_item_goto_' + _id).val();
					break;
				case 'notify_users':
					_item.notify_users = $('#engage_action_item_notify_users_' + _id).val();
					break;
			}
			//- Update field
			_engageAutomationUpdateField(_seq_id, _item);
		});

		$(document).on('focusout', '.engage-action-item-text', function(e) {
			var _name = $(this).data('name');
			var _id = $(this).data('id');
			var _seq_id = $(this).data('seqid');
			//- Get automation field
			var _auto = _engageAutomationGet(_seq_id);
			//- Get item
			var _item = _auto.find(function(a) { return a._id === _id });
			switch(_name) {
				case 'notify_text':
					_item.notify_text = $('#engage_action_item_notify_text_' + _id).val();
					break;
			}
			//- Update field
			_engageAutomationUpdateField(_seq_id, _item);
		});

	})

})

//- After on ready

function engageCampaignAutoSave() {
	// Get form values
	var _data = formserialize('engage_campaign_auto_detail_form');
	var _from_select = $('#engage_campaign_auto_detail_form #engage_campaign_from_email').select2('data')[0];
	_data += `&from_select_mailbox=${_from_select.id}&from_select_email=${_from_select.text}`
	//- Check if active
	// var _active = $('#engage_campaign_active').is(':checked');
	// If active we should check templage email address and name
	// if (_active) {
	// 	var _name = $('#engage_campaign_from_name').val();
	// 	var _email = $('#engage_campaign_from_email').val();
	// 	// var _template = $('#engage_campaign_template option:selected').val();
	// 	//- If any are empty
	// 	if ( !_name || !_email ) {
	// 		notyWrapper({
	// 			text: 'Your campaign is missing values for essential fields for sending and templates!',
	// 			type: 'error',
	// 			timeout: 8000
	// 		});
	// 		//- Reset actived
	// 		$('#engage_campaign_active').prop('checked', false);
	// 		//- Switch to tab
	// 		switchToTab('tabsettings');
	// 		return false;
	// 	}
	// }
	// Save draft
	$.ajax({
		type: "POST",
		url: '/marketing/automation/update',
		data: _data,
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error saving the campaign',
					type: 'error'
				});
			},
			// Refresh
			200: function(data) {
				// Notification
				notyWrapper({
					text: 'Your campaign has been updated',
					type: 'success'
				});
				//- Load lists
				engageLoadDripCampaign(true);
				// engageLoadDripCampaign(false);
			}
		}
	});
	return false;
}

//- Load list
function engageLoadDripCampaign(active) {
	$.ajax({
		type: "GET",
		url: `/marketing/automation/list/all`,
		statusCode: {
			200: function(data) {
				$(`#dripcampaigns`).html(data);
			}
		}
	});
	// $.ajax({
	// 	type: "GET",
	// 	url: `/marketing/automation/list/${active}`,
	// 	statusCode: {
	// 		200: function(data) {
	// 			$(`#dripcampaigns_${active}`).html(data);
	// 		}
	// 	}
	// });
};

//- If active/inactive is changed
function _engageShowHideEditOption() {
	var campaign_active = $('#engage_campaign_active').is(':checked');
	if (campaign_active) {
		$('.engage-campaign-edit').addClass('hidden');
		$('.drag-handle-div').addClass('hidden');
		$('#engage_campaign_active_desc').removeClass('hidden');
		$('#engage_campaign_drag_desc').addClass('hidden');
		$('.engage_campaign_sequence_item').addClass('disabled-button');
		$('.drip-action-btn').attr('disabled', 'disabled');
	}
	else {
		$('.engage-campaign-edit').removeClass('hidden');
		$('.drag-handle-div').removeClass('hidden');
		$('#engage_campaign_active_desc').addClass('hidden');
		$('#engage_campaign_drag_desc').removeClass('hidden');
		$('.engage_campaign_sequence_item').removeClass('disabled-button');
		$('.drip-action-btn').removeAttr('disabled');
	}
}

function engageSequenceAddStep(action, obj) {
	sequence_new = obj ? false : true;
	obj = obj || {};
	var _id = obj._id ? obj._id : new oid.ObjectId().toHexString();
	var _config_hidden = obj._id ? '' : 'hidden';
	var _config_div_style = obj._id ? 'display:none' : '';
	var _div = '';
	var _div_config = '';
	var _desc = '';
	var _border = '';
	var _data_title = '';
	var _in_marketing = document.location.href.includes('marketing');

	//- Depending on actioengage-campaign-edit n
	switch(action) {
		case 'labels':
			_border = 'border rounded border-success';
			_div = `
			<div class="d-flex align-items-center" data-id="${_id}">
				<span class="fw-bold"><span class="${_fa_style}tag text-success me-2"></span>Label action</span>
				<div class="ms-auto">
					<button class="engage-campaign-edit engage-configure-btn btn btn-icon btn-sm btn-light ${_config_hidden}" type="button" onclick="toggleSlideDiv('#engageSequenceConfigureDiv_${_id}');return false;" id="engageSequenceConfigure_${_id}"><span class="${_fa_style}pen text-gray-600"></span></button>
					<button class="engage-campaign-edit btn btn-icon btn-sm btn-light ms-3" type="button" onclick="deleteRecord('marketing_auto_sequence','${_id}');return false;"><span class="${_fa_style}trash-can text-gray-600"></span></button>
				</div>
			</div>
			<div class="text-muted fs-8" id="engageSequenceText_${_id}">Adding and/or removing labels of the contact</div>
			<div class="p-3" id="engageSequenceConfigureDiv_${_id}" style="display:none;">
				<div class="pt-3">
					<div class="fw-bold mb-2">Add the following labels</div>
					<select id='engageSequenceLabelsAdd_${_id}' class='form-select form-select-sm form-select-solid' multiple='multiple'></select>
				</div>
				<div class="pt-3 mb-3">
					<div class="fw-bold mb-2">Remove the following labels</div>
					<select id='engageSequenceLabelsRemove_${_id}' class='form-select form-select-sm form-select-solid' multiple='multiple'></select>
				</div>
				<div class="d-flex justify-content-end">
					<button class="btn btn-sm btn-light" type="button" id="engageSequenceCancel_${_id}" onclick="toggleSlideDiv('#engageSequenceConfigureDiv_${_id}');">${i18n.__('cancel')}</button>
					<button class="ms-3 btn btn-sm btn-light-info" type="button" onclick="engageSequenceAddStepDone('${_id}', '${action}');">${i18n.__('update')}</button>
				</div>
			</div>
			`;
			break;
		case 'wait':
			_border = 'border rounded border-primary';
			_desc = obj._id ? `...for <span class="fw-bold">${obj.wait_number} ${obj.wait_unit}</span> until the next action` : '';
			var _wait_number = obj._id ? obj.wait_number : 1;
			var _wait_unit = obj._id ? obj.wait_unit : 'days';
			_data_title = `Wait: ${_wait_number} ${_wait_unit}`;
			_div = `
			<div class="d-flex align-items-center" data-id="${_id}">
				<span class="fw-bold"><span class="${_fa_style}snooze text-primary me-2"></span>Wait...</span>
				<div class="ms-auto">
					<button class="engage-campaign-edit engage-configure-btn btn btn-sm btn-light btn-icon ${_config_hidden}" type="button" onclick="toggleSlideDiv('#engageSequenceConfigureDiv_${_id}');return false;" id="engageSequenceConfigure_${_id}"><span class="${_fa_style}pen text-gray-600"></span></button>
					<button class="engage-campaign-edit btn btn-sm btn-light btn-icon ms-3" type="button" onclick="deleteRecord('marketing_auto_sequence', '${_id}');return false;"><span class="${_fa_style}trash-can text-gray-600"></span></button>
				</div>
			</div>
			<div class="text-muted fs-8" id="engageSequenceText_${_id}">${_desc}</div>
			<div class="p-3 pt-6" id="engageSequenceConfigureDiv_${_id}" style="display:none;">
				<div class="fw-bold mb-2">When should the next sequence be triggered?</div>
				<div class="input-group mb-3">
					<span class="input-group-text">Perform the next sequence in</span>
					<input type="number" name="engageSequenceNumber_${_id}" id="engageSequenceNumber_${_id}" class="form-control form-control-solid" min="1" value="${_wait_number}">
					<select name="engageSequenceUnit_${_id}" id="engageSequenceUnit_${_id}" class="form-select form-select-sm form-select-solid">
						<option value="minutes" ${_wait_unit==='minutes'?'selected':''}> Minutes
						<option value="hours" ${_wait_unit==='hours'?'selected':''}> Hours
						<option value="days" ${_wait_unit==='days'?'selected':''}> Days
						<option value="weeks" ${_wait_unit==='weeks'?'selected':''}> Weeks
						<option value="months" ${_wait_unit==='months'?'selected':''}> Months
					</select>
				</div>
				<div class="d-flex justify-content-end">
					<button class="btn btn-sm btn-light" type="button" onclick="toggleSlideDiv('#engageSequenceConfigureDiv_${_id}');">${i18n.__('cancel')}</button>
					<button class="ms-3 btn btn-sm btn-light-info" type="button" onclick="engageSequenceAddStepDone('${_id}', '${action}');">${i18n.__('update')}</button>
				</div>
			</div>
			`;
			break;
		case 'message':
			_border = 'border rounded border-info';
			var _subject = obj.subject ? obj.subject : '';
			var _subject_text = _subject ? `Sending message: <span class="fw-bold">${_subject}</span>` : '';
			var _preheader = obj.preheader ? obj.preheader : '';
			var _message_type = _in_marketing && sequence_new ? 'new' : 'reply';
			_message_type = obj.message_type ? obj.message_type : _message_type;
			var _hidden_for_reply = _message_type === 'reply' ? 'hidden hidden-for-reply' : 'hidden-for-reply';
			var _automation_wait_number = obj.automation_wait_number || 6;
			var _automation_wait_unit = obj.automation_wait_unit || 'hours';
			var _automation_event = obj.automation_event || '0';
			var _automation_action_css = obj.automation && obj.automation.length ? '' : 'hidden';
			_data_title = `${i18n.__('message')}: ${_subject}`;
			_div = `
			<div class="d-flex align-items-center" data-id="${_id}">
				<span class="fw-bold"><span class="${_fa_style}at text-info me-2"></span>Send a message</span>
				<div class="ms-auto">
					<button class="engage-campaign-edit engage-configure-btn btn btn-sm btn-light btn-icon ${_config_hidden}" type="button" onclick="engageSequenceMessageConfigure('${_id}', '${action}');return false;" id="engageSequenceConfigure_${_id}"><span class="${_fa_style}pen text-gray-600"></span></button>
					<button class="engage-campaign-edit btn btn-sm btn-light btn-icon ms-3" type="button" onclick="deleteRecord('marketing_auto_sequence', '${_id}');return false;"><span class="${_fa_style}trash-can text-gray-600"></span></button>
				</div>
			</div>
			<div class="text-muted fs-8" id="engageSequenceText_${_id}">${_subject_text}</div>
			`;
			_div_config = `
			<input type="hidden" id="engage_campaign_automation_${_id}" value="${JSON.stringify(obj.automation)}">
			<div class="p-3 pt-0 hidden" id="engageSequenceConfigureDiv_${_id}">
				<div class="d-flex mb-3 w-100">
					<div class="form-group flex-grow-1 pe-5 ${_hidden_for_reply}">
						<label class="text-gray-600">Subject</label>
						<input type="text" class="form-control form-control-solid form-control-sm" id="engageSequenceSubject_${_id}" value="${_subject}">
					</div>
					<div class="form-group ms-auto">
						<div class="d-flex align-items-center">
							<label class="text-gray-600">Type</label>
							<div class="ms-auto">
								<span class="${_fa_style}question-circle text-muted hint--bottom" data-tippy-content="<p><strong>New thread:</strong> Send this message as a new email.</p><p><strong>Reply to thread:</strong> Send this message as a reply in the same email thread.</p>"></span>
							</div>
						</div>
						<select class="form-select form-select-solid form-select-sm" id="engageSequenceMessageType_${_id}" onchange="engageSequenceAutomationChangeMessageType('${_id}');">
							<option value="new" ${_message_type==='new'?'selected':''}>New thread</option>
							<option value="reply" ${_message_type==='reply'?'selected':''}>Reply to thread</option>
						</select>
					</div>
				</div>
				<div class="form-group mb-5 ${_hidden_for_reply}">
					<label class="text-gray-600">Preview text</label>
					<input type="text" class="form-control form-control-solid form-control-sm" id="engageSequencePreheader_${_id}" value="${_preheader}">
				</div>
				<div class="form-group mb-5">
					<label class="text-gray-600">${i18n.__('message')}</label>
					<textarea class="form-control form-control-solid" id="engageSequenceMessage_${_id}"></textarea>
				</div>
				<div class="my-10 separator border-gray-200"></div>
				<div class="fw-semibold">Automations</div>
				<div class="text-muted mb-5">Select what action to execute depending on the subscribers behavior</div>
				<div class="mb-5 row">
					<div class="col-md-6">
						<div class="mb-3">When the contact...</div>
						<select class="form-select form-select-solid form-select-sm" id="engageSequenceAutomationEvent_${_id}" onchange="engageSequenceAutomationEventSelect('${_id}');">
							<option value="0" ${_automation_event==='0'?'selected':''}></option>
							<option value="open" ${_automation_event==='open'?'selected':''}>Opens my email</option>
							<option value="click" ${_automation_event==='click'?'selected':''}>Clicks on a link</option>
							<option value="no_open" ${_automation_event==='no_open'?'selected':''}>Doesn't open my email</option>
							<option value="no_click" ${_automation_event==='no_click'?'selected':''}>Doesn't click on any links</option>
						</select>
					</div>
					<div class="col-md-6">
						<div id="engageSequenceAutomationEventActionDiv_${_id}" class="${_automation_action_css}">
							<div class="mb-3">Then do this...<span class="text-muted">(Apply one or several action)</span></div>
							<select class="form-select form-select-solid form-select-sm" id="engageSequenceAutomationEventAction_${_id}" onchange="engageSequenceAutomationEventActionSelect('${_id}');">
								<option value="0" selected></option>
								<option value="labels">Add/remove labels</option>
								<option value="send_again">Re-send this message</option>
								<option value="goto">Go to another sequence</option>
								<option value="remove">Remove contact from this campaign</option>
								<option value="pause">Pause contact</option>
								<option value="unpause">Unpause contact</option>
								<option value="complete">Mark contact as campaign completed</option>
								<option value="move">Move contact to another campaign</option>
								<option value="notify">Send notification</option>
							</select>
						</div>
					</div>
				</div>
				<div id="engageSequenceAutomationEventActionShow_${_id}"></div>
				<div id="engageSequenceAutomationEventActionTime_${_id}" class="hidden pt-3">
					<div class="">How long to wait until triggering the above actions</div>
					<div class="input-group">
						<span class="input-group-text">Action triggers...</span>
						<input type="number" name="engage_automation_wait_number_${_id}" id="engage_automation_wait_number_${_id}" class="form-control form-control-sm form-control-solid col-md-2" min="1" value="${_automation_wait_number}">
						<select name="engage_automation_wait_unit_${_id}" id="engage_automation_wait_unit_${_id}" class="form-select form-select-solid form-select-sm col-md-2">
							<option value="minutes" ${_automation_wait_unit==='minutes'?'selected':''}> Minutes
							<option value="hours" ${_automation_wait_unit==='hours'?'selected':''}> Hours
							<option value="days" ${_automation_wait_unit==='days'?'selected':''}> Days
							<option value="weeks" ${_automation_wait_unit==='weeks'?'selected':''}> Weeks
							<option value="months" ${_automation_wait_unit==='months'?'selected':''}> Months
						</select>
					</div>
					<div class="mb-3 mt-1">
						<span class="text-muted fst-italic">Applies only for "Doesn't open email" and "Doesn't click links"</span>
					</div>
				</div>
				<div class="pt-4 d-flex justify-content-end">
					<button class="btn btn-sm btn-light" type="button" onclick="engageSequenceMessageConfigureCancel('${_id}');">${i18n.__('cancel')}</button>
					<button class="btn btn-sm btn-light-info ms-3" type="button" onclick="engageSequenceAddStepDone('${_id}', '${action}');">${i18n.__('update')}</button>
				</div>
			</div>`;
			break;
	}

	var _html = `<div class="engage_campaign_sequence_item d-flex mb-3 p-5 ${_border}" id="engageSequenceDiv_${_id}" data-id="${_id}" data-action="${action}" data-title="${_data_title}">
		<div class="flex-grow-1">
			${_div}
		</div>
		<div class="ms-auto">
			<div class="drag-handle-div">
				<span class="${_fa_style}grip-vertical ps-6 pe-3 pt-2 drag-handle text-gray-600"></span>
			</div>
		</div>
	</div>`;


	var _html_slider = _div_config;

	//- Append Sequences
	$('#engageCampaignAppendSequence').append(_html);

	//- Append Slider
	$('#drip_campaign_sequences_message_div').append(_html_slider);

	//- For labels we call select2 to init selects
	if ( action === 'labels' ) {
		_engageAutomationLoadLabels(_id, obj, 'engageSequenceLabelsAdd_' + _id, 'engageSequenceLabelsRemove_' + _id, 'sequence');
	}

	//- Load editor
	if ( action === 'message' ) {
		//- FroalaEditor
		loadEditor('engageSequenceMessage_' + _id, 'engage_campaign', { 'emailchain_id' : _id, 'zindex' : 99999, 'theme' : 'royal', 'load_content' : obj.message ? obj.message : null, 'is_master' : $('#engage_is_master').val() });
		//- Add automation
		$.each(obj.automation, function(i, v) {
			engageSequenceAutomationEventActionSelect(_id, v);
		})
		// initTooltips();
	}

	//- If new open slider for editing
	if (sequence_new) {
		switch(action) {
			case 'message':
				engageSequenceMessageConfigure(_id, action);
				break;
			case 'labels':
			case 'wait':
				toggleSlideDiv(`#engageSequenceConfigureDiv_${_id}`);
				break;
		}
	}

}

function engageSequenceMessageConfigure(id, action) {
	//- Hide sequences div
	$('.message-hide-div').addClass('hidden');
	//- Show messages div
	$('#drip_campaign_sequences_message_div').removeClass('hidden');
	//- Show explicit message div
	$('#engageSequenceConfigureDiv_' + id).removeClass('hidden');
}
function engageSequenceMessageConfigureCancel(id) {
	//- Hide sequences div
	$('.message-hide-div').removeClass('hidden');
	//- Show messages div
	$('#drip_campaign_sequences_message_div').addClass('hidden');
	//- Show explicit message div
	$('#engageSequenceConfigureDiv_' + id).addClass('hidden');
}

function engageSequenceCancelStepConfigure(id) {
	//- Open div
	//- toggleSlideDiv('#engageSequenceConfigureDiv_' + id);
	//- Get action value
	var _event_action = $('#engageSequenceAutomationEventAction_' + id).val();
	//- If action value is 0
	if (_event_action === '0') {
		$('#engageSequenceAutomationEvent_' + id).val(0);
		$('#engageSequenceAutomationEventAction_' + id).val(0);
		$('#engageSequenceAutomationEventActionDiv_' + id).addClass('hidden');
	}
	engageOpenSequenceSlider(id, true);
	//- Show configure
	$('.engage-configure-btn').removeClass('hidden');
	//- For new remove
	if (sequence_new) {
		engageRemoveSequenceNew(id);
		return;
	}
	$('#engageSequenceConfigureDiv_' + id).addClass('hidden');
	//- $('#engageSequenceConfigure_' + id).removeClass('hidden');
}

function engageSequenceAddStepDone(id, action) {
	sequence_new = false;
	var _wait_number;
	var _wait_unit;
	var _labels_add = [];
	var _labels_remove = [];
	var _campaign_id = $('#engage_campaign_id').val();
	var _subject = '';
	var _preheader = '';
	var _message = '';
	var _automation_wait_number;
	var _automation_wait_unit;
	var _automation_event;
	var _automation;
	var _message_type = 'new';
	//- Show edit and cancel button in case label hide them
	$(`#engageSequenceConfigure_${id}, #engageSequenceCancel_${id}`).removeClass('hidden');
	//- WAIT
	if (action === 'wait') {
		_wait_number = $('#engageSequenceNumber_' + id).val();
		_wait_unit = $('#engageSequenceUnit_' + id + ' option:selected').val();
		$('#engageSequenceText_' + id).html(`...for <span class="fw-bold">${_wait_number} ${_wait_unit}</span> until the next action`);
		$('#engageSequenceDiv_' + id).attr('data-title', `Wait: ${_wait_number} ${_wait_unit}`);
		//- Close div
		toggleSlideDiv('#engageSequenceConfigureDiv_' + id);
	}
	//- LABELS
	if (action === 'labels') {
		_labels_add = $('#engageSequenceLabelsAdd_' + id).select2('data');
		_labels_remove = $('#engageSequenceLabelsRemove_' + id).select2('data');
		if (!_labels_add.length && !_labels_remove.length) {
			notyWrapper({
				text: 'Please select at least one label or remove the sequence altogether',
				type: 'error',
				timeout: 8000
			});
			//- Hide edit and cancel button
			$(`#engageSequenceConfigure_${id}, #engageSequenceCancel_${id}`).addClass('hidden');
			return false;
		}
		//- Close div
		toggleSlideDiv('#engageSequenceConfigureDiv_' + id);
	}
	//- MESSAGE
	if (action === 'message') {
		_subject = $('#engageSequenceSubject_' + id).val();
		_preheader = $('#engageSequencePreheader_' + id).val();
		_message_type = $('#engageSequenceMessageType_' + id + ' option:selected').val();
		_message = $('#engageSequenceMessage_' + id).val();
		_automation_wait_number = $('#engage_automation_wait_number_' + id).val();
		_automation_wait_unit = $('#engage_automation_wait_unit_' + id + ' option:selected').val();
		_automation_event = $('#engageSequenceAutomationEvent_' + id + ' option:selected').val();
		_automation = $('#engage_campaign_automation_' + id).val();
		$('#engageSequenceText_' + id).html(`Sending message: <span class="fw-bold">${_subject}</span>`);
		$('#engageSequenceDiv_' + id).attr('data-title', `Message: ${_subject}`);
		//- Close div
		engageSequenceMessageConfigureCancel(id);
	}
	//- $('#engageSequenceConfigureDiv_' + id).addClass('hidden');
	//- Show configure
	$('.engage-configure-btn').removeClass('hidden');
	//- $('#engageSequenceConfigure_' + id).removeClass('hidden');
	//- Submit
	$.ajax({
		type: "POST",
		url: '/marketing/automation/update_sequence',
		data: { campaign_id: _campaign_id, id: id, action: action, wait_number: _wait_number, wait_unit: _wait_unit, labels_add: JSON.stringify(_labels_add), labels_remove: JSON.stringify(_labels_remove), message: _message, subject: _subject, preheader: _preheader, message_type: _message_type, automation_wait_number: _automation_wait_number, automation_wait_unit: _automation_wait_unit, automation_event: _automation_event, automation : _automation },
		statusCode: {
			500: function(data) {
				notyWrapper({
					text: 'An error occured saving your changes',
					type: 'error',
					timeout: 8000
				});
			},
			200: function(data) {

			}
		}
	});
}

function engageSequenceTriggerUpdate() {
	var _trigger = $('#engage_campaign_auto_trigger_selection option:selected').val();
	var _labels_add = $('#engage_campaign_auto_trigger_labels_add').select2('data');
	var _labels_remove = $('#engage_campaign_auto_trigger_labels_remove').select2('data');
	var _campaign_id = $('#engage_campaign_id').val();
	if (_trigger === 'labels' && !_labels_add.length && !_labels_remove.length) {
		notyWrapper({
			text: 'You forgot to select labels',
			type: 'warning',
		});
		return false;
	}
	//- Close div
	toggleSlideDiv('#engage_campaign_auto_trigger');
	//- Update text
	var _trigger_text = _trigger === 'subscribe' ? '<span class="fw-bold">is being added to this campaign</span>' : '<span class="fw-bold">has labels added or removed</span>';
	$('#engage_campaign_auto_trigger_text').html(_trigger_text);
	//- Submit
	$.ajax({
		type: "POST",
		url: '/marketing/automation/update_trigger',
		data : { campaign_id: _campaign_id, labels_add: JSON.stringify(_labels_add), labels_remove: JSON.stringify(_labels_remove), trigger : _trigger },
		statusCode: {
			500: function(data) {
				notyWrapper({
					text: 'An error occured saving the trigger for this drip campaign',
					type: 'error',
					timeout: 8000
				});
			},
			200: function(data) {
				//- Load lists
				engageLoadDripCampaign(true);
				// engageLoadDripCampaign(false);
			}
		}
	});
}

///////////////////////////
//- MESSAGE AUTOMATION FUNC
///////////////////////////

function dripCopyCampaign(id) {
	$.ajax({
		type: "GET",
		url: `/marketing/automation/copy_campaign?id=${id}`,
		statusCode: {
			// Done
			200: function() {
				engageLoadDripCampaign();
			}
		}
	});
}

// Change message type
function engageSequenceAutomationChangeMessageType(id) {
	var _message_type = $('#engageSequenceMessageType_' + id + ' option:selected').val();
	//- Hide reply
	if (_message_type === 'reply') {
		$(`#engageSequenceConfigureDiv_${id} .hidden-for-reply`).addClass('hidden');
	}
	else {
		$(`#engageSequenceConfigureDiv_${id} .hidden-for-reply`).removeClass('hidden');
	}
}

//- Event selection
function engageSequenceAutomationEventSelect(id) {
	//- Value
	var _event = $('#engageSequenceAutomationEvent_' + id + ' option:selected').val();
	//- If 0
	if (_event === '0') return;
	//- Reset selection
	//- $('#engageSequenceAutomationEvent_' + id).val(0);
	//- Show actions
	$('#engageSequenceAutomationEventActionDiv_' + id).removeClass('hidden');
}
//- Event actions
function engageSequenceAutomationEventActionSelect(id, obj) {
	//- Existing
	obj = obj || { _id : new oid.ObjectId().toHexString() };
	var _action_id = obj._id;
	//- Value
	var _action = obj.action ? obj.action : $('#engageSequenceAutomationEventAction_' + id + ' option:selected').val();
	//- If 0
	if (_action === '0') return;
	//- Reset selection
	$('#engageSequenceAutomationEventAction_' + id).val(0);
	//- Var
	var _header = '';
	var _text = '';
	var _html_action = '';
	//- If automation is empty
	if (!obj.action) {
		obj = { '_id' : _action_id, 'action' : _action };
	}
	//- Add
	switch(_action) {
		//- Labels
		case 'labels':
			_header = `You are <span class="font-weight-bold">modifying</span> the labels of the contact to`;
			_html_action = `
			<div class="pt-3">
				<div class="">Add the following labels</div>
				<select id='engage_action_item_labels_add_${_action_id}' class='form-select form-select-solid form-select-sm engage-action-item-select' multiple='multiple' data-name="labels_add" data-id="${_action_id}" data-seqid="${id}"></select>
			</div>
			<div class="pt-3">
				<div class="">Remove the following labels</div>
				<select id='engage_action_item_labels_remove_${_action_id}' class='form-select form-select-solid form-select-sm engage-action-item-select' multiple='multiple' data-name="labels_remove" data-id="${_action_id}" data-seqid="${id}"></select>
			</div>
			`;
			//- Disable action selection
			$('#engageSequenceAutomationEventAction_' + id).find('[value=labels]').prop('disabled', true);
			break;
		//- Move to another campaign
		case 'move':
			_header = `You are <span class="font-weight-bold">moving</span> the contact to another campaign`;
			_html_action = `
			<div class="pt-3">
				<select name="engage_action_item_move_${_action_id}" id="engage_action_item_move_${_action_id}" class="form-select form-select-solid form-select-sm engage-action-item-select" data-name="move" data-id="${_action_id}" data-seqid="${id}">
					<option value="-1"></option>
				</select>
			</div>
			`;
			//- Disable action selection
			$('#engageSequenceAutomationEventAction_' + id).find('[value=move]').prop('disabled', true);
			break;
		//- Send again
		case 'send_again':
			_header = `You are <span class="font-weight-bold">re-sending</span> this message`;
			_html_action = ``;
			//- Disable action selection
			$('#engageSequenceAutomationEventAction_' + id).find('[value=send_again]').prop('disabled', true);
			//- $('#engageSequenceAutomationEventAction_' + id).find('[value=send_new]').prop('disabled', true);
			$('#engageSequenceAutomationEventAction_' + id).find('[value=goto]').prop('disabled', true);
			break;
		//- GoTo sequence
		case 'goto':
			_header = `<span class="font-weight-bold">Go to</span> another sequence`;
			_html_action = `
			<div class="pt-3">
				<select name="engage_action_item_goto_${_action_id}" id="engage_action_item_goto_${_action_id}" class="form-select form-select-sm form-select-solid engage-action-item-select" data-name="goto" data-id="${_action_id}" data-seqid="${id}">
					<option value="-1"></option>
				</select>
			</div>
			`;
			//- Disable action selection
			$('#engageSequenceAutomationEventAction_' + id).find('[value=goto]').prop('disabled', true);
			break;
		//- Notify
		case 'notify':
			_text = obj.notify_text || '';
			_header = `Send a <span class="font-weight-bold">notification</span>`;
			_html_action = `
			<div class="pt-3">
				<div class="">These users receive the notification...</div>
				<select name="engage_action_item_notify_users_${_action_id}" id="engage_action_item_notify_users_${_action_id}" multiple='multiple' class="form-select form-select-sm form-select-solid engage-action-item-select" data-name="notify_users" data-id="${_action_id}" data-seqid="${id}"></select>
			</div>
			<div class="pt-3">
				<div class="">... with the following message</div>
				<textarea name="engage_action_item_notify_text_${_action_id}" id="engage_action_item_notify_text_${_action_id}" class="form-control form-control-solid engage-action-item-text" data-name="notify_text" data-id="${_action_id}" data-seqid="${id}" style="height:100px;">${_text}</textarea>
			</div>
			`;
			//- Disable action selection
			$('#engageSequenceAutomationEventAction_' + id).find('[value=notify]').prop('disabled', true);
			break;
		//- Remove
		case 'remove':
			_header = `You are <span class="font-weight-bold">removing</span> the contact from this campaign`;
			$('#engageSequenceAutomationEventAction_' + id).find('[value=remove]').prop('disabled', true);
			break;
		//- Pause
		case 'pause':
			_header = `You are <span class="font-weight-bold">pausing</span> the campaign for the contact`;
			$('#engageSequenceAutomationEventAction_' + id).find('[value=pause]').prop('disabled', true);
			break;
		//- Unpause
		case 'unpause':
			_header = `You are <span class="font-weight-bold">unpausing</span> the campaign for the contact`;
			$('#engageSequenceAutomationEventAction_' + id).find('[value=unpause]').prop('disabled', true);
			break;
		//- Complete
		case 'complete':
			_header = `You are marking the contact to have <span class="font-weight-bold">completed</span> the campaign`;
			$('#engageSequenceAutomationEventAction_' + id).find('[value=complete]').prop('disabled', true);
			break;
	}
	//- HTML
	var _html = `
	<div class="card border bordered mb-3 engage-automation-card" id="engage_automation_card_${_action_id}">
		<div class="card-body">
			<div class="d-flex">
				<span class="fw-bold">${_header}</span>
				<div class="ms-auto">
					<a href="#" onclick="engageAutomationItemRemove('${id}','${_action_id}','${_action}');return false;">Remove action</a>
				</div>
			</div>
			${_html_action}
		</div>
	</div>
	`;
	//- Show
	$('#engageSequenceAutomationEventActionShow_' + id).append(_html);
	//- Show wait time
	$('#engageSequenceAutomationEventActionTime_' + id).removeClass('hidden');
	//- Add automation array
	_engageAutomationUpdateField(id, obj);
	//- For labels we call select2 to init selects
	if ( _action === 'labels' ) {
		_engageAutomationLoadLabels(_action_id, obj, 'engage_action_item_labels_add_' + _action_id, 'engage_action_item_labels_remove_' + _action_id);
	}
	//- For more to another campaign
	if ( _action === 'move' ) {
		var _campaigns = $('#engage_campaigns_auto').val();
		_campaigns = JSON.parse(_campaigns);
		if ( obj.move_to ) {
			_campaigns = _campaigns.map(l => {
				l.selected = obj.move_to.toString() === l.id.toString();
				return l;
			})
		}
		setTimeout(function() {
			$('#engage_action_item_move_' + _action_id).select2({
				data: _campaigns,
				dropdownParent: $('#campaignDetailsModal'),
				placeholder: {
					id: '-1',
					text: 'Move to campaign...'
				}
			});
		}, 1000);
	}
	//- Goto
	if ( _action === 'goto' ) {
		var _sequences = $('#engage_campaign_sequences').val();
		_sequences = JSON.parse(_sequences);
		_sequences = _sequences.filter(s => s._id !== id).map(s => {
			var _text = s.action.toUpperCase();
			_text = s.action === 'message' ? `${_text} (${s.subject})` : _text;
			return { id : s._id, text : `${s.order}: ${_text}` }
		});
		if ( obj.goto ) {
			_sequences = _sequences.map(l => {
				l.selected = obj.goto.toString() === l.id.toString();
				return l;
			})
		}
		setTimeout(function() {
			$('#engage_action_item_goto_' + _action_id).select2({
				data: _sequences,
				dropdownParent: $('#campaignDetailsModal'),
				placeholder: {
					id: '-1',
					text: 'Goto to sequence...'
				}
			});
		}, 1000);
	}
	//- Notify
	if ( _action === 'notify' ) {
		var _users = $('#engage_account_users').val();
		_users = JSON.parse(_users);
		if ( obj.notify_users ) {
			_users = _users.map(l => {
				l.selected = obj.notify_users.includes( l.id.toString() );
				return l;
			})
		}
		setTimeout(function() {
			$('#engage_action_item_notify_users_' + _action_id).select2({
				data: _users,
				dropdownParent: $('#campaignDetailsModal')
			});
		}, 1000);
	}
}

function engageAutomationItemRemove(sequence_id, id, action) {
	//- Remove
	$('#engage_automation_card_' + id).remove();
	//- Update automation array
	_engageAutomationUpdate(sequence_id, id, 'remove');
	//- Enable selection
	switch(action) {
		//- Labels
		case 'labels':
			$('#engageSequenceAutomationEventAction_' + sequence_id).find('[value=labels]').prop('disabled', false);
			break;
		//- Move
		case 'move':
			$('#engageSequenceAutomationEventAction_' + sequence_id).find('[value=move]').prop('disabled', false);
			break;
		//- Goto
		case 'goto':
			$('#engageSequenceAutomationEventAction_' + sequence_id).find('[value=goto]').prop('disabled', false);
			break;
		//- Notify
		case 'notify':
			$('#engageSequenceAutomationEventAction_' + sequence_id).find('[value=notify]').prop('disabled', false);
			break;
		//- Send again
		case 'send_again':
			$('#engageSequenceAutomationEventAction_' + sequence_id).find('[value=send_again]').prop('disabled', false);
			//- $('#engageSequenceAutomationEventAction_' + sequence_id).find('[value=send_new]').prop('disabled', false);
			$('#engageSequenceAutomationEventAction_' + sequence_id).find('[value=goto]').prop('disabled', false);
			break;
		//- Remove
		case 'remove':
			$('#engageSequenceAutomationEventAction_' + sequence_id).find('[value=remove]').prop('disabled', false);
			break;
		//- Pause
		case 'pause':
			$('#engageSequenceAutomationEventAction_' + sequence_id).find('[value=pause]').prop('disabled', false);
			break;
		//- Unpause
		case 'unpause':
			$('#engageSequenceAutomationEventAction_' + sequence_id).find('[value=unpause]').prop('disabled', false);
			break;
		//- Complete
		case 'complete':
			$('#engageSequenceAutomationEventAction_' + sequence_id).find('[value=complete]').prop('disabled', false);
			break;
	}
	//- If no more card we can also hide the time and desc
	var _cards = $('.engage-automation-card').length;
	if (!_cards) {
		$('#engageSequenceAutomationEvent_' + sequence_id).val(0);
		$('#engageSequenceAutomationEventActionDiv_' + sequence_id).addClass('hidden');
		$('#engageSequenceAutomationEventActionTime_' + sequence_id).addClass('hidden');
	}
}

//- Get automation object
function _engageAutomationGet(id) {
	//- Get value
	var _auto = $('#engage_campaign_automation_' + id).val();
	//- Parse
	try {
		_auto = JSON.parse(_auto);
	}
	catch(e) {
		_auto = [];
	}
	return _auto;
};

//- automation object update field
function _engageAutomationUpdateField(id, obj) {
	//- Get existing field
	var _auto = _engageAutomationGet(id);
	//- Remove existing
	_auto = _auto.filter(function(a) { return a._id !== obj._id });
	//- Push to existing
	_auto.push(obj)
	//- Write back to field
	$('#engage_campaign_automation_' + id).val( JSON.stringify(_auto) );
};

//- Update automation object for hidden field
function _engageAutomationUpdate(sequence_id, id, action) {
	//- Get field
	var _auto = _engageAutomationGet(sequence_id);
	//- According to action
	switch(action) {
		case 'remove':
			_auto = _auto.filter(function(a) { return a._id !== id });
			break;
	}
	//- Write back to field
	$('#engage_campaign_automation_' + sequence_id).val( JSON.stringify(_auto) );
}

//- Load select label
function _engageAutomationLoadLabels(id, obj, select_add_id, select_remove_id, from) {
	// from = from || '';
	var _labels_add = $('#engage_campaign_company_user_labels').val();
	var _labels_remove = $('#engage_campaign_company_user_labels').val();
	_labels_add = JSON.parse(_labels_add);
	_labels_remove = JSON.parse(_labels_remove);
	//- If we got labels mark them as selected
	if ( obj.labels_add && obj.labels_add.length ) {
		_labels_add = _labels_add.map(l => {
			if (!l.id) return l;
			l.selected = obj.labels_add.includes( l.id.toString() );
			return l;
		})
	}
	if ( obj.labels_remove && obj.labels_remove.length ) {
		_labels_remove = _labels_remove.map(l => {
			if (!l.id) return l;
			l.selected = obj.labels_remove.includes( l.id.toString() );
			return l;
		})
	}
	setTimeout(function() {
		$('#' + select_add_id).select2({
			data: _labels_add,
			dropdownParent: $('#campaignDetailsModal')
		});
		$('#' + select_remove_id).select2({
			data: _labels_remove,
			dropdownParent: $('#campaignDetailsModal')
		});
	}, 1000);
}

//- Removing Sequence

//- Subfunction if there are users on remove and reorder
function _subEngageUsersAffected(from, _id, _action) {
	//- Select selectpicker
	//- $('#engage_campaign_auto_users_affected_next').selectpicker('destroy');
	$('#engage_campaign_auto_users_affected_next').html('');
	//- Get all the sequences
	$('.engage_campaign_sequence_item').each(function() {
		var _saction = $(this).data('action');
		var _sid = $(this).data('id');
		var _stitle = $(this).data('title');
		if ( from === 'move' && _sid !== _id ) {
			$('#engage_campaign_auto_users_affected_next').append( $('<option>', { value : _sid, text : _stitle } ) );
		}
		else if (_saction !== 'labels' && _sid !== _id) {
			$('#engage_campaign_auto_users_affected_next').append( $('<option>', { value : _sid, text : _stitle } ) );
		}
	});
	//- $('#engage_campaign_auto_users_affected_next').selectpicker();
	//- $('#engage_campaign_auto_users_affected_next').selectpicker('refresh');
	//- Set stuff
	$('#engage_sequence_to_remove').val(_id);
	$('#engage_sequence_to_remove_action').val(_action);
	$('#engage_sequence_delete').modal('hide');
	$('#engage_campaign_auto_users_affected').removeClass('hidden');
	//- Different click action
	if (from === 'remove') {
		$('#engage_campaign_auto_users_affected_submit').attr('onclick', 'engageSequenceDeleteWithAction()');
	}
	else {
		$('#engage_campaign_auto_users_affected_submit').attr('onclick', 'engageSequenceMoveWithAction()');
	}
	$('.modal-body').animate({ scrollTop: 0 }, 1000);
}

function engageSequenceDeleteWithAction() {
	var id = $('#engage_sequence_to_remove').val();
	var action = $('#engage_sequence_to_remove_action').val();
	var campaign_id = $('#engage_campaign_id').val();
	var next = $('#engage_campaign_auto_users_affected_next').val();
	//- Change actions for users
	$.ajax({
		type: "POST",
		url: '/marketing/automation/remove_sequence_users_next_action',
		data: { id, action, campaign_id, next },
		statusCode: {
			// Error
			500: function(data) {
				console.log('Error auto_user_next_action');
			},
			// Done
			200: function(data) {
				//- Hide div
				$('#engage_campaign_auto_users_affected').addClass('hidden');
				//- Now delete sequence
				removeAutoSequence(id, false);
			},
		}
	});
}

function engageSequenceMoveWithAction() {
	var id = $('#engage_sequence_to_remove').val();
	var action = $('#engage_sequence_to_remove_action').val();
	var campaign_id = $('#engage_campaign_id').val();
	var next = $('#engage_campaign_auto_users_affected_next').val();
	//- Change actions for users
	$.ajax({
		type: "POST",
		url: '/marketing/automation/remove_sequence_users_next_action',
		data: { id, action, campaign_id, next },
		statusCode: {
			// Error
			500: function(data) {
				console.log('Error auto_user_next_action');
			},
			// Done
			200: function(data) {
				//- Hide div
				$('#engage_campaign_auto_users_affected').addClass('hidden');
				//- Save sort order
				dripUpdateSortSequences(false, id, action);
			},
		}
	});
}

function marketingUpdateAutoTemplateSequences() {
	//- Change actions for users
	$.ajax({
		type: "GET",
		url: '/marketing/automation/update_default_sequences',
		statusCode: {
			// Done
			200: function(data) {
				notyWrapper({
					text: 'Default sequences updates',
					type: 'success'
				});
			},
		}
	});
}

function dripUpdateSortSequences(user_check, sequence_id, action) {
	//- sequences
	var sequences = [];
	//- Get all the items
	//- Somehow dragula keep the dragged div around two times hence check if id exists in array before pushing
	$('.sequences_sortable .engage_campaign_sequence_item').each(function(i, v) {
		var _ids = $(this).data('id');
		if ( _ids && !sequences.includes(_ids) ) sequences.push( _ids );
	});
	//- If no groups no need to update
	if (sequences.length) {
		var campaign_id = $('#engage_campaign_id').val();
		$.ajax({
			type: "POST",
			url: '/marketing/automation/order_sequences',
			data : { sequences, campaign_id, user_check, sequence_id, action },
			statusCode: {
				// Error
				500: function(data) {
					console.log('ERROR starring mailbox');
				},
				// Done
				200: function(data) {

				}
			}
		});
	}
}

function editAutoTemplate(id) {
	var template_id = $('#engage_campaign_template option:selected').val();
	$.ajax({
		type: "POST",
		url: '/marketing/automation/template/edit',
		data : { id, template_id },
		statusCode: {
			// Done
			200: function(data) {
				//- Hide settings div
				$('#drip_campaign_settings_div').addClass('hidden');
				//- Show edit template div
				$('#drip_campaign_edit_template_div').removeClass('hidden');
				//- Load edit into div
				$('#drip_campaign_edit_template_div').html(data);
			}
		}
	});
}

function engageAutomationTemplateCancel() {
	//- Hide settings div
	$('#drip_campaign_settings_div').removeClass('hidden');
	//- Show edit template div
	$('#drip_campaign_edit_template_div').addClass('hidden').html('');
}

function engageAutomationTemplateUpdate(notify) {
	//- Notify here is just closing
	notify = notify || false;
	var id = $('#engage_auto_id').val();
	var body = $('#engage_auto_template_editor_html').val();
	var body_editor = $('#engage_auto_template_editor_body').val();
	$.ajax({
		type: "POST",
		url: '/marketing/automation/template/update',
		data : { id, body, body_editor },
		statusCode: {
			// Done
			200: function(data) {
				if (notify) engageAutomationTemplateCancel();
			}
		}
	});
}



//
// LABELS
//

var _log_label = false;

// Save is_folder setting
function setShowHide(id, isFolder) {
	// Call to update
	$.get('/settings/labels/' + id + '/' + isFolder);
}

// Edit label name in list
function label_name_edit(id) {
	// Hide the normal text
	$('#name_span_' + id).addClass('hidden');
	// $('#color_span_' + id).addClass('hidden');
	// Show input field
	$('#name_input_' + id).removeClass('hidden');
	// Focus text field
	$('#label_name_input_' + id).focus();
	// Dedect enter key and issue save for label name
	$('#label_name_input_' + id).on('focusout keydown', function(event) {
		if (event.type == "focusout" || event.keyCode == 13) {
			if (event.keyCode == 13) {
				$(this).unbind('focusout');
			}
			label_name_update(id);
		}
	});
}
// Label Name Update
function label_name_update(id) {
	// Grab the value from the text field
	var labelname = $('#label_name_input_' + id).val();
	// Grab the value from the span field
	var labelnameold = $('#name_span_' + id).text();
	// If labelname is empty replace value with old one
	if (labelname === '') {
		labelname = labelnameold;
		// Hide the text field
		$('#label_name_input_' + id).val(labelname);
	}
	// Hide the text field
	$('#name_input_' + id).addClass('hidden');
	// Enable span field again, but add new value
	$('#name_span_' + id).removeClass('hidden').text(labelname);
	// $('#color_span_' + id).removeClass('hidden');
	// Only save if the value has changed
	if (labelname !== labelnameold) {
		// Finally save it to db
		$.post('/settings/labels_name_update', {
			id: id,
			name: labelname,
			kind: $('#label_kind').val()
		}, function(e) {
			// Noty
			notyWrapper({
				text: i18n.__('label_name_update'),
				type: 'success'
			});
		});
	}
}

// Save Label from administration
function saveLabel() {
	// Check if name is defined
	var _name = $('#namelabel').val();
	if (_name === '') {
		$('#myModal-statusloading').addClass('hide');
		return false;
	}
	// Submit
	$('#form_edit_label').submit();
}

// Save labels from frontend
function setNewLabel() {
	// Get is_detail
	var _is_detail_string = $('#form_edit_label #is_detail').val();
	// Since above is a string covert to boolean
	var _is_detail = _is_detail_string === 'true' ? true : false;
	// Get label value
	var _label = $('#form_edit_label #namelabel').val();
	// If empty
	if (_label === '') {
		$('#newLabelModal-statusloading').addClass('hide');
		return false;
	}
	// Get form data
	var _data = formserialize("form_edit_label");
	// Kind
	var _kind = $('#label_kind').val();
	// Tag on kind
	_data += '&kind=' + _kind;
	// Post to API
	$.ajax({
		type: "POST",
		url: '/settings/labels_edit_update',
		data: _data,
		statusCode: {
			// Error
			500: function() {
				notyWrapper({
					text: 'A communication error has occured. Please try again at a later time or contact us at support@helpmonks.com',
					type: 'error',
					timeout: false
				});
				return false;
			},
			// Domain does NOT exists
			200: function(data) {
				// Close window
				$('#newLabelModal').modal('hide');
				// If kind
				if (_kind === 'company_users') {
					return _loadCompaniesLabels();
				}

				// Show noty
				notyWrapper({
					text: 'The label has been saved and is now available',
					type: 'success'
				});

				// Why is there a refresh?

				// // Check if this is from the listing page
				// var _list = $('#list_view_type');
				// // If we are on a list page
				// if (_list.length) {
				// 	// Grab values to reload page
				// 	var _mb = $('#mailbox_id').val();
				// 	var _label = $('#hidden_label_id').val();
				// 	// Depending on list type the URL is different
				// 	location.href = '/label/' + _mb + '/' + _label;
				// } else {
				// }
			}
		}
	});
}



function labelsearch() {
	// Add a delay until we search
	setTimeout(function() {
		// Grab what he is searching for
		var searchfor = $('#labelsearch').val();
		// console.log(searchfor);
	}, 1000);
}



// Remove Label
function removeLabelFromMessage(messageid, labelid, labelname) {
	// Mailbox id
	var sectionid = $('#section_id_message').val();
	// id and lid
	var _the_id = messageid + '-' + labelid;
	// label name
	var _label_name = labelname;
	// Simply hide the label
	$('.' + _the_id).addClass('hidden');
	// $('#' + _the_id + '-pad').addClass('hidden');
	// Call DB
	$.ajax({
		type: "POST",
		url: '/message/remove_labels',
		data: {
			messageid: messageid,
			labelid: labelid,
			label_name: _label_name,
			status: _getGlobalHiddenStatus(),
			sectionid,
			options: parseJSURL()
		},
		statusCode: {
			200: function(data) {

			},
			500: function() {
				console.log('Error while removing label');
			}
		}
	});
}

// This is called when user clicks on label
// function loadLabelQuery(the_url) {
// 	document.location.href = the_url;
// }

// function loadLabelsAutoComplete(mtf) {
// 	// mtf
// 	mtf = typeof mtf !== 'undefined' ? mtf : '';
// 	// Check if this is a search
// 	var _is_search_for_here = _isSearch();
// 	var _mailbox_id = _getMailboxId();
// 	// Do the autocomplete
// 	$( "#labelsAutoComplete" + mtf ).autocomplete({
// 		source: '/autocomplete_labels?is_search=' + _is_search_for_here + '&mtf=' + mtf,
// 		minLength: 2,
// 		focus: function( event, ui ) {
// 			$( "#labelsAutoComplete" + mtf ).val( ui.item.name );
// 			return false;
// 		},
// 		select: function( event, ui ) {
// 			// Call subfunction
// 			_sub_label_func(ui.item._id, ui.item.name, ui.item.color, false, _getGlobalHiddenIsMixed(), _mailbox_id, mtf !== '' ? true : false);
// 			// Clear the input field
// 			$( "#labelsAutoComplete" + mtf ).val('');
// 			// Return
// 			return false;
// 		},
// 	})
// 	.data( "ui-autocomplete" )._renderItem = function( ul, item ) {
// 		return $( "<li>" )
// 		.append( "<a>" + item.name + "</a>" )
// 		.appendTo( ul );
// 	};
// }

//////////////////////////////////////////////////
// RIGHT SLIDERS
//////////////////////////////////////////////////

//////////////////
// UPGRADE
//////////////////

// Files: Folder Settings
function upgradeSlider() {
	// Capture
	captureEvent({ action: 'slider_upgrade' });
	// Check if open
	_checkSliderRightOpenState('slider').then(d => {
		// Attr
		$(".slider-right").attr({
			"data-kt-drawer-name": "upgrade_slider",
			"data-kt-drawer-toggle": "#toggle_upgrade_slider",
			"data-kt-drawer-width": "{default:'500px'}",
			"id": "upgrade_slider"
		});
		// Get instance
		var _slider = KTDrawer.getInstance(document.querySelector(".slider-right"));
		// Update
		_slider.update();
		// Toggle
		_slider.toggle();
		// Load
		$.ajax({
			type: "GET",
			url: `/slider/upgrade`,
			statusCode: {
				// Error
				500: function (data) {
				},
				// Done
				200: function (data) {
					$('#upgrade_slider').html(data);
					// umami.track('Upgrade slider');
				}
			}
		});
	}).catch(e => {
		return false;
	});
};

//////////////////
// CRM
//////////////////

// Box: Add contacts
function crmBoxSlider(id, stage) {
	// Capture
	captureEvent({ action: 'slider_crm' });
	// Check if open
	_checkSliderRightOpenState().then(d => {
		// Attr
		$(".slider-right").attr({
			"data-kt-drawer-name": "crm_box_slider",
			"data-kt-drawer-toggle": "#toggle_crm_box_slider",
			"data-kt-drawer-width": "{default:'500px'}",
			"id": "crm_box_slider"
		});
		// Get instance
		var _slider = KTDrawer.getInstance(document.querySelector(".slider-right"));
		// Update
		_slider.update();
		// Toggle
		_slider.toggle();
		// Load
		$.ajax({
			type: "GET",
			url: `/crm/box/slider?pipeline=${id}&stage=${stage}`,
			statusCode: {
				// Error
				500: function (data) {
				},
				// Done
				200: function (data) {
					$('#crm_box_slider').html(data);
				}
			}
		});
	}).catch(e => {
		return false;
	});
};

//////////////////
// FILES
//////////////////

// Files: Extensions
function filesSettingsExtensionsSlider(from, tab) {
	// Capture
	captureEvent({ action: 'razuna_extensions' });
	from = from || null;
	tab = tab || null;
	// Check if open
	_checkSliderRightOpenState(from).then(d => {

		if (from !== 'slider') {
			$(".slider-right").attr({
				"data-kt-drawer-name": "files_settings_extensions_slider",
				"data-kt-drawer-toggle": "#files_settings_extensions_link",
				"data-kt-drawer-width": "{default:'600px'}",
				"id": "files_settings_extensions_slider"
			});
			// Get instance
			var _slider = KTDrawer.getInstance(document.querySelector(".slider-right"));
			// Update
			_slider.update();
			// Toggle
			_slider.toggle();
		}
		// Loader
		_showLoader('#files_settings_extensions_slider');
		//- Get hidden values
		var _fields = getGlobalFilesFields();
		// Load
		$.ajax({
			type: "GET",
			url: `/files/settings/extensions?tab=${tab}&workspace_host_id=${_fields.workspace_host_id}&workspace=${_fields.workspace}`,
			statusCode: {
				// Error
				500: function (data) {
				},
				// Done
				200: function (data) {
					$('#files_settings_extensions_slider').html(data);
					// umami.track('Extensions slider');
				}
			}
		});
	}).catch(e => {
		return false;
	});
};

// Files: Users & Teams
function filesUsersTeamsSlider(from, workspace, workspace_host_id) {
	// Check if open
	// _checkSliderLeftOpenState().then(d => {

		// Capture
		captureEvent({ action: 'razuna_users_teams' });

		// Loader
		_showLoader('#files_users_teams_slider');

		from = from || null;

		// Clear html of workspace slider
		$('#files_workspaces_slider').html('');

		$(".slider-left").attr({
			"data-kt-drawer-name": "files_users_teams_slider",
			"data-kt-drawer-toggle": "#files_users_teams_link",
			"data-kt-drawer-width": "{default:'70vw'}",
			"id": "files_users_teams_slider"
		});
		// Get instance
		var _slider = KTDrawer.getInstance(document.querySelector(".slider-left"));
		// Update
		_slider.update();
		// Toggle
		// _slider.toggle();

		// Load
		$.ajax({
			type: "GET",
			url: `/files/settings/users_teams?workspace_host_id=${workspace_host_id}&workspace=${workspace}`,
			statusCode: {
				// Error
				500: function (data) {
				},
				// Done
				200: function (data) {
					$('#files_users_teams_slider').html(data);
					// umami.track('Users - Teams slider');
				}
			}
		});
	// }).catch(e => {
	// 	return false;
	// });
};

// Files: Global Settings
function filesSettingsSlider() {
	// Capture
	captureEvent({ action: 'razuna_global_settings' });
	// Loader
	_showLoader('#files_settings_slider');
	// Check if open
	_checkSliderRightOpenState().then(d => {
		// Attr
		$(".slider-right").attr({
			"data-kt-drawer-name" : "files_settings_slider",
			"data-kt-drawer-toggle" : "#files_settings_link",
			"data-kt-drawer-width" : "{default:'600px'}",
			"id" : "files_settings_slider"
		});
		// Get instance
		var _slider = KTDrawer.getInstance( document.querySelector(".slider-right") );
		// Update
		_slider.update();
		// Toggle
		_slider.toggle();
		//- Get hidden values
		var _fields = getGlobalFilesFields();
		// Load
		$.ajax({
			type: "GET",
			url: `/files/settings?workspace_host_id=${_fields.workspace_host_id}&workspace=${_fields.workspace}`,
			statusCode: {
				// Error
				500: function(data) {
				},
				// Done
				200: function(data) {
					$('#files_settings_slider').html(data);
				}
			}
		});
	}).catch(e => {
		return false;
	});
};

// Files: Tags
function filesTagsSlider(workspace, workspace_host_id, wks, wks_hid) {
	// Check if open
	// _checkSliderRightOpenState().then(d => {
		// Attr
		// $(".slider-right").attr({
		// 	"data-kt-drawer-name": "files_tags_slider",
		// 	"data-kt-drawer-toggle": "#files_tags_link",
		// 	"data-kt-drawer-width": "{default:'600px'}",
		// 	"id": "files_tags_slider"
		// });
		// // Get instance
		// var _slider = KTDrawer.getInstance(document.querySelector(".slider-right"));
		// // Update
		// _slider.update();
		// // Toggle
		// _slider.toggle();
		// Capture
		captureEvent({ action: 'razuna_tags' });
		// Loader
		_showLoader('#files_workspaces_slider');
		// // Load
		$.ajax({
			type: "GET",
			url: `/files/settings/labels_slider?workspace=${workspace}&workspace_host_id=${workspace_host_id}&wks=${wks}&wks_hid=${wks_hid}`,
			statusCode: {
				// Error
				500: function (data) {
				},
				// Done
				200: function (data) {
					$('#files_workspaces_slider').html(data);
					// umami.track('Tags slider');
				}
			}
		});
	// }).catch(e => {
	// 	return false;
	// });
};

// Files: Folder Settings
function filesFolderSlider() {
	// Capture
	captureEvent({ action: 'razuna_folder_setttings' });
	// Loader
	_showLoader('#files_folder_slider');
	// Get folder_id from hidden field
	var _folder_id = $('#folder_id').val();
	// Check if open
	_checkSliderRightOpenState().then(d => {
		// Attr
		$(".slider-right").attr({
			"data-kt-drawer-name" : "files_folder_slider",
			"data-kt-drawer-toggle" : "#files_folder_settings",
			"data-kt-drawer-width" : "{default:'550px'}",
			"id" : "files_folder_slider"
		});
		// Get instance
		var _slider = KTDrawer.getInstance( document.querySelector(".slider-right") );
		// Update
		_slider.update();
		// Toggle
		_slider.toggle();
		//- Get hidden values
		var _fields = getGlobalFilesFields();
		// Load
		$.ajax({
			type: "GET",
			url: `/files/folder/settings/${_folder_id}?workspace_host_id=${_fields.workspace_host_id}&workspace=${_fields.workspace}`,
			statusCode: {
				// Error
				500: function(data) {
				},
				// Done
				200: function(data) {
					$('#files_folder_slider').html(data);
				}
			}
		});
	}).catch(e => {
		return false;
	});
};

// Files: Folder New Settings
function filesFolderNewSlider() {
	// Loader
	_showLoader('#files_folder_new_slider');
	// Get folder_id from hidden field
	var _folder_id = $('#folder_id').val();
	// Check if open
	_checkSliderRightOpenState().then(d => {
		// Attr
		$(".slider-right").attr({
			"data-kt-drawer-name": "files_folder_new_slider",
			"data-kt-drawer-toggle": "#files_folder_new",
			"data-kt-drawer-width": "{default:'550px'}",
			"id": "files_folder_new_slider"
		});
		// Get instance
		var _slider = KTDrawer.getInstance(document.querySelector(".slider-right"));
		// Update
		_slider.update();
		// Toggle
		_slider.toggle();
		//- Get hidden values
		var _fields = getGlobalFilesFields();
		// Load
		$.ajax({
			type: "GET",
			url: `/files/folder/slider/new?parent_id=${_folder_id}&workspace_host_id=${_fields.workspace_host_id}&workspace=${_fields.workspace}`,
			statusCode: {
				// Error
				500: function (data) {
				},
				// Done
				200: function (data) {
					$('#files_folder_new_slider').html(data);
				}
			}
		});
	}).catch(e => {
		return false;
	});
};

// Files: Folder Links
function filesFolderLinksSlider() {
	// Loader
	_showLoader('#files_folder_links_slider');
	// Get folder_id from hidden field
	var _folder_id = $('#folder_id').val();
	// Check if open
	_checkSliderRightOpenState().then(d => {
		// Attr
		$(".slider-right").attr({
			"data-kt-drawer-name" : "files_folder_links_slider",
			"data-kt-drawer-toggle" : "#files_folder_links",
			"data-kt-drawer-width" : "{default:'550px'}",
			"id" : "files_folder_links_slider"
		});
		// Get instance
		var _slider = KTDrawer.getInstance( document.querySelector(".slider-right") );
		// Update
		_slider.update();
		// Toggle
		_slider.toggle();
		//- Get hidden values
		var _fields = getGlobalFilesFields();
		// Load
		$.ajax({
			type: "GET",
			url: `/files/folder/links/${_folder_id}?workspace_host_id=${_fields.workspace_host_id}&workspace=${_fields.workspace}`,
			statusCode: {
				// Error
				500: function(data) {
				},
				// Done
				200: function(data) {
					$('#files_folder_links_slider').html(data);
					// umami.track('Shareable folder links slider');
				}
			}
		});
	}).catch(e => {
		return false;
	});
};

// Files: Patch
function filesPatchSlider(id) {
	// Capture
	captureEvent({ action: 'razuna_batch' });
	// Loader
	_showLoader('#files_patch_slider');
	// Check if open
	_checkSliderRightOpenState().then(d => {
		// Attr
		$(".slider-right").attr({
			"data-kt-drawer-name": "files_patch_slider",
			"data-kt-drawer-toggle": "#files_patch_slider_click",
			"data-kt-drawer-width": "{default:'500px'}",
			"id": "files_patch_slider"
		});
		// Get instance
		var _slider = KTDrawer.getInstance(document.querySelector(".slider-right"));
		// Update
		_slider.update();
		// Toggle
		_slider.toggle();
		//- Get hidden values
		var _fields = getGlobalFilesFields();
		// Load
		$.ajax({
			type: "POST",
			url: `/files/patch/drawer?workspace_host_id=${_fields.workspace_host_id}&workspace=${_fields.workspace}`,
			data: { ids: id },
			statusCode: {
				// Error
				500: function (data) {
				},
				// Done
				200: function (data) {
					$('#files_patch_slider').html(data);
				}
			}
		});
	}).catch(e => {
		return false;
	});
};

// Files: Collections
function filesCollectionsSlider(id) {
	// Capture
	captureEvent({ action: 'razuna_collections_slider' });
	// Loader
	_showLoader('#files_collections_slider');
	// Check if open
	_checkSliderRightOpenState().then(d => {
		// Attr
		$(".slider-right").attr({
			"data-kt-drawer-name": "files_collections_slider",
			"data-kt-drawer-toggle": "#files_collections_slider_click",
			"data-kt-drawer-width": "{default:'500px'}",
			"id": "files_collections_slider"
		});
		// Get instance
		var _slider = KTDrawer.getInstance(document.querySelector(".slider-right"));
		// Update
		_slider.update();
		// Toggle
		_slider.toggle();
		//- Get hidden values
		var _fields = getGlobalFilesFields();
		// Load
		$.ajax({
			type: "POST",
			url: `/files/collections/drawer?workspace_host_id=${_fields.workspace_host_id}&workspace=${_fields.workspace}`,
			data: { ids: id },
			statusCode: {
				// Error
				500: function (data) {
				},
				// Done
				200: function (data) {
					$('#files_collections_slider').html(data);
				}
			}
		});
	}).catch(e => {
		return false;
	});
};

// Files: Collections Share
function filesCollectionsShareSlider(id) {
	// Capture
	captureEvent({ action: 'razuna_collection_share' });
	// Loader
	_showLoader('#files_collection_links_slider');
	// Check if open
	_checkSliderRightOpenState().then(d => {
		// Attr
		$(".slider-right").attr({
			"data-kt-drawer-name": "files_collection_links_slider",
			"data-kt-drawer-toggle": "#files_collection_links_slider_click",
			"data-kt-drawer-width": "{default:'500px'}",
			"id": "files_collection_links_slider"
		});
		// Get instance
		var _slider = KTDrawer.getInstance(document.querySelector(".slider-right"));
		// Update
		_slider.update();
		// Toggle
		_slider.toggle();
		// Load
		$.ajax({
			type: "GET",
			url: `/files/collection/links/${id}`,
			statusCode: {
				// Error
				500: function (data) {
				},
				// Done
				200: function (data) {
					$('#files_collection_links_slider').html(data);
				}
			}
		});
	}).catch(e => {
		return false;
	});
};

// Files: Collections Settings
function filesCollectionsSettingsSlider(id) {
	// Capture
	captureEvent({ action: 'razuna_collection_settings' });
	// Loader
	_showLoader('#files_collection_settings_slider');
	// Check if open
	_checkSliderRightOpenState().then(d => {
		// Attr
		$(".slider-right").attr({
			"data-kt-drawer-name": "files_collection_settings_slider",
			"data-kt-drawer-toggle": "#files_collection_settings_slider_click",
			"data-kt-drawer-width": "{default:'500px'}",
			"id": "files_collection_settings_slider"
		});
		// Get instance
		var _slider = KTDrawer.getInstance(document.querySelector(".slider-right"));
		// Update
		_slider.update();
		// Toggle
		_slider.toggle();
		//- Get hidden values
		var _fields = getGlobalFilesFields();
		// Load
		$.ajax({
			type: "GET",
			url: `/files/collection/settings/${id}?workspace_host_id=${_fields.workspace_host_id}&workspace=${_fields.workspace}`,
			statusCode: {
				// Error
				500: function (data) {
				},
				// Done
				200: function (data) {
					$('#files_collection_settings_slider').html(data);
				}
			}
		});
	}).catch(e => {
		return false;
	});
};

// Files: Links
function filesLinksSlider(id) {
	// Capture
	captureEvent({ action: 'razuna_file_share' });
	// Loader
	_showLoader('#files_links_slider');
	// Check if open
	_checkSliderRightOpenState().then(d => {
		// Attr
		$(".slider-right").attr({
			"data-kt-drawer-name": "files_links_slider",
			"data-kt-drawer-toggle": "#files_links",
			"data-kt-drawer-width": "{default:'500px'}",
			"id": "files_links_slider"
		});
		// Get instance
		var _slider = KTDrawer.getInstance(document.querySelector(".slider-right"));
		// Update
		_slider.update();
		// Toggle
		_slider.toggle();
		//- Get hidden values
		var _fields = getGlobalFilesFields();
		// Load
		$.ajax({
			type: "GET",
			url: `/files/file/link/${id}?workspace_host_id=${_fields.workspace_host_id}&workspace=${_fields.workspace}`,
			statusCode: {
				// Error
				500: function (data) {
				},
				// Done
				200: function (data) {
					$('#files_links_slider').html(data);
					// umami.track('Shareable file links slider');
				}
			}
		});
	}).catch(e => {
		return false;
	});
};

// Files: Slider
function filesFileSlider(id, host_id) {
	// Capture
	captureEvent({ action: 'razuna_file_detail' });
	// Loader
	_showLoader('#files_file_slider');
	// Check if open
	_checkSliderRightOpenState().then(d => {
		// Attr
		$(".slider-right").attr({
			"data-kt-drawer-name" : "files_file_slider",
			"data-kt-drawer-toggle" : "#files_file_slider",
			"data-kt-drawer-width" : "{default:'95vw'}",
			"id" : "files_file_slider"
		});
		// Get instance
		var _slider = KTDrawer.getInstance( document.querySelector(".slider-right") );
		// Update
		_slider.update();
		// Toggle
		_slider.toggle();
		//- Get hidden values
		var _fields = getGlobalFilesFields();
		_fields.workspace_host_id = host_id || _fields.workspace_host_id;
		// url check
		var _jsurl = parseJSURL();
		var _upload_link = _jsurl.f === 'uploadlinks' ? true : false;
		// Check if this is from a shared folder
		var _shared_folder_p = $('#folder_shared_p').val();
		// Load
		$.ajax({
			type: "GET",
			url: `/files/file/detail/${id}?workspace_host_id=${_fields.workspace_host_id}&workspace=${_fields.workspace}&upload_link=${_upload_link}&shared_folder_p=${_shared_folder_p}`,
			statusCode: {
				// Error
				500: function(data) {
				},
				// Done
				200: function(data) {
					$('#files_file_slider').html(data);
					// umami.track('File detail slider');
				}
			}
		});
		// Monitor closing this drawer
		_slider.on("kt.drawer.hide", function() {
			// Get URL if available
			try {
				var _jsurl = sessionStorage.getItem('files_jsurl');
				if (_jsurl) setFilesURL(JSON.parse(_jsurl), { set_bar: true, set_cookie: false });
			} catch(e) {}
		});
		_slider.on("kt.drawer.after.hidden", function() {
			_popupGallery('grid_files');
		});
	}).catch(e => {
		return false;
	});
};

// Files: Workspace slider
function filesWorkspaceSlider(workspace, workspace_host_id, wks, wks_hid) {
	// Check if open
	// _checkSliderRightOpenState().then(d => {
		// Attr
		// $(".slider-right").attr({
		// 	"data-kt-drawer-name" : "files_workspace_slider",
		// 	"data-kt-drawer-toggle" : "#files_workspace_link",
		// 	"data-kt-drawer-width" : "{default:'500px'}",
		// 	"id" : "files_workspace_slider"
		// });
		// // Get instance
		// var _slider = KTDrawer.getInstance( document.querySelector(".slider-right") );
		// // Update
		// _slider.update();
		// // Toggle
		// _slider.toggle();
		// Capture
		captureEvent({ action: 'razuna_workspace_settings' });
		// Loader
		_showLoader('#files_workspaces_slider');
		// Load
		$.ajax({
			type: "GET",
			url: `/files/workspace/settings?workspace=${workspace}&workspace_host_id=${workspace_host_id}&wks=${wks}&wks_hid=${wks_hid}`,
			statusCode: {
				// Error
				500: function(data) {
				},
				// Done
				200: function(data) {
					$('#files_workspaces_slider').html(data);
					// umami.track('Workspace slider');
				}
			}
		});
	// }).catch(e => {
	// 	return false;
	// });
};

// Files: Workspaces slider
function filesWorkspacesSlider(from, workspace, workspace_host_id) {
	// Capture
	captureEvent({ action: 'razuna_workspace_slider' });
	// Loader
	_showLoader('#files_workspaces_slider');
	from = from || null;
	// Check if open
	_checkSliderLeftOpenState(from).then(d => {
		// Clear html of workspace slider
		$('#files_workspaces_slider').html('');
		$('#files_users_teams_slider').html('');
		// Attr
		if (from !== 'slider') {
			if (from === 'slider_resize') {
				var _slider_to_destroy = KTDrawer.getInstance(document.querySelector(".slider-left"));
				_slider_to_destroy.hide();
				// _slider_to_destroy.destroy();
			}
			$(".slider-left").attr({
				"data-kt-drawer-name": "files_workspaces_slider",
				"data-kt-drawer-toggle": "#files_workspaces_link",
				"data-kt-drawer-width": "{default:'550px'}",
				"id": "files_workspaces_slider"
			});
			// Get instance
			var _slider = KTDrawer.getInstance(document.querySelector(".slider-left"));
			// Update
			_slider.update();
			// Toggle
			_slider.toggle();
		}
		// Get current
		var _fields = getGlobalFilesFields();
		_fields.workspace = workspace || _fields.workspace;
		_fields.workspace_host_id = workspace_host_id || _fields.workspace_host_id;
		// Load
		$.ajax({
			type: "GET",
			url: `/files/workspaces/slider?workspace_host_id=${_fields.workspace_host_id}&workspace=${_fields.workspace}`,
			statusCode: {
				// Error
				500: function (data) {
				},
				// Done
				200: function (data) {
					$('#files_workspaces_slider').html(data);
					// umami.track('Workspace slider');
				}
			}
		});
	}).catch(e => {
		return false;
	});
};

// Only for Razuna app
function notificationSliderFiles() {
	// Capture
	captureEvent({ action: 'slider_notifications' });
	// Loader
	_showLoader('#notifications_slider');
	// Check if open
	_checkSliderRightOpenState().then(d => {
		// Attr
		$(".slider-right").attr({
			"data-kt-drawer-name" : "notifications_slider",
			"data-kt-drawer-toggle" : "#nav_notifications_link",
			"data-kt-drawer-width" : "{default:'500px', 'lg': '900px'}",
			"id" : "notifications_slider"
		});
		// Get instance
		var _slider = KTDrawer.getInstance( document.querySelector(".slider-right") );
		// Update
		_slider.update();
		// Toggle
		_slider.toggle();
		var _fields = getGlobalFilesFields();
		// Load
		$.ajax({
			type: "GET",
			url: `/files/notifications?workspace_host_id=${_fields.workspace_host_id}&workspace=${_fields.workspace}`,
			statusCode: {
				// Error
				500: function(data) {
				},
				// Done
				200: function(data) {
					$('#notifications_slider').html(data);
					// umami.track('Notifications slider');
				}
			}
		});
	}).catch(e => {
		return false;
	});
};

//////////////////
// ANNOUNCEMENTS
//////////////////

function announcementsSlider() {
	// Loader
	_showLoader('#announcements_slider');
	// Check if open
	_checkSliderRightOpenState().then(d => {
		// Attr
		$(".slider-right").attr({
			"data-kt-drawer-name" : "announcements_slider",
			"data-kt-drawer-toggle" : "#nav_announcements_link",
			"data-kt-drawer-width" : "{default:'40vw'}",
			"id" : "announcements_slider"
		});
		// Get instance
		var _slider = KTDrawer.getInstance( document.querySelector(".slider-right") );
		// Update
		_slider.update();
		// Toggle
		_slider.toggle();
		// Load
		$.ajax({
			type: "GET",
			url: '/announcements/slider',
			statusCode: {
				// Error
				500: function(data) {
				},
				// Done
				200: function(data) {
					$('#announcements_slider').html(data);
					// Mark as seen
					// markAnnouncementsSeen();
					// umami.track('Announcements slider');
				}
			}
		});
	}).catch(e => {
		return false;
	});
};

function helpSlider() {
	// Loader
	_showLoader('#help_slider');
	// Check if open
	_checkSliderRightOpenState().then(d => {
		// Attr
		$(".slider-right").attr({
			"data-kt-drawer-name" : "help_slider",
			"data-kt-drawer-toggle" : "#nav_help_link",
			"data-kt-drawer-width" : "{default:'500px'}",
			"id" : "help_slider"
		});
		// Get instance
		var _slider = KTDrawer.getInstance( document.querySelector(".slider-right") );
		// Update
		_slider.update();
		// Toggle
		_slider.toggle();
		// Load
		$.ajax({
			type: "GET",
			url: '/help/slider',
			statusCode: {
				// Error
				500: function(data) {
				},
				// Done
				200: function(data) {
					$('#help_slider').html(data);
					// umami.track('Help slider');
				}
			}
		});
	}).catch(e => {
		return false;
	});
};

//////////////////
// NOTIFICATIONS
//////////////////

function notificationSlider() {
	// Capture
	captureEvent({ action: 'slider_notifications' });
	// Loader
	_showLoader('#notifications_slider');
	// Check if open
	_checkSliderRightOpenState().then(d => {
		// Attr
		$(".slider-right").attr({
			"data-kt-drawer-name" : "notifications_slider",
			"data-kt-drawer-toggle" : "#nav_notifications_link",
			"data-kt-drawer-width" : "{default:'500px', 'lg': '900px'}",
			"id" : "notifications_slider"
		});
		// Get instance
		var _slider = KTDrawer.getInstance( document.querySelector(".slider-right") );
		// Update
		_slider.update();
		// Toggle
		_slider.toggle();
		// Load
		$.ajax({
			type: "GET",
			url: '/notifications',
			statusCode: {
				// Error
				500: function(data) {
				},
				// Done
				200: function(data) {
					$('#notifications_slider').html(data);
				}
			}
		});
	}).catch(e => {
		return false;
	});
};

//////////////////
// BOOKMARKS
//////////////////

function bookmarkSlider() {
	// Capture
	captureEvent({ action: 'slider_bookmarks' });
	// Loader
	_showLoader('#bookmarks_slider');
	// Check if open
	_checkSliderRightOpenState().then(d => {
		// Attr
		$(".slider-right").attr({
			"data-kt-drawer-name" : "bookmarks_slider",
			"data-kt-drawer-toggle" : "#nav_bookmarks_link",
			"data-kt-drawer-width" : "{default:'500px', 'lg': '900px'}",
			"id" : "bookmarks_slider"
		});
		// Get instance
		var _slider = KTDrawer.getInstance( document.querySelector(".slider-right") );
		// Update
		_slider.update();
		// Toggle
		_slider.toggle();
		// Load
		$.ajax({
			type: "GET",
			url: '/bookmarks',
			statusCode: {
				// Error
				500: function(data) {
				},
				// Done
				200: function(data) {
					$('#bookmarks_slider').html(data);
				}
			}
		});
	}).catch(e => {
		return false;
	});
};

//////////////////
// CHAT
//////////////////

function chatDetailsSlider(id) {
	// Loader
	_showLoader('#chat_details_slider');
	// Check if open
	_checkSliderRightOpenState().then(d => {
		// Attr
		$(".slider-right").attr({
			"data-kt-drawer-name" : "chat_details_slider",
			"data-kt-drawer-toggle" : "#chat_messages_dd",
			"data-kt-drawer-width" : "{default:'500px'}",
			"id" : "chat_details_slider",
		});
		// Get instance
		var _slider = KTDrawer.getInstance( document.querySelector(".slider-right") );
		// Update
		_slider.update();
		// Toogle
		_slider.toggle();
		// Load
		$.ajax({
			type: "GET",
			url: `/chat/details/${id}`,
			statusCode: {
				// Error
				500: function(data) {
				},
				// Done
				200: function(data) {
					$('#chat_details_slider').html(data);
				}
			}
		});
	}).catch(e => {
		return false;
	});
};

function chatDocsSlider(id) {
	// Loader
	_showLoader('#chat_docs_slider');
	// Check if open
	_checkSliderRightOpenState().then(d => {
		// Attr
		$(".slider-right").attr({
			"data-kt-drawer-name" : "chat_docs_slider",
			"data-kt-drawer-toggle" : "#chat_docs_search",
			"data-kt-drawer-width" : "{default:'500px'}",
			"id" : "chat_docs_slider",
		});
		// Get instance
		var _slider = KTDrawer.getInstance( document.querySelector(".slider-right") );
		// Update
		_slider.update();
		// Toogle
		_slider.toggle();
		// Load
		$.ajax({
			type: "GET",
			url: `/chat/docs/drawer/${id}`,
			statusCode: {
				// Error
				500: function(data) {
				},
				// Done
				200: function(data) {
					$('#chat_docs_slider').html(data);
				}
			}
		});
	}).catch(e => {
		return false;
	});
};

//////////////////
// LABELS
//////////////////

function labelsSlider(id, from) {
	// Check if open
	_checkSliderRightOpenState(from).then(d => {

		if (from !== 'slider') {

			// Attr
			$(".slider-right").attr({
				"data-kt-drawer-name" : "labels_slider",
				// "data-kt-drawer-toggle" : "#nav_bookmarks_link",
				"data-kt-drawer-width" : "{default:'500px', 'lg': '900px'}",
				"id" : "labels_slider"
			});
			// Get instance
			var _slider = KTDrawer.getInstance( document.querySelector(".slider-right") );
			// Update
			_slider.update();
			// Toggle
			_slider.toggle();

		}

		// Loader
		_showLoader('#labels_slider');

		// Load
		$.ajax({
			type: "GET",
			url: '/mail/label/' + id,
			statusCode: {
				// Error
				500: function(data) {
				},
				// Done
				200: function(data) {
					$('#labels_slider').html(data);
				}
			}
		});

	}).catch(e => {
		return false;
	});
};

//////////////////
// Company
//////////////////

function companySlider(id, from) {
	// Check if open
	_checkSliderRightOpenState(from).then(d => {

		if (from !== 'slider') {

			// Attr
			$(".slider-right").attr({
				"data-kt-drawer-name" : "company_slider",
				// "data-kt-drawer-toggle" : "#nav_bookmarks_link",
				"data-kt-drawer-width" : "{default:'500px', 'lg': '900px'}",
				"id" : "company_slider"
			});
			// Get instance
			var _slider = KTDrawer.getInstance( document.querySelector(".slider-right") );
			// Update
			_slider.update();
			// Toggle
			_slider.toggle();

		}

		// Loader
		_showLoader('#company_slider');

		// Load
		$.ajax({
			type: "GET",
			url: '/mail/company/' + id,
			statusCode: {
				// Error
				500: function(data) {
				},
				// Done
				200: function(data) {
					$('#company_slider').html(data);
				}
			}
		});

	}).catch(e => {
		return false;
	});
};

//////////////////
// PLUGINS
//////////////////

function pluginSlider(options) {
	// Loader
	_showLoader('#plugin_slider');
	// Check if open
	_checkSliderRightOpenState().then(d => {
		// Attr
		$(".slider-right").attr({
			"data-kt-drawer-name" : "plugin_slider",
			"data-kt-drawer-overlay" : "false",
			"data-kt-drawer-width" : "{default:'500px'}",
			"style" : "z-index:1100",
			"id" : "plugin_slider"
		});
		// Get instance
		var _slider = KTDrawer.getInstance( document.querySelector(".slider-right") );
		// Update
		_slider.update();
		// Toggle
		_slider.toggle();
		// Load
		$.ajax({
			type: "GET",
			url: options.url,
			statusCode: {
				// Error
				500: function(data) {
				},
				// Done
				200: function(data) {
					$('#plugin_slider').html(data);
				}
			}
		});
	}).catch(e => {
		return false;
	});
};

// Right: Check if open
function _checkSliderRightOpenState(from) {
	return new Promise(function(resolve, reject) {
		if (from === 'slider') return resolve(true);
		var _is_open = $('.slider-right').hasClass('drawer-on');
		if (_is_open) {
			// Get instance
			var _slider = KTDrawer.getInstance( document.querySelector(".slider-right") );
			_slider.toggle();
			// Show chat
			showHelpmonksChat();
			return reject(false);
		}
		else {
			hideHelpmonksChat();
			return resolve(true);
		}
	})
}

// Close the right slide
function _doSlideRightClose() {
	// Get instance
	var _slider = KTDrawer.getInstance( document.querySelector(".slider-right") );
	// Toogle
	_slider.toggle();
	// _popupGallery();
	showHelpmonksChat();
	// Empty slider html
	$(".slider-right").html('');
}

function _closeRightSlider() {
	var _is_open = $('.slider-right').hasClass('drawer-on');
	if (_is_open) {
		// Get instance
		var _slider = KTDrawer.getInstance(document.querySelector(".slider-right"));
		_slider.hide();
		showHelpmonksChat();
		// Empty slider html
		$(".slider-right").html('');
	}
}

//////////////////////////////////////////////////
// LEFT SLIDERS
//////////////////////////////////////////////////

//////////////////
// Mail Navigation
//////////////////

function mailSidebarSlider() {
	// Check if open
	_checkSliderLeftOpenState().then(d => {

		// Attr
		$(".slider-left").attr({
			"data-kt-drawer-name" : "mail_sidebar_slider",
			"data-kt-drawer-toggle" : ".btn-mail-sidebar",
			"data-kt-drawer-width" : "{default:'350px'}",
			"id" : "mail_sidebar_slider"
		});
		// Get instance
		var _slider = KTDrawer.getInstance( document.querySelector(".slider-left") );
		// Update
		_slider.update();
		// Toggle
		_slider.toggle();

		if ( $('#mail_sidebar_slider #mail_sidebar_slider_body').length ) return;

		$.ajax({
			type: "GET",
			url: '/mail/sidebar_slider',
			statusCode: {
				// Error
				500: function(data) {
				},
				// Done
				200: function(data) {
					$('#mail_sidebar_slider').html(data);
					mailClassicSidebarOnLoad();
					// Now adjust size for scroll again
					$('#mail_section_classic_tree').attr('data-kt-scroll-offset', '0px');
				}
			}
		});

	}).catch(e => {
		return false;
	});
};

function mailDtFilterSlider(id) {

	var jsurl = parseJSURL();

	// if we have an id
	if (id) {
		jsurl.folder = id;
	}

	// if we have a 0 as id it means we have a new tab
	if (id === 0) {
		jsurl.new_tab = true;
	}

	// Check if open
	_checkSliderRightOpenState().then(d => {

		// Attr
		$(".slider-right").attr({
			"data-kt-drawer-name": "mail_dt_filter_slider",
			"data-kt-drawer-toggle": ".btn-mail-dt-filter",
			"data-kt-drawer-width": "{default:'450px'}",
			"id": "mail_dt_filter_slider"
		});
		// Get instance
		var _slider = KTDrawer.getInstance(document.querySelector(".slider-right"));
		// Update
		_slider.update();
		// Toggle
		_slider.toggle();

		if ($('#mail_dt_filter_slider #mail_dt_filter_slider_body').length) return;

		$.ajax({
			type: "POST",
			url: '/mail/filter_slider',
			data: jsurl,
			statusCode: {
				// Error
				500: function (data) {
				},
				// Done
				200: function (data) {
					$('#mail_dt_filter_slider').html(data);
				}
			}
		});

	}).catch(e => {
		return false;
	});
};

// Keft: Check if open
function _checkSliderLeftOpenState(from) {
	return new Promise(function(resolve, reject) {
		if (from === 'slider' || from === 'slider_resize') return resolve(true);
		var _is_open = $('.slider-left').hasClass('drawer-on');
		if (_is_open) {
			// Get instance
			var _slider = KTDrawer.getInstance( document.querySelector(".slider-left") );
			_slider.toggle();
			return reject(false);
		}
		else {
			return resolve(true);
		}
	})
}

// Close the right slide
function _doSlideLeftClose() {
	// Get instance
	var _slider = KTDrawer.getInstance( document.querySelector(".slider-left") );
	// Toogle
	_slider.toggle();
}

function _closeLeftSlider() {
	var _is_open = $('.slider-left').hasClass('drawer-on');
	if (_is_open) {
		// Get instance
		var _slider = KTDrawer.getInstance( document.querySelector(".slider-left") );
		_slider.hide();
	}
}

// // SubFunction to slide div from right
// function _doSlideRightOpen(id, type) {

// 	// Remove animation
// 	// _removeLoadingAnimationGlobal();

// 	// Remove active from all nav-item-sub
// 	$('.nav-item-sub').removeClass('active');

// 	// Hide chat
// 	if (typeof Chatra !== 'undefined' && Chatra) {
// 		Chatra('hide');
// 	}

// 	// Set id of div
// 	var _thediv = '#divrightslider';

// 	$(_thediv).css('padding', '0');

// 	if (type === 'news') {
// 		$.ajax({
// 			type: 'GET',
// 			url: '/news/notification_seen',
// 			statusCode: {
// 				200: function(data) {
// 					// Set cookie that user checked news
// 					var in30Minutes = new Date(new Date().getTime() + 30 * 60 * 1000);
// 					Cookies.set('news_seen', true, _globalCookieOptions(in30Minutes));
// 					Swal.close();
// 				},
// 				500: function() {
// 				}
// 			}
// 		});
// 		// If visible close
// 		if ( $('#slider_news').is(':visible') ) {
// 			_doSlideRightClose('news');
// 			return false;
// 		}
// 		// Add active
// 		$('#nav_hm_news_li').addClass('active');
// 		// If we open news we reset the open notification var
// 		_loaded_notifications = false;
// 		_loaded_todo = false;
// 		if (!_loaded_news) {
// 			loadSkelPanelOverlay('#loadRightSide', {
// 				'row_count': 3,
// 				'card': true
// 			});
// 		}
// 	}
// 	else if (type === 'notifications') {
// 		// If visible close
// 		if ( $('#slider_notifications').is(':visible') ) {
// 			_doSlideRightClose('notifications');
// 			return false;
// 		}
// 		// Add active
// 		$('#nav_notifications_li').addClass('active');
// 		_loaded_news = false;
// 		_loaded_todo = false;
// 		if (!_loaded_notifications) {
// 			loadSkelPanelOverlay('#loadRightSide', {
// 				'row_count': 3,
// 				'card': true,
// 				'topbar': true
// 			});
// 		}
// 	}
// 	else if (type === 'todo') {
// 		// If visible close
// 		if ( $('#slider_todo').is(':visible') ) {
// 			_doSlideRightClose('todo');
// 			return false;
// 		}
// 		// Add active
// 		var _small_visible = $('#nav_todo_li_sm').is(':visible');
// 		if (_small_visible) {
// 			$('#nav_todo_li_sm').addClass('active').css('padding-left:6px;padding-right:6px;');
// 		}
// 		else {
// 			$('#nav_todo_li').addClass('active');
// 		}
// 		_loaded_news = false;
// 		_loaded_notifications = false;
// 		if (!_loaded_todo) {
// 			loadSkelPanelOverlay('#loadRightSide', {
// 				'row_count': 3,
// 				'card': true,
// 				'topbar': true
// 			});
// 		}
// 	}

// 	// Is div open?
// 	var _open = $(_thediv).hasClass('show');

// 	// If panel is already open then simply reload the div
// 	if (_open) {
// 		if (type === 'news') {
// 			_loadNewsRightSide();
// 		}
// 		else if (type === 'notifications') {
// 			_loadNotificationsRightSide(false, false);
// 		}
// 		else if (type === 'todo') {
// 			_loadToDoRightSide(false, false);
// 		}
// 	}
// 	else {
// 		// Get width of window
// 		var _width = $('.slideCSSRight').width();
// 		// if this is for the small view
// 		var _small_visible = $('#nav_todo_li_sm').is(':visible');
// 		// Set window width accordingly
// 		_width = _small_visible ? 400 : _width;
// 		// Open if closed
// 		$(_thediv).removeClass('hidden').animate({
// 			// right: 0,
// 			"margin-right": '+=' + _width
// 		}, 200, function() {
// 			$(_thediv).addClass('show');

// 			if (type === 'news') {
// 				if (!_loaded_news) {
// 					_loadNewsRightSide();
// 				}
// 			}
// 			else if (type === 'notifications') {
// 				// Load notifications (only when not already loaded)
// 				if (!_loaded_notifications) {
// 					_loadNotificationsRightSide(false, false);
// 				}
// 			}
// 			else if (type === 'todo') {
// 				// Load notifications (only when not already loaded)
// 				if (!_loaded_todo) {
// 					_loadToDoRightSide(false, false);
// 				}
// 			}

// 		});
// 	}
// }

// // Left slider
// function _doSlideLeftOpen() {
// 	// Remove animation
// 	// _removeLoadingAnimationGlobal();
// 	// For mobile view
// 	$('.navbar-collapse').removeClass('in');
// 	// Set id of div
// 	var _thediv = '#divleftslider';
// 	// Is div open?
// 	var _open = $(_thediv).hasClass('show');
// 	// If panel is already open then simply reload the div
// 	if (_open) {
// 		// Close slider
// 		_doSlideLeftClose();
// 	}
// 	else {
// 		// Add active
// 		$('#navbar-mailbox-dropdown, #navbar-mailbox-dropdown-sm').addClass('active');
// 		// Open if closed
// 		$(_thediv).removeClass('hidden').animate({
// 			left: 0
// 		}, 200, function() {
// 			$(_thediv).addClass('show');
// 			$("#mailbox_search").select2({
// 				width: '100%',
// 				placeholder: {
// 					id: '-1',
// 					text: i18n.__('search_mailboxes')
// 				}
// 			});
// 			$('#mailbox_search').on('select2:select', function(evt) {
// 				var _id = evt.params.data.id;
// 				document.location.href = '/mailbox/' + _id + '/inbox';
// 			});
// 		});
// 	}
// }

// // Close the right slide
// function _doSlideLeftClose() {
// 	// Remove active
// 	$('#navbar-mailbox-dropdown, #navbar-mailbox-dropdown-sm').removeClass('active');
// 	// Set id of div
// 	var _thediv = '#divleftslider';
// 	// Close
// 	$(_thediv).animate({
// 		// right: -20
// 		left: -250
// 	}, 200, function() {
// 		$(_thediv).removeClass('show');
// 		$(_thediv).addClass('hidden');
// 	});
// }

// // Subfunction for news slider
// function _loadNewsRightSide() {
// 	loadSkelPanelOverlay('#loadRightSide', {
// 		'row_count': 3,
// 		'card': true
// 	});
// 	// Check if there are news
// 	$.ajax({
// 		type: "GET",
// 		url: '/news/check_news_count?from=show',
// 		statusCode: {
// 			// Error
// 			500: function(data) {
// 				console.log('ERROR checking local news');
// 			},
// 			// Done
// 			200: function(data) {
// 				$.ajax({
// 					type: "GET",
// 					url: '/news?has_local_news=true',
// 					statusCode: {
// 						// Error
// 						500: function(data) {
// 							console.log('ERROR checking local news');
// 						},
// 						// Done
// 						200: function(data) {
// 							// Set data
// 							$('#loadRightSide').html(data);
// 							// Set that user loaded news already
// 							_loaded_local_news = true;
// 							// Set count
// 							lfSetItem('hm_news_count', 0).then(d => $('#news_badge').addClass('hidden') );
// 						}
// 					}
// 				});
// 			}
// 		}
// 	});
// }

// function _updateNewsBadge() {
// 	lfGetItem('hm_news_count').then(_count => {
// 		var _count = parseInt(_count);
// 		if (!_count) {
// 			$('#news_badge').addClass('hidden');
// 		} else {
// 			$('#news_badge').html(_count);
// 		}
// 	})
// }

// // Subfunction to open slider (above)
// function _loadNotificationsRightSide(paging, back, sel) {
// 	// Define selection
// 	sel = sel ? sel : {};
// 	var _type_selected = sel.value;
// 	// Var
// 	back = back ? back : false;
// 	var _type_cookie = typeof _type_selected === 'undefined' ? Cookies.get('notifications_filter') : _type_selected;
// 	var _type = typeof _type_cookie === 'undefined' ? 'null' : _type_cookie;
// 	// Show skeleton loading
// 	loadSkelPanelOverlay('.notification_list', {
// 		'row_count': 3,
// 		'card': true
// 	});
// 	_loadNotificationsRightSideSub(paging, back, _type, function() {
// 		// markReadNotification(true);
// 	})
// }


// // Subfunction to open slider (above)
// function _loadNotificationsRightSideSub(paging, back, type, cb) {
// 	// Set div
// 	var _thedivloader = '#loadRightSide';
// 	// Set page
// 	var _page = 1;
// 	// Set type cookie
// 	Cookies.set('notifications_filter', type, _globalCookieOptions(365));
// 	// if paging is true
// 	if (paging) {
// 		// Get values
// 		var _on_page = parseInt($('#notifications_on_page').val());
// 		// Increase page
// 		_page = back ? _on_page - 1 : _on_page + 1;
// 	}
// 	// Load in div
// 	$(_thedivloader).load('/notifications', {
// 		page: _page,
// 		type: type
// 	}, function() {
// 		// Set that user loaded notifications already
// 		_loaded_notifications = true;
// 		cb();
// 	});
// }

// //////////////////
// // Resize Sidebar
// //////////////////

// function resizeLeftSideBar(no_cookie) {
// 	no_cookie = no_cookie || false;
// 	// Current size
// 	var _current_size = $('.sidebar').css('width');
// 	// Call Subfunction
// 	_subResizeLeftSideBar(_current_size === '260px' ? 'small' : 'normal', no_cookie);
// }

// function _subResizeLeftSideBar(type, no_cookie) {
// 	// Set cookie
// 	if (!no_cookie) Cookies.set('left_sidebar_size', type, _globalCookieOptions(365));
// 	// Remove class
// 	$('.left-sidebar-class').removeClass('hidden show');
// 	$('#sidebar_labels_small').addClass('hidden');
// 	// Type
// 	if (type === 'small') {
// 		$('.left-sidebar-resize-button').addClass('btn-block').removeClass('btn-xs');
// 		$('#div_label_explorer').remove();
// 		$('.label-explorer').remove();
// 		$('.sidebar').addClass('sidebar-small');
// 		$('.sidebar .badge').addClass('sidebar-small-badge');
// 		$('.main').addClass('main-small');
// 		$('.folders_navigation_buttons_list').removeClass('width-100-300px').addClass('width-100-100px');
// 		$('#mailbox_link_new_message').removeClass('color-buttons-custom-sidebar color-buttons-custom-border-sidebar').addClass('no-border-background');
// 		setTimeout(function() {
// 			$('.sidebar-small-badge').addClass('sidebar-small-badge-trans');
// 		}, 500);
// 	}
// 	else {
// 		$('.sidebar').removeClass('sidebar-small');
// 		$('.main').removeClass('main-small');
// 		$('.sidebar .badge').removeClass('sidebar-small-badge sidebar-small-badge-trans');
// 		$('.folders_navigation_buttons_list').removeClass('width-100-100px').addClass('width-100-300px');
// 		$('.left-sidebar-resize-button').removeClass('btn-block').addClass('btn-xs');
// 		$('#mailbox_link_new_message').addClass('color-buttons-custom-sidebar color-buttons-custom-border-sidebar').removeClass('no-border-background');
// 		// Load Labels
// 		reloadLabelExplorer();
// 	}
// }

// //////////////////
// // Sidebar Small Labels
// //////////////////

// function leftSideBarSmallLoadLabels() {
// 	// Check if open
// 	var _visible = $('#sidebar_labels_small').is(':visible');
// 	// if open, close it
// 	if (_visible) {
// 		$('.left-sidebar-labels-div').addClass('hidden left-sidebar-labels-div-close');
// 		// Clear labels div or else there are weird UI things
// 		$('#sidebar_labels_small').addClass('hidden').html('');
// 	}
// 	else {
// 		// Load Labels
// 		reloadLabelExplorer('small');
// 	}
// }

// function leftSideBarSmallLoadLabelsClose() {
// 	$('#sidebar_labels_small').addClass('hidden');
// 	$('.left-sidebar-labels-div').addClass('left-sidebar-labels-div-close');
// }

////////////////////////////// DESKTOP NOTIFICATION STUFF ///////////////////////////

// Gets called when user enables desktop notification
function enableDesktopNotification() {
	// If not supported
	if (!window.Notification) return false;
	// Options
	var _options = {
		subject: 'Yay!',
		excerpt: 'Desktop notifications are now enabled. Happy times ahead :)'
	};
	// Permissions
	var _perm = Notification.permission;
	// var _ls_enabled = Cookies.get('hm_desktop_notification_enabled') || false;
	// console.log("_perm", _perm);
	// console.log("_ls_enabled", _ls_enabled);
	if (_perm === "default") {
		Notification.requestPermission().then(function(permission) {
			// If the user accepts, let's create a notification
			if (permission === "granted") {
				// Set
				return sendDesktopNotification(_options);
			}
			return false;
		});
	}
	return false;
}

// Subfunction to check notification permissions
function checkDesktopNotification() {
	// Let's check if the browser supports notifications
	if (!window.Notification) {
		// alert( i18n.__('desktop_notification_not_supported') );
		return false;
	} else {
		return Notification.permission;
	}
}


// Send notification
function sendDesktopNotification(options) {
	// If not supported
	// console.log("window.Notification", window.Notification);
	if (!window.Notification) return false;
	// Check for do not disturb. A user with do not disturb enabled shouldn't even get here so this is the last resort
	if ( Cookies.get('do_not_disturb') === 'true' ) return false;
	// If not same host_id
	// if ( options.host_id.toString() !== Cookies.get('host_id') ) return false;
	options.do_not_close_notification = options.do_not_close_notification || false;
	// Store emailchain_id in global var
	options.id = options.id ? options.id : uuid();
	var _emailchain_id_for_notification = options.id;
	var _instance = $('#global_app_instance').val();
	// Options for notification
	var _options = {
		tag: _emailchain_id_for_notification,
		body: decodeHtml(options.excerpt),
		icon: _instance === 'helpmonks' ? '/dist2/images/helpmonks_icon_transparent_128.png' : '/dist2/images/razuna/favicons/512.png',
		requireInteraction: options.do_not_close_notification,
		silent: false
	};
	// if we have a subject
	if (options.subject) {
		_options.title = options.subject;
	}
	// Send it
	// console.log("_options", _options);
	var _notification = new Notification(options.subject, _options);
	// Play notification sound
	// playSoundNotification('email_new');
	// Close notification but not if option is set
	if (!options.do_not_close_notification) {
		setTimeout(_notification.close.bind(_notification), 20000);
	}
	// Click on notification
	_notification.onclick = function(event) {
		event.preventDefault(); // prevent the browser from focusing the Notification's tab
		// console.log('_emailchain_id_for_notification', _emailchain_id_for_notification);
		if ( options.action !== 'draft_notification' && options.action !== 'todo_notification' && options.action !== 'approval_notification' && options.action !== 'plugin' ) {
			$.ajax({
				type: "POST",
				url: '/notifications_set_alert_one',
				data: {
					type: options.action,
					emailchain_id: _emailchain_id_for_notification
				},
				statusCode: {
					200: function(data) {
						window.open('/r/' + _emailchain_id_for_notification, '_blank');
					}
				}
			});
		}
		// For drafts, todo, approval
		if ( !options.no_link && ( options.action === 'draft_notification' || options.action === 'todo_notification' || options.action === 'approval_notification' || options.action === 'plugin' ) ) {
			window.open(options.action_url, '_blank');
		}
	};
	_notification.onerror = function(event) {
		console.log('sendDesktopNotification error :', event);
		// Call here again with different excerpt
		if (options.action === 'newEmail') {
			options.excerpt = "A new email arrived";
			sendDesktopNotification(options);
		}
		if (options.action === 'setAssignee') {
			options.excerpt = "Conversation(s) got assigned to you";
			sendDesktopNotification(options);
		}
	};
	// if we have to do the in app notification also
	if (options.in_app_notification) {
		_sendInAppNotification(options);
	}
	// Return
	return true;
}

// Test notification
function testDesktopNotification() {
	// If not supported
	if (!window.Notification) {
		alert('Looks like your browser does not support web desktop notifications :(');
		return false;
	}
	var _instance = $('#global_app_instance').val();
	// Options for notification
	var _options = {
		body: 'Notifications are now enabled. Happy times ahead :)',
		icon: _instance === 'helpmonks' ? '/dist2/images/helpmonks_icon_transparent_128.png' : '/dist2/images/razuna/favicons/512.png',
		silent: false
	};
	// Send it
	var _notification = new Notification('Yay!', _options);
	// Play notification sound
	// playSoundNotification('email_new');
	// Click on notification
	_notification.onclick = function(event) {
		event.preventDefault(); // prevent the browser from focusing the Notification's tab
	};
	_notification.onerror = function(event) {
		console.log('testDesktopNotification error :', event);
	};
	// Return
	return true;
}

function _sendInAppNotification(options) {
	// Vars
	var _timeout = options.do_not_close_notification === true ? false : options.timeout === false ? false : options.timeout || 7000;
	var _text = options.excerpt;
	var _type = options.type || 'success';
	var _click = function() { document.location.href = '/r/' + options.id; };
	// var _text_open = options.approval_action === 'approve' ? i18n.__('approval_message_open') : i18n.__('approval_draft_open');
	// For mentions
	if (options.mentions && options.mentions.length) {
		_timeout = 20000;
		_text = '<p><strong>' + i18n.__('you_got_mentioned') + '</strong></p>' + _text + '<p class="pt-3">' + i18n.__('click_goto_conversation') + '</p>';
		_type = 'warning';
	}
	// For to do we append the to go as well
	if (options.action === 'todo_notification') {
		_text = options.in_app_text || options.excerpt;
		_text = _text + '<p class="pt-3">' + i18n.__('todo_open') + '</p>';
		_click = function() { openToDo(options.id); };
	}
	// For approvals
	if (options.action === 'approval_notification') {
		_text = _text + '<p class="pt-3">' + options.text_open + '</p>';
		_click = function() { document.location.href = options.action_url; };
	}
	// For Assignee
	if (options.action === 'assigned') {
		_text = options.assignee._id === _getUserCookie() ? options.in_app_text : _text;
		_text = '<p class="pt-3">' + options.subject + '</p><p class="pt-3">' + _text + '<p class="pt-3">' + options.text_open + '</p>';
	}
	// For approvals
	if (options.action === 'assigned_many') {
		_text = _text + '<p class="pt-3">' + options.text_open + '</p>';
		_click = function() { document.location.href = options.action_url; };
	}
	// For plugins
	if (options.action === 'plugin') {
		// For now for engage with abort here
		// Get width of browser
		var _browser_width = $(window).width();
		if (options.plugin_name && options.plugin_name === 'engage' && _browser_width < 500) return false;
		_text = options.in_app_text || _text;
		_text = _text + '<p class="pt-3">' + options.text_open + '</p>';
		_click = function() { document.location.href = options.action_url; };
	}
	// For file comments
	if (options.action === 'comment') {
		_text = _text + '<p class="pt-3">' + options.text_open + '</p>';
		_click = filesFileSlider(options.id);
	}
	// For folder and workspace sharing
	if (options.action === 'folder_shared' || options.action === 'workspace_shared') {
		_click = function() { return false };
	}
	notyWrapper({
		id: options.id,
		text: _text,
		type: _type,
		timeout: _timeout,
		func: _click
	});
}

// Notify Bell
function notifyBell() {

	_loaded_notifications = false;

	// Set Notification count
	// setTimeout(function() {
	// 	checkForNewNotifications();
	// }, 2000);

	// Is div open?
	var _open = $('#divrightslider').hasClass('show');
	// if open
	if (_open) {
		_loadNotificationsRightSide(false, false);
	}

}

// Check if the localstorage had new notification
// function checkForNewNotifications(cached) {
// 	// Cached
// 	cached = typeof cached !== 'undefined' ? cached : true;
// 	// If cached (default) and we have a one minute cookie we return here
// 	if ( cached && Cookies.get('check_notifications') ) return;
// 	// Set one minute cookie
// 	var inOneMinute = new Date(new Date().getTime() + 1 * 60 * 1000);
// 	Cookies.set('check_notifications', true, _globalCookieOptions(inOneMinute));
// 	// Check
// 	setTimeout(function() {
// 		checkForNewNotificationsSub(false, function(error, data) {
// 			// Set notification badge
// 			setNotificationsBadge(data.counts);
// 		})
// 	}, 2000);
// }

// function checkForNewNotificationsSub(cached, callback) {
// 	// Check for new notifications
// 	$.ajax({
// 		type: 'GET',
// 		url: '/notifications/check_new',
// 		statusCode: {
// 			200: function(data) {
// 				return callback(null, data);
// 			}
// 		}
// 	});
// }

function setNotificationsBadge(counts) {
	// Abort when the user has notification badge turned off
	if ( _getShowNotificationBadgeCookie() === 'false' ) return false;
	// Check total
	var _count_notifications_badge = counts && counts.count_all ? counts.count_all : 0;
	// Show badge
	if (_count_notifications_badge) {
		$('#notification_badge').removeClass('hidden').addClass('animate__animated animate__heartBeat animate__delay-2s').html( counts.count_all > 100 ? '99+' : counts.count_all );
	} else {
		$('#notification_badge').addClass('hidden').removeClass('animate__animated animate__heartBeat animate__delay-2s');
	}
	// Save into LS
	lfSetItem(`hm_notifications_counts_${_getHostIdCookie()}_${_getUserCookie()}`, counts);
}

//- Mark all as read
function markReadNotification(from_nav) {
	// Set if coming from nav or not
	from_nav = from_nav ? from_nav : false;
	// If from nav
	if (from_nav) {
		$.ajax({
			type: "POST",
			url: '/notifications_mark_all_read',
			statusCode: {
				200: function(data) {
					// Set notification badge
					setNotificationsBadge(data.counts);
				}
			}
		})
	} else {
		// loadOverlayElement('body', null, function () {
		loadSkelPanelOverlay('.notification_list', {
			'row_count': 3,
			'card': true
		});
		//- Load
		$.ajax({
			type: "POST",
			url: '/notifications_mark_all_read',
			statusCode: {
				200: function(data) {
					// Set notification badge
					setNotificationsBadge(data.counts);
					//- Reload notifications
					_loadNotificationsRightSide(false, false);
					//- Remove overlay
					// removeBodyOverlay();
				}
			}
		})
	};
}

//- Show only new or not
function loadNotificationsOnlyNew() {
	// Depending on cookie we either load only new or not
	var _c = Cookies.get('notifications_only_new');
	var _new = _c && _c === 'true' ? false : typeof _c === 'undefined' ? true : true;
	// Set cookie
	Cookies.set('notifications_only_new', _new, _globalCookieOptions(365));
	// Load it
	_loadNotificationsRightSide(false, false);
}

// Set the notification icon depending on do not disturb
function setNotificationsBadgeDoNotDisturb(dnd) {
	//- Default markup
	var _icon = '<i class="fal fa-bells fa-1-3" id="nav_notifications_icon"></i>';
	if (dnd) {
		_icon = '<span class="fa-stack" id="nav_notifications_icon" style="margin-top:-0.5rem;padding-top:0.2rem;margin-left:-0.5rem;margin-right:-0.5rem;"><i class="fal fa-ban fa-stack-2x" class="helpmonks-text-tomato"></i><i class="fal fa-bells fa-stack-1x"></i></span>';
	}
	// Replace html
	$('#nav_notifications_icon').replaceWith(_icon);
}

// Mark notification for emailchain read
function markNotificationForEmailChainRead(ec_id, many) {
	many = many ? many : false;
	$.ajax({
		type: "POST",
		url: '/notifications_set_alerts',
		data: {
			ec_id: ec_id
		},
		statusCode: {
			200: function() {
				// if (many) checkForNewNotifications();
			}
		}
	});
}

// Mark notification from list
// function markNotificationForEmailChainListRead(ids_on_page, select_all) {
// 	// Remove storage
// 	lfRemoveItem('hm_notifications_counts').then(s => {
// 		// Set notification
// 		$.ajax({
// 			type: "POST",
// 			url: '/notifications_set_alerts_from_list',
// 			data: {
// 				ec_ids: ids_on_page, all : select_all
// 			},
// 			statusCode: {
// 				200: function() {
// 					// if (many) checkForNewNotifications();
// 				}
// 			}
// 		});
// 	}).catch(e => console.log('Error removing LF key: hm_notifications_counts', e));
// }

function toggleNotificationCheck(mailboxid, type) {
	var _chk = $('#notifications_mailbox_' + mailboxid + ' .notification_check_' + type);
	_chk.prop("checked", !_chk.prop("checked"));
}

function toggleNotificationContent(type) {
	var _chk = $('.mailbox_notification_content .notification_check_' + type);
	_chk.prop("checked", !_chk.prop("checked"));
}





//- Function to fill in summaries
function reportFillSummary(summary, kind) {
	//- For mailbox and user reports
	if (kind === 'mailbox' || kind === 'user') {
		//- Totals
		$('#reports_summary_first_responses').html(summary.num_first_responses);
		$('#reports_summary_total_responses').html(summary.num_responses);
		$('#reports_summary_total_resolved').html(summary.num_resolved);
		//- Averages
		var _s1 = Math.floor( parseInt(summary.avg_first_response_time), 0 );
		var _res_first = moment.duration(_s1, 'seconds').format('d[d] h[h] m[m]', { usePlural: false });
		var _res = moment.duration(parseInt(summary.avg_response_time), 'seconds').format('d[d] h[h] m[m]', { usePlural: false });
		var _resolve = moment.duration(parseInt(summary.avg_resolution_time), 'seconds').format('d[d] h[h] m[m]', { usePlural: false });
		//- Set
		$('#reports_summary_first_responses_average').html(_res_first);
		$('#reports_summary_responses_average').html(_res);
		$('#reports_summary_resolve_average').html(_resolve);
	}
	//- For time
	if (kind === 'time') {
		var _num_emails = summary.num_emails || summary.num_new_emails || 0;
		var _num_resolved = summary.num_resolved || summary.num_emails_resolved || 0;
		//- Totals
		$('#reports_summary_total_email').html(_num_emails);
		$('#reports_summary_total_resolved').html(_num_resolved);
		$('#reports_summary_total_emails_new').html(summary.num_emails_from_new_addresses || 0);
		$('#reports_summary_total_received').html(summary.num_received || 0);
		$('#reports_summary_total_sent').html(summary.num_sent || 0);
		if (summary.trend_data && summary.trend_data.num_emails) $('#reports_summary_trend_emails').html(summary.trend_data.num_emails.highest_info.day + ' / ' + summary.trend_data.num_emails.lowest_info.day);
		if (summary.trend_data && summary.trend_data.num_resolved) $('#reports_summary_trend_resolved').html(summary.trend_data.num_resolved.highest_info.day + ' / ' + summary.trend_data.num_resolved.lowest_info.day);
		if (summary.trend_data && summary.trend_data.num_emails_from_new_addresses) $('#reports_summary_trend_new').html(summary.trend_data.num_emails_from_new_addresses.highest_info.day + ' / ' + summary.trend_data.num_emails_from_new_addresses.lowest_info.day);
	}
}

function fillPieCharts(data, is_reloading) {

	var _no_data;

	var no_data = $('#no_data_message').val();
	var no_data_element = {
		'datasets' : [0],
		'labels' : [ no_data ],
		'background_color' : '#E8E8E8'
	};

	if (is_reloading) {
		//- Reset Charts
		_resetChartData('num_first_responses');
		_resetChartData('num_responses');
		_resetChartData('num_resolved');
		_resetChartData('average_first_response_time');
		_resetChartData('average_response_time');
		_resetChartData('average_resolution_time');
		//- Hide rows
		$("#num_first_responses_row").addClass('hidden');
		$("#num_responses_row").addClass('hidden');
		$("#num_resolved_row").addClass('hidden');
		$("#average_first_response_time_row").addClass('hidden');
		$("#average_response_time_row").addClass('hidden');
		$("#average_resolution_time_row").addClass('hidden');
		$("#average_response_header").addClass('hidden');
		$("#number_response_header").addClass('hidden');
	}

	// num first responses
	_no_data = _isDataEmpty(data.num_first_responses.datasets);
	if ( ! _no_data ) {
		$("#num_first_responses_row").removeClass('hidden');
		$("#number_response_header").removeClass('hidden');
		var pie = $("#num_first_responses").get(0).getContext("2d");
		var num_first_responses_chart = new Chart(pie, {
			type : 'pie',
			data : {
				datasets : [{
					data : data.num_first_responses.datasets,
					backgroundColor: data.num_first_responses.background_color
				}],
				labels : data.num_first_responses.labels
			},
			options: {
				legend: {
					display: data.num_first_responses.labels.length > 5 ? false : true
				}
			}
		});
	}

	// num responses
	_no_data = _isDataEmpty(data.num_responses.datasets);
	if ( ! _no_data ) {
		$("#num_responses_row").removeClass('hidden');
		$("#number_response_header").removeClass('hidden');
		var pie1 = $("#num_responses").get(0).getContext("2d");
		var num_responses_chart = new Chart(pie1, {
			type : 'pie',
			data : {
				datasets : [{
					data : data.num_responses.datasets,
					backgroundColor: data.num_responses.background_color
				}],
				labels : data.num_responses.labels
			},
			options: {
				legend: {
					display: data.num_responses.labels.length > 5 ? false : true
				}
			}
		});
	}

	// num_resolved
	_no_data = _isDataEmpty(data.num_resolved.datasets);
	if ( ! _no_data ) {
		$("#num_resolved_row").removeClass('hidden');
		$("#number_response_header").removeClass('hidden');
		var pie2 = $("#num_resolved").get(0).getContext("2d");
		var num_resolved_chart = new Chart(pie2, {
			type : 'pie',
			data : {
				datasets : [{
					data : data.num_resolved.datasets,
					backgroundColor: data.num_resolved.background_color
				}],
				labels : data.num_resolved.labels
			},
			options: {
				legend: {
					display: data.num_resolved.labels.length > 5 ? false : true
				}
			}
		});
	}

	// average first response time
	_no_data = _isDataEmpty(data.average_first_response_time.datasets);
	if ( ! _no_data ) {
		$("#average_first_response_time_row").removeClass('hidden');
		$("#average_response_header").removeClass('hidden');
		var avg = $("#average_first_response_time").get(0).getContext("2d");
		var average_first_response_time_chart = new Chart(avg, {
			type : 'pie',
			data : {
				datasets : [{
					data : data.average_first_response_time.datasets,
					backgroundColor : data.average_first_response_time.background_color,
					label : data.average_first_response_time.datasets_label
				}],
				labels : data.average_first_response_time.labels
			},
			options: {
				legend: {
					display: data.average_first_response_time.labels.length > 5 ? false : true
				},
				tooltips: {
					callbacks: {
						label: function(tooltipItem, chart) {
							return data.average_first_response_time.datasets_label[tooltipItem.index];
						}
					}
				}
			}
		});
	}

	// average response time
	_no_data = _isDataEmpty(data.average_response_time.datasets);
	if ( ! _no_data ) {
		$("#average_response_time_row").removeClass('hidden');
		$("#average_response_header").removeClass('hidden');
		var avg1 = $("#average_response_time").get(0).getContext("2d");
		var average_response_time_chart = new Chart(avg1, {
			type : 'pie',
			data : {
				datasets : [{
					data : data.average_response_time.datasets,
					backgroundColor: data.average_response_time.background_color
				}],
				labels : data.average_response_time.labels
			},
			options: {
				legend: {
					display: data.average_response_time.labels.length > 5 ? false : true
				},
				tooltips: {
					callbacks: {
						label: function(tooltipItem, chart) {
							return data.average_response_time.datasets_label[tooltipItem.index];
						}
					}
				}
			}
		});
	}

	// average resolution time
	_no_data = _isDataEmpty(data.average_resolution_time.datasets);
	if ( ! _no_data ) {
		$("#average_resolution_time_row").removeClass('hidden');
		$("#average_response_header").removeClass('hidden');
		var avg2 = $("#average_resolution_time").get(0).getContext("2d");
		var average_resolution_time_chart = new Chart(avg2, {
			type : 'pie',
			data : {
				datasets : [{
					data : data.average_resolution_time.datasets,
					backgroundColor: data.average_resolution_time.background_color
				}],
				labels : data.average_resolution_time.labels
			},
			options: {
				legend: {
					display: data.average_resolution_time.labels.length > 5 ? false : true
				},
				tooltips: {
					callbacks: {
						label: function(tooltipItem, chart) {
							return data.average_resolution_time.datasets_label[tooltipItem.index];
						}
					}
				}
			}
		});
	}

};

function _isDataEmpty(data) {
	var _empty = true;
	for (i =0; i < data.length; i++) {
		if ( data[i] !== 0 ) {
			_empty = false;
			break;
		}
	};
	return _empty;
};




////////////////////////////////////////////////
// DELETE MODAL
////////////////////////////////////////////////

//- Gather delete data
function deleteRecord(type, id) {
	//- Vars
	var _func;
	var _header = '';
	var _text = '';
	var _note = '';
	var _button_text = i18n.__('button_remove_record');
	//- Depending on type
	switch(type) {
		case 'api_key_revoke':
			_func = `revokeApiKey('${id}')`;
			_header = i18n.__('api_key_delete_header');
			_text = i18n.__('api_key_delete_desc');
			_note = i18n.__('api_key_delete_note');
			_button_text = i18n.__('api_key_delete_confirm');
			break;
		case 'todo':
			_func = `removeToDo('${id}')`;
			_header = i18n.__('task_remove_header');
			_text = i18n.__('task_remove_text');
			_button_text = i18n.__('task_remove_button');
			break;
		case 'todo_comment':
			_func = `removeToDoComment('${id}')`;
			_header = i18n.__('task_remove_comment_header');
			_text = i18n.__('task_remove_comment_text');
			_button_text = i18n.__('task_remove_comment_button');
			break;
		case 'todo_list':
			_func = `removeToDoCategory('${id}')`;
			_header = i18n.__('category_delete');
			_text = i18n.__('category_delete_text');
			_note = i18n.__('category_delete_note_todo');
			_button_text = i18n.__('category_delete');
			break;
		case 'todo_group':
			_func = `removeToDoGroup('${id}')`;
			_header = i18n.__('todo_group_delete');
			_text = i18n.__('todo_group_delete_text');
			_note = i18n.__('todo_group_delete_note_todo');
			_button_text = i18n.__('todo_group_delete');
			break;
		case 'companies':
			_func = `removeCompanies('${id}')`;
			_header = i18n.__('delete_selected_companies');
			_text = i18n.__('delete_selected_companies_desc');
			_button_text = i18n.__('button_remove_record');
			break;
		case 'label_company_users':
			_func = `removeCompanyUsersLabel('${id}')`;
			_header = i18n.__('modal_remove_label_header');
			_text = i18n.__('modal_remove_label_text');
			_note = i18n.__('modal_remove_label_note');
			_button_text = i18n.__('modal_remove_label_header');
			break;
		case 'sla':
			_func = `removeSla('${id}')`;
			_header = i18n.__('modal_remove_sla_header');
			_text = i18n.__('modal_remove_sla_text');
			_note = i18n.__('modal_remove_sla_note');
			_button_text = i18n.__('modal_remove_sla_btn');
			break;
		case 'marketing_email_template':
			_func = `removeEmailTemplate('${id}')`;
			_header = i18n.__('template_remove_header');
			_text = i18n.__('template_remove_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('template_remove_header');
			break;
		case 'marketing_archive':
			_func = `removeCampaignArchive('${id}')`;
			_header = i18n.__('campaign_archive_remove_header');
			_text = i18n.__('campaign_archive_remove_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('confirm_delete');
			break;
		case 'marketing_list':
			_func = `removeCampaignsList('${id}')`;
			_header = i18n.__('campaign_list_remove_header');
			_text = i18n.__('campaign_list_remove_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('confirm_delete');
			break;
		case 'marketing_list_users':
			_func = `removeUserFromCampaignList('${id}', false)`;
			_header = i18n.__('remove_subscriber');
			_text = i18n.__('remove_subscriber_desc');
			_button_text = i18n.__('remove_subscriber_button');
			break;
		case 'marketing_list_users_many':
			_func = `removeUserFromCampaignList('${id}', true)`;
			_header = id === 'all' ? i18n.__('remove_subscriber_all') : i18n.__('remove_subscriber_selected');
			_text = id === 'all' ? i18n.__('remove_subscriber_all_desc') : i18n.__('remove_subscriber_selected_desc');
			_button_text = id === 'all' ? i18n.__('remove_subscriber_all_button') : i18n.__('remove_subscriber_selected_button');
			break;
		case 'marketing_draft':
			_func = `removeCampaignDraft('${id}')`;
			_header = i18n.__('campaign_draft_remove_header');
			_text = i18n.__('campaign_draft_remove_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('campaign_draft_remove_button');
			break;
		case 'marketing_auto_sequence':
			_func = `removeAutoSequence('${id}', true)`;
			_header = i18n.__('sequences_delete');
			_text = i18n.__('sequences_delete_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('confirm_delete');
			break;
		case 'marketing_auto':
			_func = `removeAutoCampaign('${id}')`;
			_header = i18n.__('drip_remove');
			_text = i18n.__('drip_remove_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('confirm_delete');
			break;
		case 'marketing_auto_user':
			_func = `removeUserFromAutoCampaign('${id}')`;
			_header = i18n.__('modal_header_remove_user');
			_text = i18n.__('drip_remove_user_desc');
			_button_text = i18n.__('modal_header_remove_user');
			break;
		case 'marketing_auto_user_many':
			_func = `removeUserFromAutoCampaignMany()`;
			_header = i18n.__('modal_header_remove_users');
			_text = i18n.__('drip_remove_users_desc');
			_button_text = i18n.__('modal_header_remove_users');
			break;
		case 'forms':
			_func = `removeForms('${id}')`;
			_header = i18n.__('form_remove');
			_text = i18n.__('form_remove_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('form_remove');
			break;
		case 'chat_saved_reply':
			_func = `removeChatSavedReply('${id}')`;
			_header = i18n.__('remove_saved_reply');
			_text = i18n.__('remove_saved_reply_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_saved_reply');
			break;
		case 'chat_group':
			_func = `removeChatGroup('${id}')`;
			_header = i18n.__('remove_group');
			_text = i18n.__('remove_group_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_group');
			break;
		case 'chat_trigger':
			_func = `removeChatTrigger('${id}')`;
			_header = i18n.__('push_notifications_remove');
			_text = i18n.__('push_notifications_remove_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('button_remove_record');
			break;
		case 'mail_tab':
			_func = `removeMailTab('${id}')`;
			_header = i18n.__('remove_mail_tab');
			_text = i18n.__('remove_mail_tab_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_mail_tab');
			break;
		case 'mail_section':
			_func = `removeMailSection('${id}')`;
			_header = i18n.__('remove_mail_section');
			_text = i18n.__('remove_mail_section_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_mail_section');
			break;
		case 'mail_note':
			_func = `removeNote('${id}')`;
			_header = i18n.__('remove_note_header');
			_text = i18n.__('remove_note_text');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_note_header');
			break;
		case 'mail_collisions':
			_func = `removeCollisionsSelected('${id}')`;
			_header = i18n.__('delete_collisions');
			_text = i18n.__('collision_remove_selected_text');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('button_remove_record');
			break;
		case 'mail_collision_detail':
			_func = `removeCollisionInDetail('${id}')`;
			_header = i18n.__('collision_remove_selected_header');
			_text = i18n.__('collision_remove_selected_text');
			_note = i18n.__('trash_selected_emails_forever_note');
			_button_text = i18n.__('trash_selected_emails_button');
			break;
		case 'mail_collisions_dt':
			_func = `removeCollisionsDt('${id}')`;
			_header = i18n.__('delete_collisions');
			_text = i18n.__('collision_remove_selected_text');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('button_remove_record');
			break;
		case 'mail_drafts':
			_func = `removeDraftsSelected('${id}')`;
			_header = i18n.__('delete_drafts');
			_text = i18n.__('draft_remove_selected_text');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('button_remove_record');
			break;
		case 'mail_scheduled':
			_func = `removeScheduledSelected('${id}')`;
			_header = i18n.__('scheduled_messages');
			_text = i18n.__('schedule_send_delete_selected_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('button_remove_record');
			break;
		case 'mail_drafts_dt':
			_func = `removeDraftsDt('${id}')`;
			_header = i18n.__('removeDraftsSelected');
			_text = i18n.__('draft_remove_selected_text');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('button_remove_record');
			break;
		case 'label_mail':
			_func = `removeMailLabel('${id}')`;
			_header = i18n.__('modal_remove_label_header');
			_text = i18n.__('modal_remove_label_text');
			_note = i18n.__('modal_remove_label_note');
			_button_text = i18n.__('modal_remove_label_header');
			break;
		case 'mail_mailbox':
			_func = `removeMailMailbox('${id}')`;
			_header = i18n.__('delete_this_mailbox');
			_text = i18n.__('delete_this_mailbox_confirm_text');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('delete_this_mailbox_confirm');
			break;
		case 'mail_sr_cat':
			_func = `removeSavedReplyCategory('${id}')`;
			_header = i18n.__('delete_group');
			_text = i18n.__('delete_group_desc');
			_note = i18n.__('saved_replies_delete_group_note');
			_button_text = i18n.__('delete_group');
			break;
		case 'mail_sr':
			_func = `removeSavedReply('${id}')`;
			_header = i18n.__('modal_remove_savedreply_header');
			_text = i18n.__('modal_remove_savedreply_text');
			_button_text = i18n.__('button_remove_record');
			break;
		case 'mail_workflow':
			_func = `mailSettingsRemoveWorkflow('${id}')`;
			_header = i18n.__('modal_remove_workflow_header');
			_text = i18n.__('modal_remove_workflow_text');
			_note = i18n.__('modal_remove_workflow_note');
			_button_text = i18n.__('modal_remove_workflow_header');
			break;
		case 'mail_message':
			_func = `removeMessageInThread('${id}')`;
			_header = i18n.__('remove_this_message');
			_text = i18n.__('remove_this_message_text');
			_note = i18n.__('trash_selected_emails_forever_note');
			_button_text = i18n.__('trash_selected_emails_button');
			break;
		case 'team_user_group':
			_func = `mailTeamRemoveUserGroup('${id}')`;
			_header = i18n.__('remove_group');
			_text = i18n.__('remove_group_desc');
			_note = i18n.__('trash_selected_emails_forever_note');
			_button_text = i18n.__('remove_group_confirm');
			break;
		case 'team_work_group':
			_func = `mailTeamRemoveWorkGroup('${id}')`;
			_header = i18n.__('remove_group');
			_text = i18n.__('remove_group_desc');
			_note = i18n.__('trash_selected_emails_forever_note');
			_button_text = i18n.__('remove_group_confirm');
			break;
		case 'sending_email':
			_func = `marketingRemoveSendingEmail('${id}')`;
			_header = i18n.__('remmove_sending_email');
			_text = i18n.__('remmove_sending_email_desc');
			_button_text = i18n.__('remmove_sending_email_button');
			break;
		case 'pages':
			_func = `removePages('${id}')`;
			_header = i18n.__('page_remove');
			_text = i18n.__('page_remove_desc');
			_note = i18n.__('page_remove_note');
			_button_text = i18n.__('page_remove_button');
			break;
		case 'doc_page':
			_func = `docsRemovePage('${id}')`;
			_header = i18n.__('page_remove');
			_text = i18n.__('page_remove_desc');
			_note = i18n.__('page_remove_note');
			_button_text = i18n.__('page_remove_button');
			break;
		case 'doc_site':
			_func = `docsRemoveSite('${id}')`;
			_header = i18n.__('docs_delete_site_header');
			_text = i18n.__('docs_delete_site_desc');
			_note = i18n.__('docs_delete_site_note');
			_button_text = i18n.__('docs_delete_site_button');
			break;
		case 'docs_nav':
			_func = `docsRemoveNavigation('${id}')`;
			_header = i18n.__('docs_delete_navigation_header');
			_text = i18n.__('docs_delete_navigation_desc');
			_note = i18n.__('docs_delete_navigation_note');
			_button_text = i18n.__('delete_record');
			break;
		case 'doc_section':
			_func = `docsRemoveCategory('${id}')`;
			_header = i18n.__('docs_delete_section_header');
			_text = i18n.__('docs_delete_section_desc');
			_note = i18n.__('docs_delete_section_note');
			_button_text = i18n.__('delete_record');
			break;
		case 'docs_ann_category':
			_func = `docsRemoveAnnCategory('${id}')`;
			_header = i18n.__('docs_delete_announcement_header');
			_text = i18n.__('docs_delete_announcement_desc');
			_note = i18n.__('docs_delete_announcement_note');
			_button_text = i18n.__('delete_record');
			break;
		case 'docs_roadmap_label':
			_func = `docsRemoveRoadmapLabel('${id}')`;
			_header = i18n.__('docs_delete_section_header');
			_text = i18n.__('docs_delete_section_desc');
			_note = i18n.__('docs_delete_section_note');
			_button_text = i18n.__('delete_record');
			break;
		case 'docs_roadmap_list':
			_func = `docsRemoveRoadmapList('${id}')`;
			_header = i18n.__('list_delete');
			_text = i18n.__('category_delete_text');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('delete_record');
			break;
		case 'docs_roadmap_list_item':
			_func = `docsRemoveRoadmapListItem('${id}')`;
			_header = i18n.__('delete_post');
			_text = i18n.__('delete_post_text');
			_note = i18n.__('delete_post_note');
			_button_text = i18n.__('delete_record');
			break;
		case 'docs_roadmap_list_item_comment':
			_func = `docsRoadmapRemoveComment('${id}')`;
			_header = i18n.__('task_remove_comment_header');
			_text = i18n.__('remove_comment_text');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('task_remove_comment_button');
			break;
		case 'custom_field':
			_func = `removeCustomField('${id}')`;
			_header = i18n.__('custom_field_delete_header');
			_text = i18n.__('custom_field_delete_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('delete_record');
			break;
		case 'files_custom_field':
			_func = `removeFilesCustomField('${id}')`;
			_header = i18n.__('custom_field_delete_header');
			_text = i18n.__('custom_field_delete_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('delete_record');
			break;
		case 'files_folder':
			_func = `removeFilesFolder('${id.id}', '${id.parent_id}')`;
			_header = i18n.__('delete_folder');
			_text = i18n.__('delete_folder_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('delete_folder_confirm');
			break;
		case 'files_workspace':
			_func = `removeFilesWorkspace('${id.id}', '${id.host_id}')`;
			_header = i18n.__('delete_workspace');
			_text = i18n.__('delete_workspace_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('delete_workspace_confirm');
			break;
		case 'files_workspace_user':
			_func = `filesWorkspaceRemoveUser('${id}')`;
			_header = i18n.__('remove_workspace_user');
			_text = i18n.__('remove_workspace_user_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_user_confirm');
			break;
		case 'files_workspace_team':
			_func = `filesWorkspaceRemoveTeam('${id}')`;
			_header = i18n.__('remove_workspace_team');
			_text = i18n.__('remove_workspace_team_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_team_confirm');
			break;
		case 'files_folder_user':
			_func = `filesFolderRemoveUser('${id}')`;
			_header = i18n.__('remove_folder_user');
			_text = i18n.__('remove_folder_user_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_user_confirm');
			break;
		case 'files_folder_team':
			_func = `filesFolderRemoveTeam('${id}')`;
			_header = i18n.__('remove_folder_team');
			_text = i18n.__('remove_folder_team_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_team_confirm');
			break;
		case 'files_collection_user':
			_func = `filesCollectionRemoveUser('${id}')`;
			_header = i18n.__('remove_collection_user');
			_text = i18n.__('remove_collection_user_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_user_confirm');
			break;
		case 'files_collection_team':
			_func = `filesCollectionRemoveTeam('${id}')`;
			_header = i18n.__('remove_collection_team');
			_text = i18n.__('remove_collection_team_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_team_confirm');
			break;
		case 'files_folder_link':
			_func = `filesFolderLinkDelete('${id}')`;
			_header = i18n.__('delete_link');
			_text = i18n.__('delete_link_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('delete_link_confirm');
			break;
		case 'files_file_link':
			_func = `filesFileLinkDelete('${id}')`;
			_header = i18n.__('delete_link');
			_text = i18n.__('delete_link_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('delete_link_confirm');
			break;
		case 'files_collection_link':
			_func = `filesCollectionLinkDelete('${id}')`;
			_header = i18n.__('delete_link');
			_text = i18n.__('delete_link_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('delete_link_confirm');
			break;
		case 'files_tag':
			_func = `removeFileTag('${id}')`;
			_header = i18n.__('remove_tag');
			_text = i18n.__('remove_tag_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_tag_confirm');
			break;
		case 'files_collection':
			_func = `removeFileCollection('${id}')`;
			_header = i18n.__('delete_collection');
			_text = i18n.__('delete_collection_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('delete_collection');
			break;
		case 'files_user_tmp':
			_func = `filesUserTmpRevoke('${id}')`;
			_header = i18n.__('revoke_invite');
			_text = i18n.__('revoke_invite_desc');
			_note = '';
			_button_text = i18n.__('revoke_invite');
			break;
		case 'files_user_tmp_request_access':
			_func = `filesUserTmpRequestAccessRemove('${id}')`;
			_header = i18n.__('reject_request');
			_text = i18n.__('reject_request_desc');
			_note = '';
			_button_text = i18n.__('reject_request');
			break;
		case 'files_user':
			_func = `removeFilesUser('${id}')`;
			_header = i18n.__('modal_header_remove_user');
			_text = i18n.__('files_remove_user_desc');
			_note = i18n.__('files_remove_user_note');
			_button_text = i18n.__('modal_header_remove_user');
			break;
		case 'files_user_remove_from_team':
			_func = `removeFilesUserFromTeam('${id.user_id}', '${id.team_id}')`;
			_header = i18n.__('remove_team_user');
			_text = i18n.__('remove_team_user_desc');
			_note = i18n.__('remove_team_user_note');
			_button_text = i18n.__('remove_team_user');
			break;
		case 'files_team':
			_func = `removeFilesTeam('${id}')`;
			_header = i18n.__('remove_team');
			_text = i18n.__('files_remove_team_desc');
			_note = i18n.__('files_remove_team_note');
			_button_text = i18n.__('remove_team');
			break;
		case 'import_email_auth':
			_func = `mailImportRevokeAuth('${id}')`;
			_header = i18n.__('revoke_imap_auth');
			_text = i18n.__('revoke_imap_auth_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('revoke_imap_auth_confirm');
			break;
		case 'contact_note':
			_func = `contactDeleteNote('${id}')`;
			_header = i18n.__('remove_note_header');
			_text = i18n.__('remove_note_text');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_note_header');
			break;
		case 'crm_pipeline':
			_func = `crmDeletePipeline('${id}')`;
			_header = i18n.__('remove_pipeline');
			_text = i18n.__('remove_pipeline_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_pipeline');
			break;
		case 'crm_stage':
			_func = `crmDeleteStage('${id}')`;
			_header = i18n.__('remove_stage');
			_text = i18n.__('remove_stage_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_stage');
			break;
		case 'crm_box_contact':
			_func = `crmBoxContactRemove('${id}')`;
			_header = i18n.__('remove_contact');
			_text = i18n.__('remove_contact_box');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_contact');
			break;
		case 'crm_box_company':
			_func = `crmBoxCompanyRemove('${id}')`;
			_header = i18n.__('remove_company');
			_text = i18n.__('remove_company_box');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_company');
			break;
		case 'crm_box_reminder':
			_func = `crmBoxReminderRemove('${id}')`;
			_header = i18n.__('remove_reminder');
			_text = i18n.__('remove_reminder_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_reminder');
			break;
		case 'crm_box_task':
			_func = `crmBoxTaskRemove('${id}')`;
			_header = i18n.__('remove_task');
			_text = i18n.__('remove_task_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_task');
			break;
		case 'crm_box_email':
			_func = `crmBoxEmailRemove('${id}')`;
			_header = i18n.__('remove_message');
			_text = i18n.__('remove_message_desc');
			_button_text = i18n.__('remove_message');
			break;
		case 'crm_box_note':
			_func = `crmBoxNoteRemove('${id}', 'note')`;
			_header = i18n.__('remove_note_header');
			_text = i18n.__('remove_note_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_note_header');
			break;
		case 'crm_box_call':
			_func = `crmBoxNoteRemove('${id}', 'call')`;
			_header = i18n.__('remove_call_header');
			_text = i18n.__('remove_call_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_call_header');
			break;
		case 'crm_box_meeting':
			_func = `crmBoxNoteRemove('${id}', 'meeting')`;
			_header = i18n.__('remove_meeting_header');
			_text = i18n.__('remove_meeting_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_meeting_header');
			break;
		case 'crm_box_remove':
			_func = `crmBoxRemove('${id}')`;
			_header = i18n.__('delete_box');
			_text = i18n.__('delete_box_desc');
			_note = i18n.__('delete_box_note');
			_button_text = i18n.__('delete_box');
			break;
		case 'csat':
			_func = `removeCustomerSatisfaction('${id}')`;
			_header = i18n.__('remove_record_header');
			_text = i18n.__('remove_record_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('remove_record_header');
			break;
		case 'files_sso_oauth':
			_func = `filesRemoveoAuthKey('${id}')`;
			_header = i18n.__('delete_record_header');
			_text = i18n.__('remove_record_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('delete_record');
			break;
		case 'files_sso_oauth_token':
			_func = `filesRemoveoAuthToken('${id}')`;
			_header = i18n.__('delete_record_header');
			_text = i18n.__('remove_record_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('delete_record');
			break;
		case 'files_co_admin':
			_func = `filesRemoveWorkspaceCoAdmin('${id}')`;
			_header = i18n.__('remove_co_admin');
			_text = i18n.__('remove_co_admin_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('delete_record');
			break;
		case 'access_token':
			_func = `removeAccessToken('${id}')`;
			_header = i18n.__('access_token_delete');
			_text = i18n.__('access_token_delete_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('access_token_delete_confirm');
			break;
		case 'upload_link':
			_func = `removeUploadLink('${id}')`;
			_header = i18n.__('upload_link_delete');
			_text = i18n.__('upload_link_delete_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('upload_link_delete_confirm');
			break;
		case 'upload_link_file':
			_func = `removeUploadLinkFile('${id}')`;
			_header = i18n.__('delete_file');
			_text = i18n.__('upload_link_delete_file_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('yes_confirm');
			break;
		case 'file_version':
			_func = `deleteFileVersion('${id}')`;
			_header = i18n.__('delete_version');
			_text = i18n.__('delete_version_desc');
			_note = i18n.__('action_permanent');
			_button_text = i18n.__('yes_confirm');
			break;
		case 'file_rendition_link':
			_func = `removeFileRendition('${id}')`;
			_header = i18n.__('remove_rendition');
			_text = i18n.__('remove_rendition_desc');
			_button_text = i18n.__('yes_confirm');
			break;
	}
	//- Ready to call modal
	globalDeleteModal(id, _header, _text, _note, _button_text, _func);
}

//- Call SweetAlert Modal
function globalDeleteModal(id, header, text, note, button_text, func) {
	Swal.fire({
		title: header,
		html: `${text}<br><br>${note}`,
		icon: "warning",
		showCancelButton: true,
		confirmButtonText: button_text
	}).then(function(result) {
		if (result.value) {
			var _f = new Function(func);
			return _f();
			//- Swal.fire(
			//- 	"Deleted!",
			//- 	"Your file has been deleted.",
			//- 	"success"
			//- )
		}
	});
}

////////////////////////////////////////////////
// DELETE FUNCTIONS
////////////////////////////////////////////////


////////////////////////////////////////////////
// API KEYS
////////////////////////////////////////////////

function revokeApiKey(id) {
	$.ajax({
		type: "GET",
		url: '/profile/revoke_api_key/' + id + '?user_id=' + $('#form_user #user_id').val(),
		statusCode: {
			// Error
			500: function(data) {
				notyWrapper({
					text: 'An error occured. Please try again or report it, please.',
					type: 'error',
					timeout: 8000
				})
			},
			// Done
			200: function(data) {
				//- Update actions
				$('#actions_' + id).html(data.html);
				// Update status
				$('#status_' + id).html(`<span class="badge badge-light-danger fs-8">${i18n.__('revoked')}</span>`);
			},
		}
	});
}

function reactivateApiKey(id) {
	$.ajax({
		type: "GET",
		url: '/profile/reactivate_api_key/' + id + '?user_id=' + $('#form_user #user_id').val(),
		statusCode: {
			// Error
			500: function(data) {
				notyWrapper({
					text: 'An error occured. Please try again or report it, please.',
					type: 'error',
					timeout: 8000
				})
			},
			// Done
			200: function(data) {
				//- Add new row
				$('#actions_' + id).html(data.html);
				// Update status
				$('#status_' + id).html(`<span class="badge badge-light-success fs-8">${i18n.__('active')}</span>`);
			},
		}
	});
}

function deleteApiKey(id) {
	$.ajax({
		type: "GET",
		url: '/profile/delete_api_key/' + id + '?user_id=' + $('#form_user #user_id').val(),
		statusCode: {
			// Error
			500: function(data) {
				notyWrapper({
					text: 'An error occured. Please try again or report it, please.',
					type: 'error',
					timeout: 8000
				})
			},
			// Done
			200: function(data) {
				//- Remove row
				$('#' + id).remove();
			},
		}
	});
}

////////////////////////////////////////////////
// TODOS
////////////////////////////////////////////////

// Remove Comment
function removeToDoComment(id) {
	// Submit
	$.ajax({
		type: "POST",
		url: '/todo/remove/comment',
		data: { comment_id: id },
		statusCode: {
			// Error
			500: function(data) {

			},
			// Done
			200: function(comment_id) {
				// Just remove the div
				$('#todo_comment_' + comment_id).remove();
			}
		}
	});
}

// Remove
function removeToDo(id) {
	// Remove lf
	lfRemoveItem( `todos_count_${_getHostIdCookie()}_${_getUserCookie()}` );
	// Submit
	$.ajax({
		type: "POST",
		url: '/todo/remove',
		data: { todo_id: id },
		statusCode: {
			// Error
			500: function(data) {

			},
			// Done
			200: function(data) {
				// Remove modal
				$('#removeToDo').modal('hide');
				// Load right side
				$('#todos_list_div').load('/todos?category=' + Cookies.get('todo_category'));
			}
		}
	});
}

// Remove Category
function removeToDoCategory(id) {
	// Submit
	$.ajax({
		type: "POST",
		url: '/todo/remove/category',
		data: { cat_id: id },
		statusCode: {
			// Error
			500: function(data) {

			},
			// Done
			200: function(comment_id) {
				_closeAllDropdown();
				// Remove div
				$(`#todo_Category_${id}`).remove();
				//- Set category to incoming
				$('#todo_category_incoming').addClass('font-weight-800');
				// Load todos
				$('#todos_list_div').load('/todos?category=incoming');
			}
		}
	});
}

// Remove
function removeToDoGroup(id) {
	// Submit
	$.ajax({
		type: "POST",
		url: '/todo/remove/group',
		data: { id },
		statusCode: {
			// Error
			500: function(data) {

			},
			// Done
			200: function() {
				_closeAllDropdown();
				// Load todos
				$('#todos_list_div').load('/todos?category=' + Cookies.get('todo_category'));
			}
		}
	});
}

////////////////////////////////////////////////
// COMPANY
////////////////////////////////////////////////

// Remove Company
function removeCompanies(ids) {
	// Call route to remove record on success close the window and refresh list
	$.ajax({
		type: "POST",
		url: '/settings/remove_companies',
		data: {
			'ids': ids
		},
		statusCode: {
			// Error
			500: function(data) {

			},
			// Done
			200: function() {
				// Callback
				removeCompaniesPostAction();
			}
		}
	});
}

////////////////////////////////////////////////
// LABELS
////////////////////////////////////////////////

// Remove Company Users Label
function removeCompanyUsersLabel(id) {
	// Call route to remove record on success close the window and refresh list
	$.ajax({
		type: "POST",
		url: '/settings/labels_remove',
		data: {
			id: id,
			kind: 'company_users'
		},
		statusCode: {
			// Error
			500: function(data) {

			},
			// Done
			200: function() {
				// Refresh modal
				loadDynamicContent('/settings/customers/labels','contactsLabels');
			}
		}
	});
}

// Remove Label
function removeMailLabel(id) {
	// Call route to remove record on success close the window and refresh list
	$.ajax({
		type: "POST",
		url: '/settings/labels_remove',
		data: {
			id: id
		},
		statusCode: {
			// Error
			500: function(data) {
				notyWrapper({
					text: data,
					type: 'error',
					timeout: 10000
				});
			},
			// Error
			201: function(data) {
				Swal.fire({
					title: `<span class="fs-2 fw-bold">${i18n.__('heads_up')}</span>`,
					html: data,
					icon: "warning",
					showCancelButton: false,
					confirmButtonText: i18n.__('ok')
				});
			},
			// Done
			200: function() {
				// Refresh modal
				loadDynamicContent('/mail/settings/labels','mailLabels');
			}
		}
	});
}

////////////////////////////////////////////////
// SLA
////////////////////////////////////////////////

// Remove Service Level Agreement
function removeSla(id) {
	// Call route to remove record on success close the window and refresh list
	$.ajax({
		type: "GET",
		url: `/sla/remove/${id}`,
		statusCode: {
			// Error
			500: function(data) {

			},
			// Done
			200: function() {
				// Refresh modal
				loadDynamicContent('/slas','slasModal');
			}
		}
	});
}

////////////////////////////////////////////////
// MARKETING
////////////////////////////////////////////////

function removeEmailTemplate(id) {
	//- Submit
	$.ajax({
		type: "POST",
		url: '/marketing/templates/remove',
		data: { id },
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error saving the template',
					type: 'error'
				});
			},
			// Refresh
			200: function(data) {
				// Reload list
				engageLoadEmailTemplates();
			}
		}
	});
	return false;
}

function removeCampaignArchive(id) {
	//- Submit
	$.ajax({
		type: "POST",
		url: '/marketing/archive/remove',
		data: { id },
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error removing the campaign',
					type: 'error'
				});
			},
			// Refresh
			200: function(data) {
				dtRefreshTable('#dt_campaigns_sent');
			}
		}
	});
	return false;
}

function removeCampaignsList(id) {
	//- Submit
	$.ajax({
		type: "GET",
		url: `/marketing/lists/remove/${id}`,
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error removing the list',
					type: 'error'
				});
			},
			// Refresh
			200: function(data) {
				engageLoadLists();
				loadCampaignsData();
			}
		}
	});
	return false;
}

function removeUserFromCampaignList(ids, many) {
	var list_id = $('#engage_campaign_list_detail_id').val();
	$.ajax({
		type: "POST",
		url: `/marketing/lists/detail/users_remove`,
		data: { ids, list_id },
		statusCode: {
			// Error
			500: function(data) {
				console.log('ERROR removing users', data);
			},
			// Done
			200: function(data) {
				//- Refresh list
				// dtRefreshTable('#dt_campaigns_list_details_subscribers');
				// Post remove
				removeUsersFromListPostAction(many);
				// _getContactsTotal();
				//- Refresh list
				// _dtCampaignsListsDetailsUsers.dataTablesRefresh();
				//- Refresh list page
				// engageLoadLists();
			}
		}
	});
	return false;
}

function removeCampaignDraft(id) {
	//- Submit
	$.ajax({
		type: "GET",
		url: `/marketing/drafts/remove/${id}`,
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error removing the draft',
					type: 'error'
				});
			},
			// Refresh
			200: function(data) {
				engageLoadCampaignDrafts();
			}
		}
	});
	return false;
}

function removeAutoSequence(id, user_check) {
	var campaign_id = $('#engage_campaign_id').val();
	var action = $('#engageSequenceDiv_' + id).data('action');
	//- Submit
	$.ajax({
		type: "POST",
		url: '/marketing/automation/remove_sequence',
		data: { id, user_check, campaign_id, action },
		statusCode: {
			// Error
			500: function() {
				console.log('ERROR auto_remove_seq');
			},
			// Done
			200: function() {
				$('#engageSequenceDiv_' + id).remove();
			},
			//- There are users
			201: function() {
				_subEngageUsersAffected('remove', id, action);
			}
		}
	});
}

function removeAutoCampaign(id) {
	//- Submit
	$.ajax({
		type: "GET",
		url: `/marketing/automation/remove/${id}`,
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error removing the campaign',
					type: 'error'
				});
			},
			// Refresh
			200: function(data) {
				// Remove from view
				$('#drip_' + id).remove();
			}
		}
	});
	return false;
}

function removeUserFromAutoCampaign(id) {
	var campaign_id = $('#engage_campaign_id').val();
	//- Submit
	$.ajax({
		type: "POST",
		url: `/marketing/automation/remove_user`,
		data: { id, campaign_id },
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error removing the campaign',
					type: 'error'
				});
			},
			// Refresh
			200: function(data) {
				// dt refresh
				dripUsersLoadDt();
				//- Reload sumary
				dripUsersLoadSummary();
			}
		}
	});
	return false;
}

////////////////////////////////////////////////
// FORMS
////////////////////////////////////////////////

function removeForms(id) {
	//- Submit
	$.ajax({
		type: "GET",
		url: '/forms/remove/' + id,
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error removing the form',
					type: 'error'
				});
			},
			// Refresh
			200: function(data) {
				// Remove from view
				$('#form' + id).remove();
				// Reload lists
				loadFormsList(true);
				loadFormsList(false);
			}
		}
	});
	return false;
}

////////////////////////////////////////////////
// CHAT
////////////////////////////////////////////////

function removeChatSavedReply(id) {
	//- Submit
	$.ajax({
		type: "GET",
		url: `/chat/settings/saved_replies_remove/${id}`,
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error removing the saved reply',
					type: 'error'
				});
			},
			// Refresh
			200: function(data) {
				// Remove div
				$('#engage_saved_reply_outer_div_' + id).remove();
			}
		}
	});
	return false;
}

function removeChatGroup(id) {
	//- Submit
	$.ajax({
		type: "GET",
		url: `/chat/settings/groups_remove/${id}`,
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error removing the saved reply',
					type: 'error'
				});
			},
			// Refresh
			200: function(data) {
				// Remove div
				$('#chat_group_' + id).remove();
				$('#chat_group_line_' + id).remove();
			}
		}
	});
	return false;
}

function removeChatTrigger(id) {
	//- Submit
	$.ajax({
		type: "GET",
		url: `/chat/settings/trigger_remove/${id}`,
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error removing the saved reply',
					type: 'error'
				});
			},
			// Refresh
			200: function(data) {
				// Remove div
				$('#engage_trigger_' + id).remove();
			}
		}
	});
	return false;
}

////////////////////////////////////////////////
// MAIL
////////////////////////////////////////////////

function removeMailTab(id) {
	//- Submit
	$.ajax({
		type: "GET",
		url: `/mail/tab/remove/${id}`,
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error removing the saved reply',
					type: 'error'
				});
			},
			// Refresh
			200: function(data) {
				document.location.href = '/mail';
			}
		}
	});
	return false;
}

function removeMailSection(id) {
	//- Submit
	$.ajax({
		type: "GET",
		url: `/mail/section/remove/${id}`,
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error removing the saved reply',
					type: 'error'
				});
			},
			// Refresh
			200: function(data) {
				$(`#mail_section_${id}`).remove();
				$(`#dt_mails_div_${id}`).remove();
			}
		}
	});
	return false;
}

// Remove Note
function removeNote(id) {
	// Grab emailchain_id
	var _ec_id = $('#mail_id').val();
	// Call route to remove record on success close the window and refresh list
	$.ajax({
		type: "GET",
		url: '/mail/message/note_remove/' + _ec_id + '/' + id,
		success: function(data) {
			// Just remove the div instead of reloading
			$('#note_' + id).remove();
			// addStatusDiv(data.note);
		}
	});
}

// Remove Mailbox
function removeMailMailbox(id) {
	// Call route to remove record on success close the window and refresh list
	$.ajax({
		type: "POST",
		url: '/mailbox/remove',
		data: {
			'id': id
		},
		success: function(data) {
			try {
				location.href = '/mail';
			} catch (e) {
				/* no need to do anything */
				// console.log(e);
			}
		}
	});
}

// Remove message in thread
function removeMessageInThread(id) {
	var _ec_id = $('#mail_id').val();
	// Call route to remove record on success close the window and refresh list
	$.ajax({
		type: "GET",
		url: '/message/remove_one/' + _ec_id + '/' + id,
		statusCode: {
			500: function() {
				notyWrapper({
					text: i18n.__('remove_this_message_error'),
					type: 'error',
					timeout: false
				});
			},
			200: function() {
				// Noty
				notyWrapper({
					text: i18n.__('remove_this_message_success'),
					type: 'success'
				});
				// Remove div
				$('#mail_' + id).remove();
			}
		}
	});
}

// Remove sending email
function marketingRemoveSendingEmail(id) {
	$.ajax({
		type: "GET",
		url: `/marketing/sending_emails/remove?id=${id}`,
		statusCode: {
			500: function() {
				notyWrapper({
					text: i18n.__('remove_this_message_error'),
					type: 'error',
					timeout: false
				});
			},
			200: function() {
				//- Reload lists
				// marketingLoadSendingEmails();
			}
		}
	});
}

////////////////////////////////////////////////
// PAGES
////////////////////////////////////////////////

function removePages(id) {
	// console.log("id", id);
	//- Submit
	$.ajax({
		type: "GET",
		url: '/pages/delete/' + id,
		statusCode: {
			// Already here
			500: function(data) {
				notyWrapper({
					text: 'There was an error removing the page',
					type: 'error'
				});
			},
			// Refresh
			200: function(data) {
				// Remove from view
				$('#page_' + id).remove();
				// Reload
				document.location.href = '/pages';
			}
		}
	});
	return false;
}

// Remove label
// function removeLabel() {
// 	// Remove animation
// 	// _removeLoadingAnimationGlobal();
// 	// Grab the id
// 	var theid = getModalId();
// 	// Kind
// 	var _kind = $('#label_kind').val();
// 	// Call route to remove record on success close the window and refresh list
// 	$.ajax({
// 		type: "POST",
// 		url: '/settings/labels_remove',
// 		data: {
// 			id: theid,
// 			kind: _kind
// 		},
// 		statusCode: {
// 			200: function(data) {
// 				// For normal labels
// 				if (!_kind) {
// 					setTimeout(function() {
// 						location.href = '/settings/labels';
// 						return;
// 					}, 1000);
// 				}
// 				else {
// 					// Close window
// 					$('#myModalDelete').modal('hide');
// 					// Just remove the label div
// 					$('#label_tr_' + theid).remove();
// 				}
// 			},
// 			201: function(data) {
// 				// Close window
// 				$('#myModalDelete').modal('hide');
// 				// Show noty
// 				notyWrapper({
// 					text: data,
// 					type: 'warning',
// 					timeout: false
// 				});
// 				return false;
// 			},
// 			500: function(data) {
// 				// Close window
// 				$('#myModalDelete').modal('hide');
// 				// Show noty
// 				notyWrapper({
// 					text: data,
// 					type: 'error',
// 					timeout: false
// 				});
// 				return false;
// 			}
// 		}
// 	});
// }

